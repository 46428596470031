import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Home } from 'pages';
import { useModalAuth, useStore } from 'stores';
var Referrer = function () {
    var openWelcome = useModalAuth().openWelcome;
    var setReferrer = useStore().setReferrer;
    var nickname = useParams().nickname;
    useEffect(function () {
        setReferrer(nickname);
        openWelcome();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return _jsx(Home, {});
};
export default Referrer;
