/*
  References

  "hero": {
    "width": 1360,
    "height": 765
  },
  "mainscreen": {
    "width": 900,
    "height": 510
  },
  "cover": {
    "width": 445,
    "height": 250
  },
  "xcover": {
    "width": 300,
    "height": 170
  }
*/
import { MediaType } from 'config/constants';
export var MEDIA_TYPES = {
    cover: 1,
    thumbnail: 2,
};
var MEDIA_SIZES = {
    hero: 'hero',
    mainscreen: 'mainscreen',
    cover: 'cover',
    xcover: 'xcover',
};
export var getPoster = function (props) {
    var media = props.media, _a = props.size, size = _a === void 0 ? 'cover' : _a;
    if (!(media === null || media === void 0 ? void 0 : media.length))
        return '';
    var poster = media.find(function (img) { return img.type === MediaType.poster; });
    if (!poster)
        return '';
    return poster.sizes[size];
};
export var getCover = function (props) {
    var media = props.media, _a = props.size, size = _a === void 0 ? 'cover' : _a;
    if (!(media === null || media === void 0 ? void 0 : media.length))
        return '';
    var cover = media.find(function (img) { return img.type === MEDIA_TYPES.cover; });
    if (!cover)
        return '';
    return cover.sizes[size];
};
export var getThumbnail = function (props) {
    var media = props.media, order = props.order, _a = props.size, size = _a === void 0 ? 'cover' : _a;
    if (!(media === null || media === void 0 ? void 0 : media.length))
        return '';
    /**
     * Returns specific order
     */
    if (typeof order === 'number') {
        var thumb = media.find(function (img) { return (img.type === MEDIA_TYPES.thumbnail
            && img.order === order); });
        if (!thumb)
            return '';
        return thumb === null || thumb === void 0 ? void 0 : thumb.sizes[size];
    }
    var thumbnails = media.filter(function (img) { return img.type === MEDIA_TYPES.thumbnail; });
    if (!thumbnails.length)
        return '';
    /**
     * Sorting with null order at the end
     */
    var sorted = thumbnails.sort(function (a, b) {
        if (b.order === null)
            return -1;
        if (a.order === null)
            return 1;
        return a.order - b.order;
    });
    return sorted[0].sizes[size];
};
/**
 * Returns media following this criteria:
 * > cover
 * > thumbnail with lower order
 * > thumbnail with null order
 * > anything
 */
export var getMedia = function (props) {
    var _a;
    var media = props.media, _b = props.size, size = _b === void 0 ? 'cover' : _b;
    if (!(media === null || media === void 0 ? void 0 : media.length))
        return '';
    var cover = getCover({ media: media, size: size });
    if (cover)
        return cover;
    var thumbnail = getThumbnail({ media: media, size: size });
    if (thumbnail)
        return thumbnail;
    return (_a = media[0]) === null || _a === void 0 ? void 0 : _a.sizes[size];
};
