import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Suspense, lazy, useEffect, useState, useTransition, } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSession } from 'hooks';
import { useModalAuth, useStore } from 'stores';
import { ModalPortal } from 'ui';
import { userApi } from 'api';
var Login = lazy(function () { return import('./Login'); });
var Recovery = lazy(function () { return import('./Recovery'); });
var Register = lazy(function () { return import('./Register'); });
var Validate = lazy(function () { return import('./Validate'); });
var Welcome = lazy(function () { return import('./Welcome'); });
var Referred = lazy(function () { return import('./Referred'); });
var Onboarding = lazy(function () { return import('./Onboarding'); });
var ModalAuth = function () {
    var _a = useTransition(), _ = _a[0], startTransition = _a[1];
    var _b = useModalAuth(), variantStore = _b.variant, openWelcome = _b.openWelcome, close = _b.close, openRegister = _b.openRegister, openLogin = _b.openLogin, openRecovery = _b.openRecovery, openReferred = _b.openReferred, openOnboarding = _b.openOnboarding;
    var navigate = useNavigate();
    var _c = useSession(), isLoggedIn = _c.isLoggedIn, logout = _c.logout;
    var _d = useStore(), referrer = _d.referrer, setReferrer = _d.setReferrer;
    var setReferred = userApi.useSetReferred().setReferred;
    var _e = useState(''), variant = _e[0], setVariant = _e[1];
    var cleanPath = function () {
        if (location.pathname.includes('/referrer/')) {
            var _a = location.pathname.split('referrer'), path = _a[0], _1 = _a[1];
            navigate(path, { replace: true });
        }
    };
    var handleCongrats = function () {
        if (referrer) {
            openOnboarding();
            setReferrer(null);
        }
        else {
            openReferred();
        }
    };
    useEffect(function () {
        if (!isLoggedIn)
            return;
        cleanPath();
        if (referrer) {
            setReferred(referrer);
        }
        if (variant === 'login' || variant === 'welcome') {
            close();
        }
    }, [isLoggedIn]);
    useEffect(function () {
        startTransition(function () {
            setVariant(variantStore);
        });
    }, [variantStore]);
    if (!variant)
        return null;
    return (_jsx(ModalPortal, { children: _jsxs(Suspense, { fallback: null, children: [variant === 'welcome' && (_jsx(Welcome, { onClose: function () {
                        close();
                        cleanPath();
                        logout();
                    }, onCredentials: openLogin, onRegister: openRegister })), variant === 'login' && (_jsx(Login, { onClose: close, onBack: openWelcome, onRegister: openRegister, onRecovery: openRecovery })), variant === 'register' && (_jsx(Register, { onClose: close, onBack: openWelcome, onCongrats: handleCongrats })), variant === 'recovery' && (_jsx(Recovery, { onBack: openLogin, onClose: close })), variant === 'validate' && (_jsx(Validate, { onBack: function () {
                        openWelcome();
                        logout();
                    }, onClose: function () {
                        logout();
                        close();
                    }, onCongrats: handleCongrats })), variant === 'referred' && (_jsx(Referred, { onClose: function () {
                        close();
                        openOnboarding();
                    } })), variant === 'onboarding' && (_jsx(Onboarding, { onClose: close }))] }) }));
};
export default ModalAuth;
