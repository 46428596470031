import ReactGA from 'react-ga4';
import { environment } from 'config/environment';
export var GAHandler = function (action, params, fb) {
    ReactGA.event(action, params);
    var sendToMeta = fb && window.fbq;
    if (!environment.isProduction) {
        console.log('Analytics', { action: action, params: params });
        if (sendToMeta) {
            console.log('Meta', { action: action, params: params });
        }
    }
    if (environment.isProduction && sendToMeta) {
        window.fbq('track', action);
    }
};
