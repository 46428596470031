import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useLayoutEffect, useRef, useState, } from 'react';
import { Autoplay, EffectFade, FreeMode, Mousewheel, Navigation, Pagination, } from 'swiper/modules';
import { useResponsive } from 'hooks';
import useShowCarouselButtons from 'hooks/useShowCarouselButtons';
import { SwiperSlide } from 'swiper/react';
import { Skeleton } from 'ui';
import { SwiperStyled, SwiperWrapper, } from './styles';
import 'swiper/css';
import 'swiper/css/autoplay';
import 'swiper/css/effect-fade';
import 'swiper/css/free-mode';
var CarouselBase = function (props) {
    var isMobileBig = useResponsive().isMobileBig;
    var children = props.children, slidesPerView = props.slidesPerView, _a = props.renderNavigationBtns, renderNavigationBtns = _a === void 0 ? function () { return null; } : _a, _b = props.renderPagination, renderPagination = _b === void 0 ? function () { return null; } : _b, _c = props.renderOverlay, renderOverlay = _c === void 0 ? function () { return null; } : _c, loop = props.loop, rewind = props.rewind, autoPlay = props.autoPlay, _d = props.effect, effect = _d === void 0 ? 'slide' : _d, _e = props.offsetLeft, offsetLeft = _e === void 0 ? 35 : _e, _f = props.offsetRight, offsetRight = _f === void 0 ? 0 : _f, _g = props.defaultSlidePosition, defaultSlidePosition = _g === void 0 ? 0 : _g, isFreeMode = props.isFreeMode, _h = props.spaceBetween, spaceBetween = _h === void 0 ? 10 : _h, isLoading = props.isLoading, _j = props.simulateTouch, simulateTouch = _j === void 0 ? isMobileBig : _j, _k = props.mousewheel, mousewheel = _k === void 0 ? true : _k;
    var ref = useRef(null);
    var _l = useState(0), index = _l[0], setIndex = _l[1];
    var _m = useState(true), isFirstMount = _m[0], setIsFirstMount = _m[1];
    var _o = useState(), swiper = _o[0], setSwiper = _o[1];
    var _p = useState(false), isMoving = _p[0], setIsMoving = _p[1];
    var showNavigationBtns = useShowCarouselButtons().showNavigationBtns;
    var slides = Array.isArray(children) ? children : [children];
    var total = slides.length;
    var freeMode = isFreeMode || !showNavigationBtns;
    var content = isLoading
        ? Array(Math.ceil(slidesPerView) + 1).fill({}).map(function (_, idx) { return (_jsx(SwiperSlide, { style: { height: 'auto', width: 'fit-content' }, children: _jsx(Skeleton, {}, idx) }, idx)); })
        : slides.map(function (slide, idx) { return (_jsx(SwiperSlide, { style: { height: 'auto', width: 'fit-content' }, children: typeof slide === 'function'
                ? slide({ isMoving: isMoving })
                : slide }, idx)); });
    var handleNext = function () { var _a; (_a = ref.current) === null || _a === void 0 ? void 0 : _a.swiper.slideNext(); };
    var handlePrev = function () { var _a; (_a = ref.current) === null || _a === void 0 ? void 0 : _a.swiper.slidePrev(); };
    var customBtns = renderNavigationBtns({
        classPrev: 'swiper-button-prev',
        classNext: 'swiper-button-prev',
        nextSlide: handleNext,
        prevSlide: handlePrev,
    });
    var customPagination = renderPagination({ index: index, total: total });
    var handleIndexChange = function (_a) {
        var realIndex = _a.realIndex;
        setIndex(realIndex);
    };
    var handleAutoplayStart = function () {
        var _a;
        if (!autoPlay)
            return;
        (_a = ref.current) === null || _a === void 0 ? void 0 : _a.swiper.autoplay.start();
    };
    var handleAutoplayPause = function () {
        var _a;
        if (!autoPlay)
            return;
        (_a = ref.current) === null || _a === void 0 ? void 0 : _a.swiper.autoplay.stop();
    };
    useEffect(function () {
        setIsFirstMount(false);
    }, []);
    useLayoutEffect(function () {
        if (!swiper)
            return;
        var transition = isFirstMount ? 0 : 300;
        if (loop)
            swiper.slideToLoop(defaultSlidePosition, transition, false);
        else
            swiper.slideTo(defaultSlidePosition, transition, false);
    }, [loop, defaultSlidePosition, isFirstMount, swiper]);
    useLayoutEffect(function () {
        var _a;
        if (!((_a = ref.current) === null || _a === void 0 ? void 0 : _a.swiper))
            return;
        ref.current.swiper.allowTouchMove = isFreeMode || !showNavigationBtns;
    }, [isFreeMode, showNavigationBtns]);
    return (_jsxs(SwiperWrapper, { onMouseEnter: handleAutoplayPause, onMouseLeave: handleAutoplayStart, onTouchStart: handleAutoplayPause, onTouchEnd: handleAutoplayStart, children: [_jsx("div", { style: {
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    zIndex: 2,
                    pointerEvents: 'none',
                }, children: renderOverlay() }), !isLoading && (_jsxs(_Fragment, { children: [customBtns, customPagination] })), _jsx(SwiperStyled, { ref: ref, speed: 600, slidesPerView: slidesPerView, slidesPerGroup: slidesPerView === 'auto' ? 1 : slidesPerView, freeMode: freeMode, rewind: rewind, loop: loop, effect: effect, spaceBetween: spaceBetween, autoplay: autoPlay, loopPreventsSliding: false, 
                // simulateTouch={simulateTouch}
                modules: [
                    FreeMode,
                    Navigation,
                    Pagination,
                    Autoplay,
                    EffectFade,
                    Mousewheel,
                ], onRealIndexChange: handleIndexChange, onSwiper: function (s) { setSwiper(s); }, "$offsetLeft": offsetLeft, "$offsetRight": offsetRight, "$isFade": effect === 'fade', mousewheel: mousewheel && { sensitivity: 0.5, forceToAxis: true }, onSlideChangeTransitionStart: function (s) {
                    setIsMoving(true);
                    if (isMobileBig)
                        return;
                    s.el.style.pointerEvents = 'none';
                }, onSlideChangeTransitionEnd: function (s) {
                    setIsMoving(false);
                    if (isMobileBig)
                        return;
                    s.el.style.pointerEvents = 'unset';
                }, children: content }, String(freeMode))] }));
};
export default CarouselBase;
