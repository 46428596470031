import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { ReactComponent as ChevronLeftSVG } from 'assets/icons/chevron-left.svg';
import { LinkLang, Text } from 'ui';
import { useResponsive } from 'hooks';
import { LinkStyled } from './styles';
var GoBack = function (_a) {
    var to = _a.to, className = _a.className;
    var t = useTranslation().t;
    var isMobile = useResponsive().isMobile;
    return (_jsxs(LinkStyled, { as: LinkLang, className: className, to: to, children: [_jsx(ChevronLeftSVG, { height: 19, width: 19 }), _jsx(Text, { color: 'inerhit', size: isMobile ? 'sm' : 'base', children: t('back') })] }));
};
export default GoBack;
