import { useMutation } from '@tanstack/react-query'

import { useApi } from 'hooks'
import { RECAPTCHA_TYPES } from 'config/constants'


const useResetPassword = () => {
  const { apiFetch } = useApi()

  const url = '/auth/resetpassword'

  const resetPassword = ({ mobileNumber, code, password }) => {
    return apiFetch({
      url,
      method: 'POST',
      data: {
        mobileNumber,
        code,
        password,
      },
      captcha: {
        type: RECAPTCHA_TYPES.v3,
        action: 'resetpassword_web',
      },
    })
      .then(res => res.data)
  }

  const {
    mutate,
    ...rest
  } = useMutation(resetPassword)

  return {
    resetPassword: mutate,
    ...rest,
  }
}

export default useResetPassword
