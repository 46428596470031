var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import Axios from 'axios';
import { useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { useTranslation } from 'react-i18next';
import { RECAPTCHA_TYPES } from 'config/constants';
import { environment } from 'config/environment';
import { useSession, useToast } from 'hooks';
import i18n from 'i18n';
import { useStore } from 'stores';
import { GAHandler } from 'utils/GA';
import { TEST_CONFIG } from 'utils/Settings';
import packageInfo from '../../package.json';
var API_URL = environment.apiUrl;
var OSINFO = window.navigator.userAgent;
var ENV = environment.name;
var CLIENT = "".concat(packageInfo.build.appId, "/").concat(packageInfo.version, " ")
    + "(".concat(OSINFO, ") ").concat(packageInfo.name, " (").concat(ENV, ") ")
    + "node/".concat(process.versions.node, " ")
    + "chrome/".concat(process.versions.chrome, " ")
    + "v8/".concat(process.versions.v8, " ");
var getAuth = function (secret, url) { return __awaiter(void 0, void 0, void 0, function () {
    var ronly;
    return __generator(this, function (_a) {
        switch (_a.label) {
            case 0:
                if (!secret)
                    return [2 /*return*/, {}];
                ronly = url.replace(/\?[\s\S]*$/g, '');
                return [4 /*yield*/, import('@flixxo/authorizator').then(function (mod) { return __awaiter(void 0, void 0, void 0, function () {
                        var signer, authToken;
                        return __generator(this, function (_a) {
                            switch (_a.label) {
                                case 0:
                                    signer = new mod.Signer(secret);
                                    return [4 /*yield*/, signer.generateToken(ronly)];
                                case 1:
                                    authToken = _a.sent();
                                    return [2 /*return*/, { Authorization: "Basic ".concat(btoa(authToken.toString())) }];
                            }
                        });
                    }); })];
            case 1: return [2 /*return*/, _a.sent()];
        }
    });
}); };
var useApi = function () {
    var t = useTranslation().t;
    var executeRecaptcha = useGoogleReCaptcha().executeRecaptcha;
    var logout = useSession().logout;
    var _a = useStore(), secretCommon = _a.secret, secretRegister = _a.secretRegister;
    var secret = secretCommon || secretRegister;
    var toast = useToast().toast;
    var baseURL = "".concat(API_URL, "/api");
    var apiFetch = function (props) { return __awaiter(void 0, void 0, void 0, function () {
        var url, _a, customHeaders, _b, method, _c, data, _d, captcha, auth, lang, captchaTokenV3;
        return __generator(this, function (_e) {
            switch (_e.label) {
                case 0:
                    url = props.url, _a = props.customHeaders, customHeaders = _a === void 0 ? {} : _a, _b = props.method, method = _b === void 0 ? 'get' : _b, _c = props.data, data = _c === void 0 ? {} : _c, _d = props.captcha, captcha = _d === void 0 ? {} : _d;
                    return [4 /*yield*/, getAuth(secret, url)];
                case 1:
                    auth = _e.sent();
                    lang = i18n.language;
                    captchaTokenV3 = '';
                    if (!(captcha.type === RECAPTCHA_TYPES.v3)) return [3 /*break*/, 3];
                    return [4 /*yield*/, executeRecaptcha(captcha.action)];
                case 2:
                    captchaTokenV3 = _e.sent();
                    _e.label = 3;
                case 3: return [4 /*yield*/, Axios.request({
                        baseURL: baseURL,
                        url: url,
                        method: method,
                        data: data,
                        headers: __assign(__assign({ Accept: 'application/json', 'Content-Type': 'application/json', 'x-flixxo-client': CLIENT, 'Accept-Language': lang, 'x-flixxo-lang': lang, 'x-flixxo-captcha-type': localStorage.getItem(TEST_CONFIG.captchaBypass)
                                ? 'bypass'
                                : captcha.type, 'x-flixxo-captcha-token': captchaTokenV3 }, auth), customHeaders),
                    })
                        .catch(function (res) {
                        var _a;
                        var response = res === null || res === void 0 ? void 0 : res.response;
                        var resError = (_a = response === null || response === void 0 ? void 0 : response.data) === null || _a === void 0 ? void 0 : _a.error;
                        var code = (response === null || response === void 0 ? void 0 : response.status) || '';
                        var message = (resError === null || resError === void 0 ? void 0 : resError.message) || (res === null || res === void 0 ? void 0 : res.error) || (res === null || res === void 0 ? void 0 : res.message) || '';
                        var name = (resError === null || resError === void 0 ? void 0 : resError.name) || '';
                        var details = (resError === null || resError === void 0 ? void 0 : resError.details) || (res === null || res === void 0 ? void 0 : res.stack) || '';
                        var newError = {
                            code: code,
                            message: message,
                            name: name,
                            details: details,
                            url: url,
                            version: packageInfo.version,
                        };
                        GAHandler('res_error', newError);
                        if (newError.code === 401) {
                            logout === null || logout === void 0 ? void 0 : logout();
                        }
                        if (newError.code === 412) {
                            toast(t('error:api.humanVerification'), { icon: 'error' });
                        }
                        // if (newError.code === 500) {
                        //   toast(t('error:api.unexpected'), { icon: 'error' })
                        // }
                        throw newError;
                    })];
                case 4: return [2 /*return*/, _e.sent()];
            }
        });
    }); };
    return { apiFetch: apiFetch };
};
export default useApi;
