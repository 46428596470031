import { useQueryClient } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useAccount, useDisconnect, useSignMessage } from 'wagmi';
import { create } from 'zustand';
import { useWeb3Modal } from '@web3modal/wagmi/react';
import { walletApi } from 'api';
import { GAHandler } from 'utils/GA';
import useOpen from './useOpen';
var useStore = create(function (set) { return ({
    isConnected: false,
    address: null,
    connect: function (address) {
        set(function () { return ({ address: address, isConnected: true, isConnecting: false }); });
    },
    disconnect: function () {
        set(function () { return ({ address: null, isConnected: false, isConnecting: false }); });
    },
}); });
var useExternalWallet = function () {
    var queryClient = useQueryClient();
    var openWeb3Modal = useWeb3Modal().open;
    var _a = useAccount(), isConnectedFront = _a.isConnected, addressFront = _a.address;
    var disconnectFront = useDisconnect().disconnect;
    var _b = useSignMessage(), signMessage = _b.signMessage, signature = _b.data, isLoading = _b.isLoading, isSuccessSignature = _b.isSuccess, isErrorSignature = _b.isError, error = _b.error;
    var _c = useStore(), isConnected = _c.isConnected, address = _c.address, connect = _c.connect, disconnect = _c.disconnect;
    var _d = walletApi.useRegisterWallet(), registerWallet = _d.mutateAsync, isWalletRegistered = _d.isSuccess, isConnecting = _d.isLoading, isError = _d.isError;
    var signState = useOpen();
    var message = walletApi.useGetMessage({ address: address, enabled: signState.isOpen }).data;
    /**
     * This state is local to avoid all useExternalWallet
     * tries to register the wallet.
     */
    var _e = useState(false), isRegistering = _e[0], setIsRegistering = _e[1];
    var handleConnect = function (origin) {
        GAHandler('tg_connection_modal', { origin: origin });
        openWeb3Modal();
        setIsRegistering(true);
    };
    var handleDisconnect = function () {
        GAHandler('tg_disconnected');
        disconnectFront();
        disconnect();
        localStorage.removeItem('wagmi.store');
        localStorage.removeItem('wagmi.walletConnect.requestedChains');
        localStorage.removeItem('wagmi.recentConnectorId');
    };
    var sign = function () {
        GAHandler('tg_signature_modal');
        signState.open();
    };
    /**
     * SIGN MESSAGE
     * When
     **/
    useEffect(function () {
        if (!message || !signState.isOpen)
            return;
        signMessage({ message: message });
        signState.close();
        GAHandler('tg_signature_sent');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [message]);
    useEffect(function () {
        if (isSuccessSignature) {
            GAHandler('tg_signature_success');
        }
        if (isErrorSignature) {
            GAHandler('tg_signature_error', error);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isErrorSignature, isSuccessSignature]);
    /**
     * STEP 0
     * Check if wallet is already connected
     * This assumes wallet was already registered
     **/
    useEffect(function () {
        if (!isConnectedFront) {
            if (isConnected)
                disconnect();
            return;
        }
        if (isConnected)
            return;
        connect(addressFront);
    }, []);
    /**
     * STEP 1: Register wallet
     **/
    useEffect(function () {
        if (!isRegistering || !isConnectedFront || !addressFront)
            return;
        registerWallet({ address: addressFront });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isRegistering, isConnectedFront, addressFront]);
    /**
     * STEP 2: Update global state
     **/
    useEffect(function () {
        if (!isWalletRegistered || !addressFront)
            return;
        GAHandler('tg_connection_success');
        connect(addressFront);
        queryClient.invalidateQueries({
            predicate: function (q) { return q.queryKey.includes('content'); },
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isWalletRegistered]);
    /**
     * STEP 3: Disconnect on error
     **/
    useEffect(function () {
        if (!isError)
            return;
        GAHandler('tg_connection_error');
        handleDisconnect();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isError]);
    return {
        isConnected: isConnected,
        isConnecting: isConnecting,
        address: address,
        connect: handleConnect,
        disconnect: handleDisconnect,
        sign: sign,
        signature: signature,
        isWaitingSignature: signState.isOpen || isLoading,
    };
};
export default useExternalWallet;
