var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
export var LinkStyled = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  justify-content: flex-start;\n  align-items: center;\n  gap: 8px;\n\n  width: fit-content;\n\n  animation: fadeIn 500ms ease-in-out;\n  animation-delay: 900ms;\n  animation-fill-mode: backwards;\n\n  @include respond(phone) {\n    display: none;\n  }\n\n  font-size: ", ";\n  font-weight: ", ";\n  color: ", ";\n  transition: color 200ms;\n\n  :hover {\n    color: ", ";\n  }\n"], ["\n  display: flex;\n  justify-content: flex-start;\n  align-items: center;\n  gap: 8px;\n\n  width: fit-content;\n\n  animation: fadeIn 500ms ease-in-out;\n  animation-delay: 900ms;\n  animation-fill-mode: backwards;\n\n  @include respond(phone) {\n    display: none;\n  }\n\n  font-size: ", ";\n  font-weight: ", ";\n  color: ", ";\n  transition: color 200ms;\n\n  :hover {\n    color: ", ";\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.old.fonts.size.normal;
}, function (_a) {
    var theme = _a.theme;
    return theme.old.fonts.weight.medium;
}, function (_a) {
    var theme = _a.theme;
    return theme.old.colors.white;
}, function (_a) {
    var theme = _a.theme;
    return theme.old.colors.gray[750];
});
var templateObject_1;
