import common from './common'
import old from './old'

export const light = {
  old,
  fonts: common.fonts,
  colors: common.colors,
  text: {
    default: '#71717A',
    secondary: '',
    headline: '#1A1A1A',
    highlight: '#7F39FA',
    error: '#F43F5E',
    brand: '#7F39FA',
    white: 'white',
    danger: '#D02744',
  },
  snackbar: {
    // default: {
    //   background: '#18181B',
    //   border: '1px solid #27272A',
    // },
    error: {
      background: '#18181B',
      border: '1px solid #27272A',
      color: '#fff',
    },
  },
  input: {
    // this is from darkkkkkk
    main: {
      default: {
        background: '#27272A',
        color: {
          text: 'white',
          placeholder: '#A1A1AA',
        },
        border: '1px solid #3F3F46',
      },
      hover: {
        background: '#27272A',
        color: {
          text: 'white',
          placeholder: 'white',
        },
        border: '1px solid #3F3F46',
      },
      error: {
        border: '1px solid #F43F5E',
        color: '#F43F5E',
      },
    },
  },
  button: {
    primary: {
      default: {
        background: '#7F39FA',
        color: 'white',
        border: 'none',
      },
      hover: {
        background: '#6D28D9',
        color: '',
        border: '',
      },
      loading: {
        background: '#4C1D95',
        color: '',
        border: '',
      },
      disabled: {
        background: '#7F39FA',
        color: '',
        border: '',
      },
    },
    secondary: {
      default: {},
      hover: {},
      loading: {},
      disabled: {},
    },
    tertiary: {
      default: {
        background: 'transparent',
        color: '#D4D4D8',
        border: '',
      },
      hover: {
        background: '#D1FAE5',
        color: '#18181B',
        border: '',
      },
      loading: {
        background: '',
        color: '',
        border: '',
      },
      disabled: {
        background: 'transparent',
        color: '#FAFAFA',
        border: '',
      },
    },
    'secondary-invert': {
      default: {
        background: '#27272A',
        color: '#D4D4D8',
        border: '1px solid #3F3F46',
      },
      hover: {
        background: '',
        color: 'white',
        border: '',
      },
      loading: {
        background: '#3F3F46',
        color: '#D4D4D8',
        border: '1px solid #52525B',
      },
      disabled: {
        background: '#27272A',
        color: '#D4D4D8',
        border: '',
      },
    },
  },
  modal: {
    background: 'white',
    border: '1px solid rgba(255, 255, 255, 0.24)',
    boxShadow: '0px 1px 2px 0px #10182859',
    mobile: {
      borderTop: '1px solid rgba(255, 255, 255, 0.24)',
    },
  },
  card: {
    primary: {
      default: {
        background: '',
        border: '',
      },
      hover: {
        background: '',
        border: '',
      },
      focus: {
        background: '',
        border: '',
      },
    },
    secondary: {
      default: {
        background: '#D1FAE5',
        border: '#FAFAFA',
      },
    },
    inverted: { // footer creators
      default: {
        background: '#18181B',
        border: '1px solid black',
      },
      hover: {
        background: '#27272A',
        border: '1px solid #3F3F46',
      },
      focus: {
        background: '#27272A',
        border: '1px solid white',
      },
    },
  },
  lang: {
    main: '#F2F2F2',
  },
}
