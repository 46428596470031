import { jsx as _jsx } from "react/jsx-runtime";
import { contentApi } from 'api';
import { CarouselCard, CarouselFullWidth, } from 'ui';
import { getContentUrl } from 'utils/contents';
import { getMedia, getPoster } from 'utils/thumbnails';
var CarouselContent = function (props) {
    var title = props.title, uuid = props.uuid, isVertical = props.isVertical;
    var _a = contentApi.useGetCarousel(uuid), data = _a.data, isLoading = _a.isLoading, isError = _a.isError;
    return (_jsx(CarouselFullWidth, { isLoading: isLoading, isError: isError, title: title, isVertical: isVertical, children: data === null || data === void 0 ? void 0 : data.map(function (content) {
            var cover = getMedia({ media: content.media });
            var poster = getPoster({ media: content.media });
            var title = content.title, body = content.body;
            var currentCover = isVertical ? poster : cover;
            return (function (_a) {
                var isMoving = _a.isMoving;
                return (_jsx(CarouselCard, { state: { title: title, body: body, cover: cover }, title: title, imgSrc: currentCover, to: getContentUrl(content.contentType, content.uuid), isVertical: isVertical, forceClose: isMoving }, content.uuid));
            });
        }) }));
};
export default CarouselContent;
