import { CONTENT_STATUS } from 'config/constants'

const oldColors = {
  // primary: '#6432D8',
  darkPrimary: '#3C1A89',
  lightPrimary: '#F0EDFF',

  secondary: '#FFC404',
  darkSecondary: '#E6B02A',
  lightSecondary: '#FFE9BF',

  // black: '#000',
  lightBlack: '#343146', // Text

  bg: '#222036',
  lightBg: '#F5F6FA',
  darkBg: '#161424', // #343146 #211F35 #161424 - background

  gray: '#CCCCCC',
  darkGray: '#909090',
  lightGray: '#F2F2F2',

  error: '#FF7F40',
  info: '#448AFF',
  warning: '#FECE45',
  success: '#21B94A',
  // white: '#FFF',
  transparent: 'rgba(255,255,255,.0)',

  // rediseño
  grey: '#808080',
  white15: '#262626',
  bleachedSilk: '#F2F2F2',
  warmPink: '#FF5988',
  darkShamrock: '#2FC894',
  meltingGlacier: '#EBFAF5',
  sweetScape: '#7F39FA',
  orochimaru: '#D9D9D9',
  neonBoneyard: '#D9C4FD',
  purpleIllutionist: '#9F6BFB',
  purpleIllutionist2: '#B692F7',
  coldMorning: '#E5E5E5',
  tangledWeb: '#B3B3B3',
  maWhite: '#F5F9FC',
  aircraftWhite: '#EDF2F7',
  seashellPeach: '#FFF6DD',
  eerieBlack: '#1A1A1A',
  moreThanAWeek: '#8C8C8C',
  whiteSolid: '#F5F6FA',
  placeboPurple: '#F2EBFF',
  silver: '#BFBFBF',
  drWhite: '#FAFAFA',
  hoverButton: '#491F92',
  powderPuff: '#FFEEF3',
}

const newColors = {
  background: {
    play: 'black',
    creators: '#F5F9FC',
  },
  primary: '#7F39FA',
  balance: {
    play: 'white',
    creators: '#FFC61B',
  },
  purple: {
    900: '#F8F5FE',
    800: '#F2EBFF',
    700: '#D9C4FD',
    600: '#9F6BFB',
    500: '#7F39FA',
  },
  blue: {
    900: '#F5F6FA',
  },
  white: 'white',
  black: 'black',
  gray: {
    900: '#FAFAFA',
    800: '#F2F2F2',
    750: '#E5E5E5',
    700: '#D4D4D8',
    675: '#C8C8C8',
    650: '#c5c5c5',
    625: '#BFBFBF',
    600: '#B3B3B3',
    550: '#AFAFAF',
    525: '#A1A1AA',
    500: '#9C9C9C',
    450: '#8C8C8C',
    400: '#71717A',
    350: '#666',
    300: '#52525B',
    275: '#474747',
    250: '#434343',
    225: '#3F3F46',
    200: '#373737',
    190: '#34D399',
    175: '#27272A',
    150: '#242424',
    100: '#1A1A1A',
    75: '#18181B',
    50: '#064E3B',
  },
  status: {
    [CONTENT_STATUS.enabled]: '#2FC894',
    [CONTENT_STATUS.paused]: '#FFC61B',
    [CONTENT_STATUS.cancel]: '#8C8C8C66',
  },
}

const sizes = {
  header: '72px',
  sidesMargin: {
    large: '40px',
    medium: '',
    small: '16px',
  },
  avatar: '40px',
  button: {
    large: '48px',
    medium: '40px',
    small: '32px',
  },
  balance: {
    medium: {
      height: '40px',
      width: '120px',
      coin: '36px',
    },
    small: {
      height: '32px',
      width: '97px',
      coin: '24px',
    },
  },
}

const fonts = {
  family: {
    primary: "'Inter', sans-serif",
  },
  size: {
    smaller: '0.75rem', // 12px
    small: '0.875rem', // 14px
    normal: '1rem', // 16px
    semilarge: '1.25rem', // 20px
    large: '1.5rem', // 24px
    huge: '1.75rem', // 28px
    extrahuge: '2.25rem', // 36px
  },
  weight: {
    thin: '100',
    extralight: '200',
    light: '300',
    regular: '400',
    medium: '500',
    semibold: '600',
    bold: '700',
    extrabold: '800',
    black: '900',
  },
}

const breakpoints = {
  isMobile: '@media screen and (max-width: 500px)',
  isMobileBig: '@media screen and (max-width: 760px)',
  isTab: '@media screen and (max-width: 850px)',
  isTabBig: '@media screen and (max-width: 1100px)',
  isTabLand: '@media screen and (max-width: 1300px)',
  isLaptop: '@media screen and (max-width: 1600px)',
  isDesktop: '@media screen and (max-width: 1799px)',
  isBigDesktop: '@media screen and (min-width: 1800px)',
}

const old = {
  colors: { ...oldColors, ...newColors },
  sizes,
  fonts,
  breakpoints,
  fontSize: { // tamaños de fuentes
    label: '12px',
    text: '14px',
    header: '16px',
    subtitle: '20px',
    title: '24px',
    display: '32px',
    display1: '48px',
    display2: '64px',
    display3: '80px',
    display4: '120px',
    display5: '150px',

    12: '12px',
    14: '14px',
    16: '16px',
    24: '24px',
    28: '28px',
  },
  rounded: { // corner radius
    none: '0',
    xs: '4px',
    sm: '8px',
    md: '12px',
    lg: '16px',
    xl: '32px',
    xl1: '40px',

    0: '0px',
    4: '4px',
    6: '6px',
    8: '8px',
    12: '12px',
    16: '16px',
    20: '20px',
    24: '24px',
    32: '32px',
    36: '36px',
    40: '40px',
    48: '48px',
    circle: '50%',
  },
  spacing: { // paddings & margins
    none: '0',
    0: '0px',
    4: '4px',
    6: '6px',
    8: '8px',
    12: '12px',
    16: '16px',
    18: '18px',
    20: '20px',
    24: '24px',
    28: '28px',
    32: '32px',
    36: '36px',
    40: '40px',
    48: '48px',
  },
  shadow: {
    // 1: '0 4px 10px 0 rgba(100,50,216,0.1)',
    1: '0 3px 8px -1px rgba(50,50,71,.05)',
    button: {
      hover: '0 4px 4px rgba(0,0,0,.25)',
      active: 'inset 0 4px 4px rgba(0,0,0,.25)',
    },
    menu: '0 4px 20px 0 rgba(0,0,0,.15)',
    black: '0 0 10px rgba(0,0,0,.12)',
  },
  opacity: {
    10: '.1',
    20: '.2',
    30: '.3',
    40: '.4',
    50: '.5',
    60: '.6',
    70: '.7',
    80: '.8',
    90: '.9',
    100: '1',
  },
  effects: {
    blur: 'blur(4px)',
    gray: 'rgba(0,0,0,.3)',
    lighting: 'rgba(255,255,255,.1)',
    darkening: 'rgba(0,0,0,.5)',
    bg80: 'rgba(34,32,54,.8)',
  },
  animate: {
    fadeIn: 'fadeIn 1s ease-in-out',
  },
  keyframes: {
    fadeIn: '@keyframes fadeIn { from {opacity: 0;} to {opacity: 1;} }',
  },
  animations: {
    fadeIn: `
        @keyframes fadeIn {
          from { opacity: 0; }
          to { opacity: 1; }
        }
      `,
  },
  is: {
    isMobile: '@media(max-width: 500px)',
    isMobileBig: '@media(max-width: 760px)',
    isTab: '@media(max-width: 850px)',
    isTabLand: '@media(max-width: 1220px)',
    isDesktop: '@media(max-width: 1457px)',
    isBigDesktop: '@media(max-width: 1800px)',
  },
  transition: {
    default: '.3s',
  },
}

export default old
