var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
import styled from 'styled-components';
import { dark } from 'styles/theme/dark';
import { light } from 'styles/theme/light';
export var TextStyled = styled.p(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  ", "\n"], ["\n  ", "\n"])), function (props) {
    var _a, _b;
    var $theme = props.$theme, $size = props.$size, $weight = props.$weight, $customWeight = props.$customWeight, $color = props.$color, $align = props.$align, $style = props.$style, $lineClamp = props.$lineClamp, $lineHeight = props.$lineHeight;
    // const family = theme.fonts.family.primary
    var fontSize = $size
        ? typeof $size === 'number'
            ? "".concat($size / 16, "rem")
            : $theme.fonts.size[$size].fontSize
        : undefined;
    var fontWeight = typeof $customWeight === 'number'
        ? $customWeight
        : $theme.fonts.weight[$customWeight]
            || ((_a = $theme.fonts.size[$size]) === null || _a === void 0 ? void 0 : _a.weight)
            || $theme.fonts.weight[$weight];
    var lHeight = $lineHeight
        ? typeof $lineHeight === 'number'
            ? "".concat($lineHeight / 16, "rem")
            : $lineHeight
        : (_b = $theme.fonts.size[$size]) === null || _b === void 0 ? void 0 : _b.lineHeight;
    var textColor = $theme.text[$color] || $color;
    var lineClampProps = typeof $lineClamp === 'number'
        ? {
            display: '-webkit-box',
            lineClamp: $lineClamp,
            WebkitLineClamp: $lineClamp,
            WebkitBoxOrient: 'vertical',
            overflow: 'hidden',
            textOverflow: 'ellipsis',
        }
        : {};
    return (__assign(__assign({ 
        // fontFamily: family,
        fontSize: fontSize, fontWeight: fontWeight, lineHeight: lHeight, color: textColor, textAlign: $align, whiteSpace: 'pre-line' }, lineClampProps), $style));
});
var Text = function (props) {
    var as = props.as, size = props.size, weight = props.weight, _a = props.color, color = _a === void 0 ? 'default' : _a, _b = props.align, align = _b === void 0 ? 'left' : _b, style = props.style, _c = props.theme, theme = _c === void 0 ? 'dark' : _c, children = props.children, lineClamp = props.lineClamp, lineHeight = props.lineHeight, className = props.className;
    var selectedTheme = theme === 'dark' ? dark : light;
    return (_jsx(TextStyled, { as: as, className: className, "$style": style, "$size": size, "$weight": weight !== null && weight !== void 0 ? weight : 'normal', "$customWeight": weight, "$color": color, "$align": align, "$lineClamp": lineClamp, "$lineHeight": lineHeight, "$theme": selectedTheme, children: children }));
};
export default React.memo(Text);
var templateObject_1;
