import React from 'react'

import { Container, Dot } from './styles'

const Pagination = ({ index, total }) => (
  <Container>
    { Array(total).fill().map((_, idx) => (
      <Dot key={idx} $isActive={index === idx} />
    ))}
  </Container>
)

export default Pagination
