import { useQuery } from '@tanstack/react-query'

import useApi from 'hooks/useApi'

const useGetSuggestionsSeries = () => {
  const { apiFetch } = useApi()
  const url = '/suggestions/series/unfinished'

  const getSuggestions = () => {
    return apiFetch({ url })
      .then(res => res.data)
  }

  const options = {
    staleTime: 60000 * 30, // 30 min
  }

  return useQuery(['suggestions_series'], getSuggestions, options)
}

export default useGetSuggestionsSeries
