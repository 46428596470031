import React, { createContext, useState } from 'react'

export const snackbarContext = createContext({})
snackbarContext.displayName = 'SnackbarContext'

const SnackbarProvider = ({ children }) => {
  const [data, setData] = useState({})

  const value = { data, setData }

  return (
    <snackbarContext.Provider value={value}>
      {children}
    </snackbarContext.Provider>
  )
}

export default SnackbarProvider
