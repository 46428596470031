import { jsx as _jsx } from "react/jsx-runtime";
import { useResponsive } from 'hooks';
var Icon = function (props) {
    var Icon = props.icon, _a = props.bgColor, bgColor = _a === void 0 ? '#D1FAE5' : _a, _b = props.color, color = _b === void 0 ? '#059669' : _b;
    var isMobile = useResponsive().isMobile;
    if (!Icon)
        return null;
    return (_jsx("div", { style: {
            backgroundColor: bgColor,
            borderRadius: '100%',
            display: 'grid',
            placeItems: 'center',
            height: isMobile ? 20 : 32,
            width: isMobile ? 20 : 32,
            minHeight: isMobile ? 20 : 32,
            minWidth: isMobile ? 20 : 32,
            color: color,
        }, children: _jsx(Icon, { height: isMobile ? 16 : 20, width: isMobile ? 16 : 20 }) }));
};
export default Icon;
