export var formatAmount = function (amount, decimals) {
    if (amount === void 0) { amount = 0; }
    if (decimals === void 0) { decimals = 2; }
    var clean = String(amount).replace(/[^\d.]/g, '').replaceAll(',', '');
    var _a = clean.split('.'), int = _a[0], dec = _a[1];
    var newInt = String(Number(int)).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    var newDec = dec === undefined
        ? '.00'
        : '.' + dec.substring(0, decimals);
    return "".concat(newInt).concat(newDec);
    // const factor = 10 ** decimals
    // console.log({ amount2: Number(amount) })
    // const truncated = Math.trunc(Number(amount) * factor) / factor
    // console.log({ truncated })
    // const formatedAmount = new Intl.NumberFormat(
    //   'en-US',
    //   { minimumFractionDigits: decimals, maximumFractionDigits: decimals },
    // ).format(truncated)
    // return formatedAmount
};
export var formatAmountEntry = function (value) {
    var clean = value.replace(/[^\d.]/g, '').replaceAll(',', '');
    var _a = clean.split('.'), int = _a[0], dec = _a[1];
    var newInt = String(Number(int)).replace(/\B(?=(\d{3})+(?!\d))/g, ',');
    var newDec = dec === undefined
        ? ''
        : ".".concat(dec).substring(0, 3);
    return "".concat(newInt).concat(newDec);
};
export var amountStrToNum = function (value) {
    var clean = value.replaceAll(',', '');
    return Number(clean);
};
