import { jsx as _jsx } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { contentApi } from 'api';
import { CarouselCard, CarouselFullWidth } from 'ui';
import { getContentUrl } from 'utils/contents';
import { getMedia } from 'utils/thumbnails';
var CarouselSuggestions = function () {
    var t = useTranslation().t;
    var _a = contentApi.useGetSuggestionsSeries(), isLoading = _a.isLoading, isError = _a.isError, _b = _a.data, data = _b === void 0 ? [] : _b;
    if (!isError && !data.length) {
        return null;
    }
    return (_jsx(CarouselFullWidth, { isLoading: isLoading, isError: isError, title: t('continueWatching'), children: data === null || data === void 0 ? void 0 : data.map(function (content) {
            var to = getContentUrl(content.contentType, (content === null || content === void 0 ? void 0 : content.serie.uuid) || content.uuid);
            var cover = getMedia({ media: (content === null || content === void 0 ? void 0 : content.serie.media) || content.media });
            var author = content.author, body = content.body, title = content.title;
            return (_jsx(CarouselCard, { state: { title: title, body: body, cover: cover, author: author }, to: to, imgSrc: cover }, content.uuid));
        }) }));
};
export default CarouselSuggestions;
