import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { userApi } from 'api';
import { ReactComponent as StarFillSvg } from 'assets/icons/star-fill.svg';
import { ReactComponent as StarOutlineSvg } from 'assets/icons/star-outline.svg';
import { Avatar, Button, Card, Text } from 'ui';
import { getAvatarSrc } from 'utils/media';
var Creator = function (props) {
    var creator = props.creator, onClick = props.onClick, isFollowed = props.isFollowed;
    var _a = userApi.useFollow(), follow = _a.follow, unfollow = _a.unfollow, isLoadingFollow = _a.isLoading;
    var handleFollow = function (e) {
        e.stopPropagation();
        follow(creator.id);
    };
    var handleUnfollow = function (e) {
        e.stopPropagation();
        unfollow(creator.id);
    };
    return (_jsxs(Card, { onClick: onClick, style: {
            border: 'none',
            padding: 14,
            marginTop: 16,
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            gap: 12,
        }, children: [_jsx(Avatar, { src: getAvatarSrc(creator.Profile, 'h164'), style: {
                    border: 'none',
                    boxShadow: '5px 0px 4px 0px #00000040',
                } }), _jsx("div", { style: { flexGrow: 1 }, children: _jsx(Text, { size: 14, weight: 500, color: 'headline', children: creator.nickname }) }), _jsx(Button, { size: 'small', iconSize: 16, variant: 'secondary', iconLeft: isFollowed ? StarFillSvg : StarOutlineSvg, onClick: isFollowed ? handleUnfollow : handleFollow, isLoading: isLoadingFollow, style: { color: '#D9D9D9', minWidth: 32 } })] }));
};
export default Creator;
