import { useEffect, useState } from 'react';
import { isDrmSupported } from 'ui/PlayerLanding/Player/useVideojsSamples';
var useDrmSupported = function (hasDrm) {
    var _a = useState(false), isSupported = _a[0], setIsSupported = _a[1];
    var _b = useState(hasDrm), isLoading = _b[0], setIsLoading = _b[1];
    useEffect(function () {
        if (!hasDrm)
            return;
        setIsLoading(true);
        isDrmSupported()
            .then(function (value) {
            setIsSupported(value);
            setIsLoading(false);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hasDrm]);
    return {
        isLoading: isLoading,
        isSupported: !hasDrm || (hasDrm && isSupported),
    };
};
export default useDrmSupported;
