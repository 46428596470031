import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTheme } from 'styled-components';
import { GoBack } from 'ui';
import { useResponsive } from 'hooks';
var Root = function (props) {
    var children = props.children;
    var isTab = useResponsive().isTab;
    var old = useTheme().old;
    return (_jsxs("div", { style: { marginTop: 145 }, children: [_jsx("div", { style: {
                    marginBottom: isTab ? 16 : 32,
                    marginLeft: isTab
                        ? old.sizes.sidesMargin.small
                        : old.sizes.sidesMargin.large,
                }, children: _jsx(GoBack, { to: '/' }) }), children] }));
};
export default Root;
