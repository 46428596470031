import { useQuery } from '@tanstack/react-query';
import { useApi } from 'hooks';
var useGetCountries = function () {
    var apiFetch = useApi().apiFetch;
    var url = '/countries';
    var getCountries = function () {
        return apiFetch({ url: url })
            .then(function (res) { return res.data.sort(function (a, b) { return a.Name.value.localeCompare(b.Name.value); }); });
    };
    var options = {
        staleTime: Infinity,
        cacheTime: Infinity,
    };
    return useQuery(['lang', 'countries'], getCountries, options);
};
export default useGetCountries;
