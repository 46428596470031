import { jsx as _jsx } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import ModalDownloadApp from 'templates/ModalDownloadApp';
import { ModalPortal } from 'ui';
var ModalDonateCreator = function (props) {
    var close = props.close;
    var t = useTranslation().t;
    return (_jsx(ModalPortal, { children: _jsx(ModalDownloadApp, { title: t('modals:downloadApp:wallet.title'), description: t('modals:downloadApp:wallet.description'), close: close }) }));
};
export default ModalDonateCreator;
