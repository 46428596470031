import { useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { useApi, useToast } from 'hooks';
var useRegisterWallet = function () {
    var t = useTranslation().t;
    var apiFetch = useApi().apiFetch;
    var toast = useToast().toast;
    var url = '/auth/externalWallets';
    var mutationFn = function (data) {
        return apiFetch({ method: 'POST', url: url, data: data })
            .then(function (res) { return res.data; });
    };
    return useMutation({
        mutationFn: mutationFn,
        onError: function (err) {
            var _a, _b;
            var serverMsg = (_b = (_a = err === null || err === void 0 ? void 0 : err.details) === null || _a === void 0 ? void 0 : _a[0]) === null || _b === void 0 ? void 0 : _b.message;
            var frontErr = serverMsg === 'address must be unique'
                ? t('tokenGating:error.alreadyUsed')
                : t('error:api.unexpected');
            toast(frontErr, { icon: 'error' });
        },
    });
};
export default useRegisterWallet;
