import { useMutation } from '@tanstack/react-query';
import { RECAPTCHA_TYPES } from 'config/constants';
import { useApi } from 'hooks';
var useForgotCode = function () {
    var apiFetch = useApi().apiFetch;
    var url = '/auth/forgotcode/sms';
    var mutationFn = function (_a) {
        var mobileNumber = _a.mobileNumber;
        return apiFetch({
            url: url,
            method: 'POST',
            data: { mobileNumber: mobileNumber },
            captcha: {
                type: RECAPTCHA_TYPES.v3,
                action: 'forgotcode_web',
            },
        })
            .then(function (res) { return res.data; });
    };
    return useMutation({ mutationFn: mutationFn });
};
export default useForgotCode;
