var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
export var DividerRootContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  align-items: center;\n  gap: 8px;\n  margin-top: 8px;\n  width: 100%;\n"], ["\n  display: flex;\n  align-items: center;\n  gap: 8px;\n  margin-top: 8px;\n  width: 100%;\n"])));
export var DividerStyled = styled.span(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  &::before {\n    content: '|';\n    color: ", ";\n    font-size: ", ";\n    font-weight: ", ";\n    line-height: 20px;\n  }\n"], ["\n  &::before {\n    content: '|';\n    color: ", ";\n    font-size: ", ";\n    font-weight: ", ";\n    line-height: 20px;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.old.colors.gray[400];
}, function (_a) {
    var theme = _a.theme;
    return theme.old.fonts.size.normal;
}, function (_a) {
    var theme = _a.theme;
    return theme.old.fonts.weight.medium;
});
var templateObject_1, templateObject_2;
