var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { useInView } from 'react-intersection-observer';
import { Spinner } from 'ui';
var Image = function (props) {
    var src = props.src, alt = props.alt, title = props.title, _a = props.defaultLoading, defaultLoading = _a === void 0 ? true : _a, _b = props.ratio, ratio = _b === void 0 ? 'cover' : _b, styleImg = props.styleImg;
    var _c = useState(defaultLoading), isLoading = _c[0], setIsLoading = _c[1];
    var _d = useState(false), isError = _d[0], setIsError = _d[1];
    var handleLoad = function () {
        setIsLoading(false);
    };
    var handleError = function () {
        setIsLoading(false);
        setIsError(true);
    };
    var _e = useInView({
        threshold: 0,
        rootMargin: '0px',
        triggerOnce: true,
        delay: 0,
    }), ref = _e.ref, inView = _e.inView;
    var ratioProps = ratio === 'cover'
        ? { aspectRatio: '16 / 9', width: '100%' }
        : ratio === 'poster'
            ? { aspectRatio: '3 / 4', height: '100%' }
            : { aspectRatio: '1', width: '100%', height: '100%' };
    if (isError) {
        return (_jsx("div", { style: __assign(__assign({ border: '1px solid #ccc', borderRadius: 6, backgroundColor: '#f2f2f2', objectFit: 'cover', display: 'grid', placeItems: 'center' }, ratioProps), styleImg), children: "ERROR" }));
    }
    if (!src) {
        return (_jsx("div", { style: __assign(__assign({ backgroundColor: '#f2f2f2', borderRadius: 6, display: 'grid', placeItems: 'center', color: 'black', fontWeight: 600 }, ratioProps), styleImg), children: "NO IMAGE" }));
    }
    return (_jsxs("div", { ref: ref, style: __assign({ position: 'relative' }, ratioProps), children: [isLoading && (_jsx("div", { style: __assign({ position: 'absolute', top: 0, left: 0, bottom: 0, right: 0, zIndex: 1, borderRadius: 6, display: 'grid', placeItems: 'center', backgroundColor: '#131313' }, ratioProps), children: _jsx(Spinner, { size: 30 }) })), inView && (_jsx("img", { src: src, alt: alt !== null && alt !== void 0 ? alt : title, title: title, onLoad: handleLoad, onError: handleError, style: __assign(__assign({ borderRadius: 6, objectFit: 'cover', width: '100%', opacity: isLoading ? 0 : 1 }, ratioProps), styleImg) }))] }));
};
export default Image;
