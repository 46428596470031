var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { t } from 'i18next';
export var getContentUrl = function (contentType, uuid) {
    if (contentType === 1)
        return "community/".concat(uuid);
    if (contentType === 2)
        return "series/".concat(uuid);
    if (contentType === 3)
        return "content/".concat(uuid); // shorts
    if (contentType === 4)
        return "content/".concat(uuid); // microshorts
    if (contentType === 5)
        return "content/".concat(uuid); // movies
    return '';
};
export var getContentTypeStr = function (contentType, count) {
    // if (contentType === 1) return t('community', { count })
    if (contentType === 2)
        return t('common:microseries', { count: count });
    if (contentType === 3)
        return t('common:shorts', { count: count });
    if (contentType === 4)
        return t('common:microshorts', { count: count });
    if (contentType === 5)
        return t('common:movies', { count: count });
    return '';
};
export var getSubtitles = function (content) {
    if (!content)
        return [];
    var subs = [];
    /** WHOLE SERIES */
    if (content === null || content === void 0 ? void 0 : content.season) {
        content.season.forEach(function (season) {
            season.content.forEach(function (content) {
                content.subtitle.forEach(function (sub) {
                    subs.push(sub.lang);
                });
            });
        });
        /** CONTENT */
    }
    else {
        content.subtitle.forEach(function (sub) {
            subs.push(sub.lang);
        });
    }
    var uniqueSubs = new Set(subs);
    var sortFn = function (first, last) { return first.localeCompare(last); };
    return Array.from(uniqueSubs).sort(sortFn);
};
export var getReleaseYear = function (content, contentType) {
    if (!content)
        return;
    var cType = contentType !== null && contentType !== void 0 ? contentType : content.contentType;
    if (cType === 2) {
        // season[0] -> fix for AyV because has each season as a separated series
        var firstSeason = content.season.find(function (s) { return s.number === 1; }) || content.season[0];
        var sortFn = function (first, last) { return first.releaseDate.localeCompare(last.releaseDate); };
        var firstPublished = __spreadArray([], firstSeason.content, true).sort(sortFn)[0];
        var publishedDate = new Date(firstPublished.releaseDate).getFullYear();
        return publishedDate;
    }
};
