const fonts = {
  family: {
    primary: "'Inter', sans-serif",
  },
  weight: {
    normal: '400',
    medium: '500',
    semibold: '600',
  },
  size: {
    '5xl': {
      fontSize: '3rem', // 48px
      lineHeight: '3.75rem', // 48px
      weight: '600',
    },
    '4xl': {
      fontSize: '2.25rem', // 36px
      lineHeight: '2.5rem', // 40px
      weight: '600',
    },
    '3xl': {
      fontSize: '1.875rem', // 30px
      lineHeight: '2.25rem', // 36px
      weight: '600',
    },
    '2xl': {
      fontSize: '1.5rem', // 24px
      lineHeight: '2rem', // 32px
      weight: '600',
    },
    xl: {
      fontSize: '1.25rem', // 20px
      lineHeight: '1.75rem', // 28px
      weight: '600',
    },
    l: {
      fontSize: '1.125rem', // 18px
      lineHeight: '1.5rem', // 24px
    },
    base: {
      fontSize: '1rem', // 16px
      lineHeight: '1.5rem', // 24px
    },
    sm: {
      fontSize: '0.875rem', // 14px
      lineHeight: '1.25rem', // 20px
    },
    xs: {
      fontSize: '0.75rem', // 12px
      lineHeight: '1rem', // 16px
    },
    '2xs': {
      fontSize: '0.625rem', // 10px
      lineHeight: '1rem', // 16px
    },
  },
}

const colors = {
  basics: {
    white: '#FFFFFF',
    black: '#000000',
    transparent: 'transparent',
    brand: '#7F39FA',
  },
  neutral: {
    900: '#18181B',
    800: '#27272A',
    700: '#3F3F46',
    600: '#52525B',
    500: '#71717A',
    400: '#A1A1AA',
    300: '#D4D4D8',
    200: '#E4E4E7',
    100: '#F4F4F5',
    50: '#FAFAFA',
  },
  primary: {
    900: '#4C1D95',
    800: '#5B21B6',
    700: '#6d28d9',
    600: '#7F39FA', // base
    500: '#8b5cf6',
    400: '#A78BFA',
    300: '#D9C4FD',
    200: '#ddd6fe',
    100: '#EDE9FE',
    50: '#F5F3FF',
  },
  secondary: {
    green: {
      900: '#064e3b',
      400: '#34D399',
      100: '#d1fae5',
    },
    yellow: {
      900: '#d97706',
      400: '#fbbf24',
      100: '#fef3c7',
    },
    red: {
      900: '#881337',
      500: '#f43f5e',
      100: '#ffe4e6 ',
    },
  },
  transparent: {
    24: 'rgba(255, 255, 255, 0.24)',
    12: 'rgba(255, 255, 255, 0.12)',
  },
}

const common = {
  fonts,
  colors,
}

export default common
