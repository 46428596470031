import { useTranslation } from 'react-i18next';
import { useSearchParams } from 'react-router-dom';
import { useDebounce } from 'use-debounce';
import { create } from 'zustand';
import { MOODS } from './Moods/types';
var FILTERS = ['all', 'series', 'shorts'];
var useSearchStore = create(function (set) { return ({
    step: 'closed',
    query: '',
    setQuery: function (query) { set({ query: query }); },
    openIdle: function () { set({ step: 'idle' }); },
    openResults: function () { set({ step: 'results' }); },
    close: function () { set({ step: 'closed', query: '' }); },
}); });
var useSearchHandler = function () {
    var _a;
    var t = useTranslation().t;
    var _b = useSearchParams(), searchParams = _b[0], setSearchParams = _b[1];
    var queryInput = searchParams.get('q');
    var queryMood = searchParams.get('mood');
    var querySearch = searchParams.get('s');
    var _c = useSearchStore(), step = _c.step, query = _c.query, setQuery = _c.setQuery, openIdle = _c.openIdle, openResults = _c.openResults, close = _c.close;
    var _d = useDebounce(query, 300), queryBounced = _d[0], bounced = _d[1];
    var mood = MOODS.find(function (m) { return m.value === queryMood; });
    var resultsFor = (_a = (queryInput !== null && queryInput !== void 0 ? queryInput : mood === null || mood === void 0 ? void 0 : mood.getLabel(t))) !== null && _a !== void 0 ? _a : '';
    var open = function () {
        var s = querySearch !== null && querySearch !== void 0 ? querySearch : '';
        var hasValidFilter = FILTERS.includes(s);
        var validFilter = hasValidFilter ? s : 'all';
        setSearchParams(function (params) {
            params.delete('q');
            params.delete('mood');
            params.set('s', validFilter);
            return params;
        });
        openIdle();
    };
    var handleClose = function () {
        setSearchParams(function (params) {
            params.delete('s');
            params.delete('q');
            params.delete('mood');
            return params;
        });
        close();
    };
    var closeResults = function () {
        setSearchParams(function (params) {
            params.delete('q');
            params.delete('mood');
            params.set('s', 'all');
            return params;
        });
        setQuery('');
        openIdle();
    };
    var byMood = function (mood) {
        setSearchParams(function (params) {
            params.set('mood', mood);
            return params;
        });
    };
    var changeContentType = function (type) {
        setSearchParams(function (params) {
            params.set('s', type);
            return params;
        });
    };
    var setQueryParams = function (query) {
        if (mood)
            return;
        setSearchParams(function (params) {
            params.set('q', query);
            return params;
        });
    };
    return {
        query: query,
        queryInput: queryInput,
        queryBounced: queryBounced,
        mood: mood,
        contentType: querySearch,
        resultsFor: resultsFor,
        isOpen: step !== 'closed',
        isShowingResults: step === 'results',
        open: open,
        showResults: openResults,
        close: handleClose,
        closeResults: closeResults,
        byMood: byMood,
        changeContentType: changeContentType,
        setQuery: setQuery,
        setQueryParams: setQueryParams,
        step: step,
        isSearching: bounced.isPending(),
        openIdle: openIdle,
    };
};
export default useSearchHandler;
