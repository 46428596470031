import styled from 'styled-components'

export const SIZES = {
  large: {
    height: '48px',
    labelVariant: {
      default: 'base',
      focused: 'xs',
    },
    valueVariant: 'sm',
    errorVariant: 'sm',
  },
  medium: {
    height: '40px',
    labelVariant: {
      default: 'sm',
      focused: 'xs',
    },
    valueVariant: 'xs',
    errorVariant: 'xs',
  },
}

export const Container = styled.div`
  height: ${({ $size }) => SIZES[$size].height};
  
  background: ${({ theme }) => theme.input.main.default.background};
  border-radius: 8px;

  border: ${({ theme, $hasError }) => $hasError
    ? theme.input.main.error.border
    : theme.input.main.default.border
};

  padding: 6px 16px;
  width: 100%;
  display: flex;
  align-items: center;
  gap: 12px;

  &, label {
    cursor: text;
  }

  label {
    height: 100%;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

`

export const Input = styled.input`
  width: 100%;
  border: none;
  caret-color: white;

  height: ${({ $isActive }) => $isActive
    ? '100%'
    : '0'
};

  background-color: ${({ theme }) => theme.colors.basics.transparent};
  color: ${({ theme }) => theme.input.main.default.color.text};
  font-size: ${({ $size }) => SIZES[$size].valueVariant};

  -webkit-text-fill-color: ${({ theme }) => theme.input.main.default.color.text} !important;
  box-shadow: 0 0 0px 1000px ${({ theme }) => theme.input.main.default.background} inset;
`

export const Placeholder = styled.div`
    display: ${({ $hide }) => $hide ? 'none' : 'grid'};
    place-items: center start;
    height: 100%;

    color: ${({ theme, $isHover }) => $isHover
    ? theme.input.main.hover.color.placeholder
    : theme.input.main.default.color.placeholder
};

    transition: color 200ms;
`

export const ErrorContainer = styled.div`
  margin-top: 6px;
  color: ${({ theme }) => theme.input.main.error.color}
`
