var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
import { ReactComponent as CheckSVG } from 'assets/icons/check.svg';
export var Container = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  min-height: 100%;\n  height: 100%;\n  margin-left: 1px; // fix for left border with auto slideTo\n"], ["\n  min-height: 100%;\n  height: 100%;\n  margin-left: 1px; // fix for left border with auto slideTo\n"])));
export var Content = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  height: 100%;\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n"], ["\n  height: 100%;\n  width: 100%;\n  display: flex;\n  flex-direction: column;\n"])));
export var Footer = styled.footer(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  display: grid;\n  grid-template-columns: 1fr auto;\n  /* margin-top: 20px; */\n  padding: 16px 16px 0;\n"], ["\n  display: grid;\n  grid-template-columns: 1fr auto;\n  /* margin-top: 20px; */\n  padding: 16px 16px 0;\n"])));
export var Check = styled(CheckSVG)(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n  background-color: ", ";\n  color: ", ";\n  border-radius: 100%;\n  padding: 4px;\n  height: 20px;\n  width: 20px;\n"], ["\n  background-color: ", ";\n  color: ", ";\n  border-radius: 100%;\n  padding: 4px;\n  height: 20px;\n  width: 20px;\n"])), function (_a) {
    var theme = _a.theme;
    return theme.old.colors.gray[50];
}, function (_a) {
    var theme = _a.theme;
    return theme.old.colors.gray[190];
});
export var TitleWrapper = styled.div(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  display: grid;\n  place-items: center left;\n  \n  p {\n    font-size: ", ";\n    font-weight: ", ";\n    line-height: 20px;\n  }\n\n"], ["\n  display: grid;\n  place-items: center left;\n  \n  p {\n    font-size: ", ";\n    font-weight: ", ";\n    line-height: 20px;\n  }\n\n"])), function (_a) {
    var theme = _a.theme;
    return theme.old.fonts.size.small;
}, function (_a) {
    var theme = _a.theme;
    return theme.old.fonts.weight.regular;
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
