var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled, { css } from 'styled-components';
import { creators, text, flameInverted, success } from './variants';
var HEIGHT = {
    huge: '60px',
    large: '48px',
    medium: '40px',
    small: '32px',
};
var FONT_SIZE = {
    large: '1rem',
    medium: '14px',
    small: '12px',
};
var LINE_HEIGHT = {
    large: '24px',
    medium: '20px',
    small: '20px',
};
export var ButtonStyled = styled.button(templateObject_5 || (templateObject_5 = __makeTemplateObject(["\n  border-radius: ", ";\n  border: none;\n  font-weight: 400;\n  cursor: pointer;\n  transition: all 200ms;\n  padding: ", ";\n\n  font-weight: ", ";\n\n  font-size: ", ";\n  line-height: ", ";\n  height: ", ";\n  min-height: ", ";\n  width: ", ";\n  min-width: ", ";\n\n\n  color: inherit;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  gap: 8px;\n  white-space: nowrap;\n  position: relative;\n\n  \n  ", "\n\n\n  ", "\n\n  &:disabled {\n    opacity: .4;\n    cursor: not-allowed;\n    pointer-events: fill;\n  }\n"], ["\n  border-radius: ", ";\n  border: none;\n  font-weight: 400;\n  cursor: pointer;\n  transition: all 200ms;\n  padding: ", ";\n\n  font-weight: ", ";\n\n  font-size: ", ";\n  line-height: ", ";\n  height: ", ";\n  min-height: ", ";\n  width: ", ";\n  min-width: ", ";\n\n\n  color: inherit;\n  display: flex;\n  align-items: center;\n  justify-content: center;\n  gap: 8px;\n  white-space: nowrap;\n  position: relative;\n\n  \n  ", "\n\n\n  ", "\n\n  &:disabled {\n    opacity: .4;\n    cursor: not-allowed;\n    pointer-events: fill;\n  }\n"])), function (_a) {
    var $borderRadius = _a.$borderRadius;
    return "".concat($borderRadius, "px");
}, function (_a) {
    var $isIcon = _a.$isIcon;
    return $isIcon ? 0 : '0 12px';
}, function (_a) {
    var theme = _a.theme, $weight = _a.$weight;
    return theme.fonts.weight[$weight];
}, function (_a) {
    var size = _a.size;
    return FONT_SIZE[size];
}, function (_a) {
    var size = _a.size;
    return LINE_HEIGHT[size];
}, function (_a) {
    var size = _a.size;
    return HEIGHT[size];
}, function (_a) {
    var size = _a.size;
    return HEIGHT[size];
}, function (_a) {
    var $isFull = _a.$isFull, $isIcon = _a.$isIcon, size = _a.size;
    return $isFull
        ? '100%'
        : $isIcon ? HEIGHT[size] : 'fit-content';
}, function (_a) {
    var $isFull = _a.$isFull, $isIcon = _a.$isIcon, size = _a.size;
    return $isFull
        ? 'unset'
        : $isIcon ? HEIGHT[size] : 'fit-content';
}, function (_a) {
    var theme = _a.theme, $variant = _a.$variant, $isLoading = _a.$isLoading, $animation = _a.$animation, $animationDuration = _a.$animationDuration;
    if (theme.button[$variant]) {
        return css(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n        background: ", ";\n        color: ", ";\n        border: ", ";\n        \n        &:hover:not(:disabled) {\n          background: ", ";\n          color: ", ";\n          border: ", ";\n        }\n\n        ", "\n\n        &:disabled {\n          background: ", ";\n        }\n\n        ", "\n      "], ["\n        background: ", ";\n        color: ", ";\n        border: ", ";\n        \n        &:hover:not(:disabled) {\n          background: ", ";\n          color: ", ";\n          border: ", ";\n        }\n\n        ", "\n\n        &:disabled {\n          background: ", ";\n        }\n\n        ", "\n      "])), theme.button[$variant].default.background, theme.button[$variant].default.color, theme.button[$variant].default.border, theme.button[$variant].hover.background, theme.button[$variant].hover.color, theme.button[$variant].hover.border, $isLoading && css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n          background: ", ";\n\n          &:hover {\n            background: ", ";\n          }\n        "], ["\n          background: ", ";\n\n          &:hover {\n            background: ", ";\n          }\n        "])), theme.button[$variant].loading.background, theme.button[$variant].loading.background), theme.button[$variant].disabled.background, !$isLoading && css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n          &:active:not(:disabled) {\n            opacity: .6;\n          }\n        "], ["\n          &:active:not(:disabled) {\n            opacity: .6;\n          }\n        "]))));
    }
    if ($variant === 'creators')
        return creators;
    if ($variant === 'text')
        return text;
    if ($variant === 'flame-inverted')
        return flameInverted;
    if ($variant === 'success')
        return success;
}, function (_a) {
    var $isLoading = _a.$isLoading;
    return $isLoading && css(templateObject_4 || (templateObject_4 = __makeTemplateObject(["\n    cursor: not-allowed;\n  "], ["\n    cursor: not-allowed;\n  "])));
});
var templateObject_1, templateObject_2, templateObject_3, templateObject_4, templateObject_5;
