var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { ToastContainer as Toastify } from 'react-toastify';
import styled from 'styled-components';
export var StyledToastify = styled(Toastify)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  width: 95%;\n  max-width: 800px;\n  left: 50%;\n  transform: translate(-50%);\n  bottom: 0;\n\n  .Toastify__toast {\n    padding: 16px;\n    border-radius: 12px;\n    margin-bottom: 16px;\n\n    ", " {\n      padding: 12px;\n      border-radius: 8px;\n      margin-bottom: 24px;\n    }\n  }\n\n  .Toastify__toast-body {\n    padding: 0px;\n  }\n\n  .Toastify__toast-theme--dark {\n    background-color: #444;\n    border: 1px solid #666;\n  }\n  \n  .Toastify__toast-theme--light {\n    background-color: #FAFAFA;\n    border: 1px solid #E4E4E7;\n    color: #52525B;\n  }\n\n  .Toastify__toast-theme--colored.Toastify__toast--default {\n  }\n\n  .Toastify__toast-theme--colored.Toastify__toast--info {\n  }\n\n  .Toastify__toast-theme--colored.Toastify__toast--success {\n  }\n\n  .Toastify__toast-theme--colored.Toastify__toast--warning {\n  }\n\n  .Toastify__toast-theme--colored.Toastify__toast--error {\n  }\n"], ["\n  width: 95%;\n  max-width: 800px;\n  left: 50%;\n  transform: translate(-50%);\n  bottom: 0;\n\n  .Toastify__toast {\n    padding: 16px;\n    border-radius: 12px;\n    margin-bottom: 16px;\n\n    ", " {\n      padding: 12px;\n      border-radius: 8px;\n      margin-bottom: 24px;\n    }\n  }\n\n  .Toastify__toast-body {\n    padding: 0px;\n  }\n\n  .Toastify__toast-theme--dark {\n    background-color: #444;\n    border: 1px solid #666;\n  }\n  \n  .Toastify__toast-theme--light {\n    background-color: #FAFAFA;\n    border: 1px solid #E4E4E7;\n    color: #52525B;\n  }\n\n  .Toastify__toast-theme--colored.Toastify__toast--default {\n  }\n\n  .Toastify__toast-theme--colored.Toastify__toast--info {\n  }\n\n  .Toastify__toast-theme--colored.Toastify__toast--success {\n  }\n\n  .Toastify__toast-theme--colored.Toastify__toast--warning {\n  }\n\n  .Toastify__toast-theme--colored.Toastify__toast--error {\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.old.breakpoints.isMobile;
});
var templateObject_1;
