import { useSearchParams } from 'react-router-dom';
var FROM_OPTIONS = ['webview', 'app', 'web'];
var ACTIONS = ['delete'];
var VIEWS = ['challenges'];
var useQueryString = function () {
    var _a = useSearchParams(), searchParams = _a[0], setSearchParams = _a[1];
    var from = FROM_OPTIONS.find(function (opt) { return opt === searchParams.get('from'); });
    var action = ACTIONS.find(function (opt) { return opt === searchParams.get('action'); });
    var view = VIEWS.find(function (opt) { return opt === searchParams.get('view'); });
    var remove = function (key) {
        searchParams.delete(key);
        setSearchParams(searchParams);
    };
    var add = function (key, value) {
        searchParams.append(key, value);
        setSearchParams(searchParams);
    };
    var data = {
        from: from,
        action: action,
        view: view,
        remove: remove,
        add: add,
    };
    return data;
};
export default useQueryString;
