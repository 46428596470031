import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { userApi } from 'api';
import { useResponsive } from 'hooks';
import { PlayerEarning } from 'ui';
var WEB_VIDEO = {
    es: '/onboard/web-es.mp4',
    en: '/onboard/web-en.mp4',
};
var APP_VIDEO = {
    es: '/onboard/app-es.mp4',
    en: '/onboard/app-en.mp4',
};
var PlayerOnboarding = function (props) {
    var onClose = props.onClose, _a = props.notify, notify = _a === void 0 ? true : _a;
    var i18n = useTranslation().i18n;
    var isPortrait = useResponsive().isPortrait;
    var _b = userApi.useNotifyOnboard(), notifyOnboard = _b.notifyOnboard, isSuccess = _b.isSuccess;
    var src = isPortrait
        ? APP_VIDEO[i18n.language]
        : WEB_VIDEO[i18n.language];
    var handleNotify = function () {
        if (notify)
            notifyOnboard();
        else
            onClose();
    };
    useEffect(function () {
        if (!isSuccess)
            return;
        onClose();
    }, [isSuccess]);
    return (_jsx(PlayerEarning, { onEnd: handleNotify, src: src, hideClose: true }));
};
export default PlayerOnboarding;
