var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled, { css } from 'styled-components';
import { isMobile as isMobileDevice } from 'react-device-detect';
export var CarouselContainer = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  ", "\n"], ["\n  ", "\n"])), function (_a) {
    var theme = _a.theme;
    if (isMobileDevice) {
        return css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n      ", " {\n        margin-left: ", ";\n        margin-right: 0;\n      }\n\n      ", " {\n        margin-left: ", ";\n        margin-right: 0;\n      }\n    "], ["\n      ", " {\n        margin-left: ", ";\n        margin-right: 0;\n      }\n\n      ", " {\n        margin-left: ", ";\n        margin-right: 0;\n      }\n    "])), theme.old.breakpoints.isTabLand, function (_a) {
            var theme = _a.theme;
            return theme.old.sizes.sidesMargin.large;
        }, theme.old.breakpoints.isTab, function (_a) {
            var theme = _a.theme;
            return theme.old.sizes.sidesMargin.small;
        });
    }
    return css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n      margin: 0 ", ";\n  \n      ", " {\n        margin-left: ", ";\n        margin-right: 0;\n      }\n    "], ["\n      margin: 0 ", ";\n  \n      ", " {\n        margin-left: ", ";\n        margin-right: 0;\n      }\n    "])), theme.old.sizes.sidesMargin.large, theme.old.breakpoints.isTab, function (_a) {
        var theme = _a.theme;
        return theme.old.sizes.sidesMargin.small;
    });
});
var templateObject_1, templateObject_2, templateObject_3;
