import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { useGetMe } from 'api/user';
import { ReactComponent as UserAddSvg } from 'assets/icons/user-add.svg';
import { Button, Text, TooltipAction } from 'ui';
import { GAHandler } from 'utils/GA';
var ActionInvite = function (props) {
    var invites = props.invites;
    var t = useTranslation().t;
    var me = useGetMe().data;
    var copyShareText = function () {
        var nickname = me === null || me === void 0 ? void 0 : me.nickname;
        var text = t('challenges:inviteMsg', { nickname: nickname });
        navigator.clipboard.writeText(text);
        GAHandler('referral_intent');
    };
    return (_jsxs("div", { style: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
            gap: 8,
        }, children: [_jsx(TooltipAction, { children: function (tooltip) { return (_jsx(Button, { size: 'small', variant: 'secondary', iconRight: UserAddSvg, iconSize: 16, style: { backgroundColor: '#27272a' }, onClick: function () {
                        copyShareText();
                        tooltip.show();
                    }, children: t('challenges:invite') })); } }), _jsx(Text, { size: 12, weight: 500, align: 'right', children: t('challenges:newRegisters', { count: invites }) })] }));
};
export default ActionInvite;
