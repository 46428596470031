import styled from 'styled-components'

export const Container = styled.div`
  padding-top: 10dvh;

  display: grid;
  place-items: center;

  background-color: ${({ theme, $isCreators }) => $isCreators
    ? theme.old.colors.background.creators
    : theme.old.colors.background.play
};
`
