import styled, { css } from 'styled-components'


export const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-bottom: 60px;
  width: 100%;

  & > svg {
    color: ${({ theme }) => theme.old.colors.gray[200]}
  }

  ${({ $isCreators }) => $isCreators && css`
    background-color: ${({ theme }) => theme.old.colors.white};
    width: 80vw;
    max-width: 700px;
    border-radius: 8px;
    padding: 50px 20px 70px;

    & > svg {
      color: ${({ theme }) => theme.old.colors.gray[800]}
    }
  `}
`


export const Code = styled.span`
  margin-top: 10px;

  background-color: ${({ theme, $isCreators }) => $isCreators
    ? theme.old.colors.gray[800]
    : theme.old.colors.gray[100]
};
`
