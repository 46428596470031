var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { useLayoutEffect, useState } from 'react';
var PRESET = {
    cover: {
        0: 2,
        500: 3,
        850: 3,
        1100: 4,
        1300: 5,
        1600: 6,
        1920: 7,
    },
    poster: {
        0: 2.4,
        500: 3.4,
        650: 4.4,
        850: 5,
        1300: 6,
        1500: 7,
        1920: 8,
        2200: 9,
    },
};
var useCarouselSlides = function (props) {
    var _a = props !== null && props !== void 0 ? props : {}, _b = _a.preset, preset = _b === void 0 ? 'cover' : _b, config = _a.config;
    var _c = useState(1), slidesPerView = _c[0], setSlidesPerView = _c[1];
    var currentConfig = config !== null && config !== void 0 ? config : PRESET[preset];
    var calculateSlides = function () {
        var vw = window.innerWidth;
        var entries = __spreadArray([], Object.entries(currentConfig), true).reverse();
        for (var _i = 0, entries_1 = entries; _i < entries_1.length; _i++) {
            var _a = entries_1[_i], breakpoint = _a[0], slides = _a[1];
            if (Number(breakpoint) <= vw) {
                setSlidesPerView(slides);
                break;
            }
        }
    };
    useLayoutEffect(function () {
        calculateSlides();
        window.addEventListener('resize', calculateSlides);
        return function () {
            window.removeEventListener('resize', calculateSlides);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return slidesPerView;
};
export default useCarouselSlides;
