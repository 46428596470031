var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { useInView } from 'react-intersection-observer';
var Observer = function (props) {
    var children = props.children, _a = props.threshold, threshold = _a === void 0 ? 0 : _a, _b = props.rootMargin, rootMargin = _b === void 0 ? '-75px 0px' : _b, containerStyle = props.containerStyle;
    var _c = useInView({
        threshold: threshold,
        rootMargin: rootMargin,
        triggerOnce: true,
    }), ref = _c.ref, inView = _c.inView;
    if (!inView) {
        return (_jsx("div", { style: __assign({ height: 200 }, containerStyle), ref: ref }));
    }
    return children;
};
export default Observer;
