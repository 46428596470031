import { useMutation } from '@tanstack/react-query'

import { useApi } from 'hooks'
import { RECAPTCHA_TYPES } from 'config/constants'

const useCheck = () => {
  const { apiFetch } = useApi()

  const url = '/auth/check'

  const check = ({ mobileNumber, email, nickname }) => {
    return apiFetch({
      url,
      method: 'POST',
      data: {
        mobileNumber,
        email,
        nickname,
      },
      captcha: {
        type: RECAPTCHA_TYPES.v3,
        action: 'check_web',
      },
    })
      .then(res => res.data)
  }

  const {
    mutate,
    ...rest
  } = useMutation(check)

  return {
    check: mutate,
    ...rest,
  }
}

export default useCheck
