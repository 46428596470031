import { useMutation } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { RECAPTCHA_TYPES } from 'config/constants';
import { useApi, useToast } from 'hooks';
import { GAHandler } from 'utils/GA';
var useLoginVas = function () {
    var t = useTranslation().t;
    var apiFetch = useApi().apiFetch;
    var toast = useToast().toast;
    var mutationFn = function (props) {
        var provider = props.provider, token = props.token;
        var url = "/auth/login/vas/".concat(provider);
        return apiFetch({
            url: url,
            method: 'POST',
            data: { token: token },
            captcha: {
                type: RECAPTCHA_TYPES.v3,
                action: 'login_web_vas',
            },
        }).then(function (res) { return res.data; });
    };
    return useMutation({
        mutationFn: mutationFn,
        onSuccess: function (data, variables, context) {
            GAHandler('login_vas', { provider: variables.provider });
        },
        onError: function (error, variables, context) {
            GAHandler('login_vas_error', { provider: variables.provider });
        },
    });
};
export default useLoginVas;
