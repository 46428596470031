import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { ReactComponent as CheckSVG } from 'assets/icons/check.svg';
import { ReactComponent as CloseSVG } from 'assets/icons/exit.svg';
import { Input } from 'ui';
var SelectBtn = function (props) {
    var options = props.options, size = props.size, _a = props.icon, icon = _a === void 0 ? CloseSVG : _a, selected = props.selected, onChange = props.onChange, _b = props.formatSelectedValue, formatSelectedValue = _b === void 0 ? function (value) { return value; } : _b, defaultValue = props.defaultValue;
    var formatOptionLabel = function (_a, meta) {
        var _b;
        var value = _a.value, label = _a.label;
        if (meta.context === 'value') {
            return formatSelectedValue(value);
        }
        var isSelected = ((_b = meta.selectValue[0]) === null || _b === void 0 ? void 0 : _b.value) === value;
        return (_jsxs("div", { style: {
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                minWidth: 200,
            }, children: [label, isSelected ? _jsx(CheckSVG, { height: 20 }) : ''] }));
    };
    return (_jsx(Input.Select, { options: options, placeholder: ' ', size: size, hideChevron: true, icon: icon, isSearchable: false, selected: selected, defaultValue: defaultValue, onChange: onChange, formatOptionLabel: formatOptionLabel }));
};
export default SelectBtn;
