import { useQuery } from '@tanstack/react-query';
import { useApi } from 'hooks';
var useGetSimilars = function (props) {
    var uuid = props.uuid, enabled = props.enabled, _a = props.purchaseMethods, purchaseMethods = _a === void 0 ? 3 : _a;
    var apiFetch = useApi().apiFetch;
    var url = "/suggestions/contents/similar/".concat(uuid)
        + "?purchaseMethods=".concat(purchaseMethods);
    var queryFn = function () {
        return apiFetch({ url: url })
            .then(function (res) { return res.data; });
    };
    return useQuery({
        queryFn: queryFn,
        queryKey: ['similars', uuid, 'lang'],
        staleTime: 60000 * 10, // 30 min
        enabled: enabled,
    });
};
export default useGetSimilars;
