var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx } from "react/jsx-runtime";
import { Skeleton } from 'ui';
import defaultAvatar from 'assets/icons/avatar-default.svg';
var SIZES = {
    small: 32,
    medium: 40,
    large: 48,
    full: '100%',
};
var Avatar = function (props) {
    var src = props.src, className = props.className, _a = props.size, size = _a === void 0 ? 'medium' : _a, isLoading = props.isLoading, _b = props.theme, theme = _b === void 0 ? 'dark' : _b, _c = props.style, style = _c === void 0 ? {} : _c;
    var avatar = src !== null && src !== void 0 ? src : defaultAvatar;
    var isDark = theme === 'dark';
    var darkProps = {
        border: '1px solid#3F3F46',
        backgroundColor: '#27272a',
    };
    var lightProps = {
        border: '1px solid#A1A1AA',
        backgroundColor: '#f8f5fe',
        boxShadow: '1px 1px 3px 0px #00000033',
    };
    var themeProps = isDark ? darkProps : lightProps;
    if (isLoading) {
        return (_jsx(Skeleton, { className: className, style: __assign(__assign({ borderRadius: 6, width: SIZES[size], height: SIZES[size] }, themeProps), style) }));
    }
    return (_jsx("div", { className: className, style: __assign(__assign({ borderRadius: 6, overflow: 'hidden', width: SIZES[size], minWidth: SIZES[size], height: SIZES[size], backgroundImage: "url(".concat(avatar, ")"), backgroundSize: '100%', backgroundRepeat: 'no-repeat' }, themeProps), style) }));
};
export default Avatar;
