import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { Anteroom, Text } from 'ui';
import { formatDuration } from 'utils/duration';
export var EpisodeInfo = function (props) {
    var serie = props.serie, season = props.season, episode = props.episode, title = props.title, duration = props.duration, body = props.body, audios = props.audios, subtitles = props.subtitles, likes = props.likes;
    var t = useTranslation().t;
    var seasonEpisode = t('seasonEpisodeHyphen', { episode: episode, season: season });
    var titleText = "".concat(seasonEpisode, ": ").concat(title);
    return (_jsxs(_Fragment, { children: [_jsx(Anteroom.TitleSuper, { isLoading: !serie, children: serie }), _jsx(Anteroom.Title, { isLoading: !title, children: titleText }), likes >= 1 && (_jsxs(Text, { size: 'sm', weight: 'medium', color: '#FBBF24', style: { marginTop: 8 }, children: ["\u2665\uFE0E ", likes] })), _jsxs(Anteroom.Divider.Root, { isLoading: !audios, children: [_jsx(Anteroom.Divider.Child, { children: formatDuration(duration) }), _jsxs(Anteroom.Divider.Child, { children: [t('audio'), " (", audios, ")"] }), (subtitles === null || subtitles === void 0 ? void 0 : subtitles.length) && (_jsxs(Anteroom.Divider.Child, { children: [t('common:subtitles'), " (", subtitles.join(', '), ")"] }))] }), _jsx(Anteroom.Body, { isLoading: !body, children: body })] }));
};
