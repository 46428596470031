import { create } from 'zustand';
var STATE_VERSION = 8;
var useStore = create(function (set) {
    var _a;
    return ({
        /**
         * VERSION
         */
        isOldVersion: localStorage.getItem('state') !== String(STATE_VERSION),
        updateStateVersion: function () {
            localStorage.setItem('state', String(STATE_VERSION));
            set(function () { return ({ isOldVersion: false }); });
        },
        /**
         * AUTH
         */
        secret: (_a = localStorage.getItem('secret')) !== null && _a !== void 0 ? _a : '',
        secretRegister: '',
        isSeriesCreator: localStorage.getItem('isSeriesCreator'),
        referrer: null,
        setSecret: function (secret) {
            set(function () { return ({ secret: secret }); });
        },
        setReferrer: function (referrer) {
            set(function () { return ({ referrer: referrer }); });
        },
        setSecretRegister: function (secretRegister) {
            set(function () { return ({ secretRegister: secretRegister }); });
        },
        setIsSeriesCreator: function (value) {
            set(function () { return ({ isSeriesCreator: value }); });
        },
        /**
         * SEARCH
         */
        isSearch: false,
        isSearchResults: false,
        setSearch: function (value) {
            set(function () { return ({ isSearch: value, isSearchResults: false }); });
        },
        setSearchResults: function (value) {
            set(function () { return ({ isSearch: true, isSearchResults: value }); });
        },
    });
});
export default useStore;
