var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import styled from 'styled-components';
import { ReactComponent as CloseSVG } from 'assets/icons/exit.svg';
import { useOpen, useResponsive } from 'hooks';
import { Button, ModalPortal, Card } from 'ui';
var CardStyled = styled(Card)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: absolute;\n  right: 0;\n  bottom: 0;\n  transform: translate(0%, 0%);\n  animation: ", ";\n\n  @keyframes showSide {\n    from {\n      transform: translate(100%);\n    }\n    to {\n      transform: translate(0%);\n    }\n  }\n\n  @keyframes hideSide {\n    from {\n      transform: translate(0%);\n    }\n    to {\n      transform: translate(100%);\n    }\n  }\n\n  ", " {\n    animation: ", ";\n    @keyframes showUp {\n      from {\n        transform: translate(0, 100%);\n      }\n      to {\n        transform: translate(0, 0%);\n      }\n    }\n\n    @keyframes hideUp {\n      from {\n        transform: translate(0, 100%);\n      }\n      to {\n        transform: translate(0, 0%);\n      }\n    }\n  }\n"], ["\n  position: absolute;\n  right: 0;\n  bottom: 0;\n  transform: translate(0%, 0%);\n  animation: ", ";\n\n  @keyframes showSide {\n    from {\n      transform: translate(100%);\n    }\n    to {\n      transform: translate(0%);\n    }\n  }\n\n  @keyframes hideSide {\n    from {\n      transform: translate(0%);\n    }\n    to {\n      transform: translate(100%);\n    }\n  }\n\n  ", " {\n    animation: ", ";\n    @keyframes showUp {\n      from {\n        transform: translate(0, 100%);\n      }\n      to {\n        transform: translate(0, 0%);\n      }\n    }\n\n    @keyframes hideUp {\n      from {\n        transform: translate(0, 100%);\n      }\n      to {\n        transform: translate(0, 0%);\n      }\n    }\n  }\n"])), function (_a) {
    var $isOpen = _a.$isOpen;
    return $isOpen
        ? 'showSide 300ms ease forwards'
        : 'hideSide 300ms ease forwards';
}, function (_a) {
    var theme = _a.theme;
    return theme.old.breakpoints.isMobile;
}, function (_a) {
    var $isOpen = _a.$isOpen;
    return $isOpen
        ? 'showUp 300ms ease forwards'
        : 'hideUp 300ms ease forwards';
});
var InfoExtend = function (props) {
    var close = props.close, children = props.children;
    var isMobile = useResponsive().isMobile;
    var info = useOpen(true);
    var handleClose = function () {
        info.close();
        setTimeout(function () {
            close();
        }, 300);
    };
    return (_jsx(ModalPortal, { onBgClick: handleClose, style: {
            opacity: info.isOpen ? 1 : 0,
            transition: 'all 200ms',
        }, children: _jsxs(CardStyled, { "$isOpen": info.isOpen, style: {
                height: isMobile ? 520 : '100vh',
                width: '100%',
                maxWidth: isMobile ? 'unset' : 400,
                placeSelf: isMobile ? 'end center' : 'center end',
                border: 'none',
                borderRadius: isMobile ? '16px 16px 0 0' : '32px 0 0 32px',
                padding: isMobile ? '16px 16px 48px' : '16px 20px 32px',
                display: 'flex',
                flexDirection: 'column',
            }, children: [_jsx("div", { style: {
                        display: 'flex',
                        justifyContent: 'end',
                    }, children: _jsx(Button, { variant: 'secondary', onClick: handleClose, iconLeft: CloseSVG, iconSize: 20, style: { backgroundColor: '#0d0d0f', border: 'none' } }) }), _jsx("div", { style: {
                        marginTop: isMobile ? 12 : 24,
                        overflow: 'auto',
                        paddingRight: 10,
                        flexGrow: 1,
                    }, children: children })] }) }));
};
export default InfoExtend;
var templateObject_1;
