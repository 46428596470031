import common from './common'
import old from './old'

export const dark = {
  old,
  fonts: common.fonts,
  colors: common.colors,
  text: {
    default: '#A1A1AA',
    secondary: '#D4D4D8',
    headline: '#FFFFFF',
    highlight: '#FFFFFF',
    error: '#F43F5E',
    danger: '#D02744',
  },
  button: {
    primary: {
      default: {
        background: '#7F39FA',
        color: 'white',
        border: '1px solid #7F39FA',
      },
      hover: {
        background: '#8b5cf6',
        color: '',
        border: '',
      },
      loading: {
        background: '#4C1D95',
        color: '',
        border: '',
      },
      disabled: {
        background: '#7F39FA',
        color: '',
        border: '',
      },
    },
    secondary: {
      default: {
        background: '#18181B',
        color: '#D4D4D8',
        border: '1px solid #3F3F46',
      },
      hover: {
        background: '',
        color: 'white',
        border: '1px solid #71717A',
      },
      loading: {
        background: '#3F3F46',
        color: '#D4D4D8',
        border: '1px solid #52525B',
      },
      disabled: {
        background: '#18181B',
        color: '#D4D4D8',
        border: '',
      },
    },
    tertiary: {
      default: {
        background: 'transparent',
        color: '#D4D4D8',
        border: '',
      },
      hover: {
        background: '#27272A',
        color: 'white',
        border: '',
      },
      loading: {
        background: '#3F3F46',
        color: '#D4D4D8',
        border: '',
      },
      disabled: {
        background: 'transparent',
        color: '#D4D4D8',
        border: '',
      },
    },
    'secondary-invert': {
      default: {
        background: '#E4E4E7',
        color: '#52525B',
        border: '1px solid #D4D4D8',
      },
      hover: {
        background: '',
        color: '#27272A',
        border: '1px solid #27272A',
      },
      loading: {
        background: '#D4D4D8',
        color: '#52525B',
        border: '1px solid #D4D4D8',
      },
      disabled: {
        background: '#E4E4E7',
        color: '#52525B',
        border: '',
      },
    },
    danger: {
      default: {
        background: '#D02744',
        color: '#FFFFFF',
        border: '1px solid #881337',
      },
      hover: {
        background: '#F43F5E',
        color: '',
        border: '1px solid #D02744',
      },
      loading: {
        background: '#F43F5E',
        color: '',
        border: '1px solid #D02744',
      },
      disabled: {
        background: '',
        color: '',
        border: '',
      },
      active: {
        background: '#D02744',
        color: '#666666',
        border: '1px solid #D02744',
      },
    },
    flame: {
      default: {
        background: 'linear-gradient(118.36deg, #C602E6 0%, #E6C100 100%)',
        color: '#FFFFFF',
        border: '',
      },
      hover: {
        background: 'linear-gradient(118.36deg, rgba(198, 2, 230, 0.9) 0%, rgba(230, 193, 0, 0.9) 100%)',
        color: '',
        border: '',
      },
      loading: {
        background: 'linear-gradient(118.36deg, #C602E6 0%, #E6C100 100%), linear-gradient(118.36deg, #840199 0%, #998100 100%)',
        color: '',
        border: '',
      },
      disabled: {
        background: '',
        color: '',
        border: '',
      },
      active: {
        background: '',
        color: '',
        border: '',
      },
    },
  },
  input: {
    main: {
      default: {
        background: '#27272A',
        color: {
          text: 'white',
          placeholder: '#A1A1AA',
        },
        border: '1px solid #3F3F46',
      },
      hover: {
        background: '#27272A',
        color: {
          text: 'white',
          placeholder: 'white',
        },
        border: '1px solid #3F3F46',
      },
      error: {
        border: '1px solid #F43F5E',
        color: '#F43F5E',
      },
    },
  },
  snackbar: {
    // default: {
    //   background: '#18181B',
    //   border: '1px solid #27272A',
    // },
    error: {
      background: '#FAFAFA',
      border: '1px solid #E4E4E7',
      color: '#52525B',
    },
  },
  modal: {
    background: '#18181B',
    border: '1px solid rgba(255, 255, 255, 0.24)',
    boxShadow: '0px 1px 2px 0px #10182859',
    mobile: {
      borderTop: '1px solid #27272A',
    },
  },
  card: {
    primary: {
      default: {
        background: '#18181B',
        border: '1px solid #27272A',
      },
      hover: {
        background: '#27272A',
        border: '1px solid #3F3F46',
      },
      focus: {
        background: '#27272A',
        border: '1px solid white',
      },
    },
    secondary: {
      default: {
        background: '#27272A',
        border: '1px solid #3F3F46',
      },
    },
  },
  lang: {
    main: '#27272A',
  },
}
