import { useTranslation } from 'react-i18next'
import styled, { useTheme } from 'styled-components'

import { Text, Button, Div } from 'ui'
import { useSnackbar, useResponsive } from 'hooks'


const Container = styled.div`
  width: 100%;
  display: flex;
  gap: 8px;
  align-items: center;
`

const Error = ({ children, icon: Icon }) => {
  const { t } = useTranslation()

  const { isMobile } = useResponsive()
  const { Snackbar } = useSnackbar()
  const theme = useTheme()

  return (
    <Container>
      {Icon && (
        <Div display='grid' placeItems='center'>
          <Icon height={isMobile ? 28 : 36} width={isMobile ? 28 : 36} />
        </Div>
      )}

      <div style={{ flexGrow: 1 }}>
        <Text size={isMobile ? 'xs' : 'base'} weight='medium'>
          <span style={{ color: theme.snackbar.error.color }}>
            {children}
          </span>
        </Text>
      </div>

      <Button
        variant='secondary-invert'
        size={isMobile ? 'small' : 'medium'}
        onClick={Snackbar.close}
        weight='medium'
      >
        {t('common:ok')}
      </Button>
    </Container>
  )
}

export default Error
