import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { createPortal } from 'react-dom';
import { useDebouncedCallback } from 'use-debounce';
import { useResponsive } from 'hooks';
import { Image, LinkLang } from 'ui';
import { Slide } from './styles';
var CarouselCard = function (props) {
    var to = props.to, imgSrc = props.imgSrc, title = props.title, state = props.state, _a = props.isRounded, isRounded = _a === void 0 ? true : _a, _b = props.isHoverEffect, isHoverEffect = _b === void 0 ? true : _b, onClick = props.onClick, isVertical = props.isVertical, forceClose = props.forceClose;
    var isTab = useResponsive().isTab;
    var ref = useRef(null);
    var slideRef = useRef(null);
    var startRef = useRef(null);
    var _c = useState('off'), hover = _c[0], setHover = _c[1];
    var isHover = hover === 'on';
    var _d = useState(), pos = _d[0], setPos = _d[1];
    var root = document.getElementById('modal');
    var openDebounced = useDebouncedCallback(function () {
        setHover(function (status) {
            if (status === 'wait')
                return 'on';
            return 'off';
        });
    }, 50);
    var open = function () {
        var _a;
        if (!isHoverEffect)
            return;
        // Avoid hover on edge items
        var currentPos = (_a = ref.current) === null || _a === void 0 ? void 0 : _a.getBoundingClientRect();
        if (currentPos.right > window.innerWidth) {
            return;
        }
        startRef.current = currentPos;
        setHover('wait');
    };
    var close = function (delay) {
        if (delay === void 0) { delay = 150; }
        if (!isHover) {
            setHover('off');
            return;
        }
        if (slideRef.current) {
            slideRef.current.style.transition = "transform ".concat(delay, "ms ease, box-shadow 200ms ease");
            slideRef.current.style.transform = 'scale(1)';
            slideRef.current.style.boxShadow = '0 0 50px rgba(0,0,0, 0)';
        }
        setTimeout(function () {
            setHover('off');
        }, delay);
    };
    useEffect(function () {
        if (!forceClose)
            return;
        close(30);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [forceClose]);
    useLayoutEffect(function () {
        if (!ref.current || !startRef.current)
            return;
        if (hover === 'wait') {
            // Avoid open hover on search close
            setTimeout(function () {
                var _a;
                var currentPos = (_a = ref.current) === null || _a === void 0 ? void 0 : _a.getBoundingClientRect();
                if (startRef.current.top !== currentPos.top || startRef.current.left !== currentPos.left) {
                    return;
                }
                openDebounced();
            }, 0);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hover]);
    useLayoutEffect(function () {
        var _a;
        if (!isHover || !slideRef.current || !isHoverEffect)
            return;
        var scroll = function () {
            var _a;
            setPos((_a = ref.current) === null || _a === void 0 ? void 0 : _a.getBoundingClientRect());
        };
        var currentPos = (_a = ref.current) === null || _a === void 0 ? void 0 : _a.getBoundingClientRect();
        setPos(currentPos);
        slideRef.current.style.transition = 'transform 150ms ease, box-shadow 200ms ease';
        slideRef.current.style.transform = 'scale(1.2)';
        slideRef.current.style.boxShadow = '0 0 50px rgba(0,0,0, 1)';
        slideRef.current.style.transformOrigin = currentPos.left < 100
            ? 'left'
            : window.innerWidth - currentPos.right < 100
                ? 'right'
                : 'center';
        document.addEventListener('scroll', scroll);
        return function () {
            document.removeEventListener('scroll', scroll);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isHover, slideRef.current]);
    var Container = (_jsx(LinkLang, { to: to, state: state, onClick: onClick, style: { pointerEvents: 'none' }, children: _jsx("div", { style: {
                position: 'absolute',
                top: pos ? (pos === null || pos === void 0 ? void 0 : pos.top) + window.scrollY : undefined,
                left: pos === null || pos === void 0 ? void 0 : pos.left,
                width: pos === null || pos === void 0 ? void 0 : pos.width,
                height: pos === null || pos === void 0 ? void 0 : pos.height,
                zIndex: 9,
            }, children: _jsx(Slide, { ref: slideRef, "$isRounded": isRounded, children: _jsx(Image, { src: imgSrc, alt: title, title: title, defaultLoading: false, ratio: isVertical ? 'poster' : 'cover' }) }) }) }));
    var portal = createPortal(Container, root);
    return (_jsx("div", { ref: ref, style: { position: 'relative' }, onMouseEnter: open, onMouseLeave: function () { close(); }, children: _jsxs(LinkLang, { to: to, state: state, onClick: onClick, children: [_jsx(Slide, { "$isRounded": isRounded, children: _jsx(Image, { src: imgSrc, alt: title, title: title, ratio: isVertical ? 'poster' : 'cover' }) }), isHover && !isTab && portal] }) }));
};
export default CarouselCard;
