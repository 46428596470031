import { useMutation } from '@tanstack/react-query'

import { RECAPTCHA_TYPES } from 'config/constants'
import { useApi } from 'hooks'
import { useStore } from 'stores'
import { GAHandler } from 'utils/GA'


const useRegister = () => {
  const { apiFetch } = useApi()

  const { setSecretRegister } = useStore()

  const url = '/auth/register'

  const mutationFn = ({
    mobileNumber,
    email,
    nickname,
    password,
  }) => {
    return apiFetch({
      url,
      method: 'POST',
      data: {
        mobileNumber,
        email,
        nickname,
        password,
      },
      captcha: {
        type: RECAPTCHA_TYPES.v3,
        action: 'register_web',
      },
    })
      .then(res => res.data)
  }

  const {
    mutate,
    ...rest
  } = useMutation({
    mutationFn,
    onSuccess: async (data) => {
      GAHandler('user_created', undefined, true)
      gtag_report_conversion?.()

      await setSecretRegister(data.secret)
    },
  })

  return {
    register: mutate,
    ...rest,
  }
}

export default useRegister
