import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { createPortal } from 'react-dom';
import { ReactComponent as CloseSVG } from 'assets/icons/exit.svg';
import { useResponsive } from 'hooks';
import { Button } from 'ui';
import Player from 'ui/PlayerLanding/Player';
var PlayerEarning = function (props) {
    var onClose = props.onClose, onEnd = props.onEnd, onPlay = props.onPlay, src = props.src, hideClose = props.hideClose;
    var isMobileBig = useResponsive().isMobileBig;
    var _a = useState(0), duration = _a[0], setDuration = _a[1];
    var _b = useState(0), played = _b[0], setPlayed = _b[1];
    var _c = useState(0), transition = _c[0], setTransition = _c[1];
    var player = document.getElementById('player');
    var unfinishedClose = function () {
        onClose === null || onClose === void 0 ? void 0 : onClose();
    };
    var updateTime = function (time) {
        setPlayed(time);
        setTransition(time - played);
    };
    var handlePlay = function () {
        onPlay === null || onPlay === void 0 ? void 0 : onPlay();
    };
    var handleEnd = function () {
        onEnd === null || onEnd === void 0 ? void 0 : onEnd();
    };
    var adRoot = (_jsxs("div", { style: {
            position: 'fixed',
            top: 0,
            left: 0,
            width: '100%',
            height: '100%',
            zIndex: 1000,
            backgroundColor: 'rgba(0,0,0, 1)',
        }, children: [!hideClose && (_jsx("div", { style: {
                    position: 'absolute',
                    top: isMobileBig ? 16 : 40,
                    right: isMobileBig ? 16 : 40,
                    zIndex: 1,
                }, children: _jsx(Button, { onClick: unfinishedClose, size: isMobileBig ? 'medium' : 'huge', iconLeft: CloseSVG, iconSize: isMobileBig ? 28 : 40, variant: 'tertiary' }) })), _jsx(Player, { src: src, onDuration: setDuration, onPlay: handlePlay, onEnd: handleEnd, onTimeUpdate: updateTime }), _jsx("div", { style: {
                    position: 'absolute',
                    bottom: 0,
                    left: 0,
                    width: '100%',
                    height: isMobileBig ? 120 : 180,
                    background: 'linear-gradient(0deg, black, rgba(0,0,0,.8) 20%, rgba(0,0,0,.05) 90%, transparent)',
                } }), _jsx("div", { style: {
                    position: 'absolute',
                    bottom: 0,
                    left: 0,
                    width: '100%',
                    padding: isMobileBig ? 16 : 40,
                }, children: _jsx("div", { style: {
                        display: 'flex',
                        alignItems: 'center',
                        gap: isMobileBig ? 14 : 36,
                        marginTop: isMobileBig ? 10 : 16,
                    }, children: _jsx("div", { style: {
                            borderRadius: 100,
                            flexGrow: 1,
                            height: isMobileBig ? 6 : 10,
                            position: 'relative',
                            backgroundColor: 'rgba(255,255,255, .6)',
                            overflow: 'hidden',
                        }, children: _jsx("div", { style: {
                                backgroundColor: '#7F39FA',
                                width: "".concat((played * 100 / duration) || 0, "%"),
                                height: '100%',
                                transition: "all ".concat(transition, "s linear"),
                            } }) }) }) })] }));
    useEffect(function () {
        /**
         * Overflow is set visible when modal is closed.
         * So to override it, I need to use the timeout
         */
        setTimeout(function () {
            document.body.style.overflow = 'hidden';
        }, 0);
        return function () {
            document.body.style.overflow = 'visible';
        };
    }, []);
    return createPortal(adRoot, player);
};
export default PlayerEarning;
