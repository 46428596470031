var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useSession } from 'hooks';
import { Button, Card, Flixx, Pill, Text } from 'ui';
import { useGetMe } from 'api/user';
import { richStringToNodes } from 'utils/strings';
import { Check, Footer } from './styles';
var Cover = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  aspect-ratio: 16 / 9;\n  background: ", ";\n  background-size: 100%;\n  background-position: center;\n  background-repeat: no-repeat;\n  display: flex;\n  align-items: end;\n  position: relative;\n\n  &::after {\n    content: '';\n    background: linear-gradient(0deg, #18181B 0%, #000000 35%, rgba(0, 0, 0, 0) 100%);\n    height: 40%;\n    position: absolute;\n    left: 0;\n    right: 0;\n    bottom: -1px;\n  }\n"], ["\n  aspect-ratio: 16 / 9;\n  background: ", ";\n  background-size: 100%;\n  background-position: center;\n  background-repeat: no-repeat;\n  display: flex;\n  align-items: end;\n  position: relative;\n\n  &::after {\n    content: '';\n    background: linear-gradient(0deg, #18181B 0%, #000000 35%, rgba(0, 0, 0, 0) 100%);\n    height: 40%;\n    position: absolute;\n    left: 0;\n    right: 0;\n    bottom: -1px;\n  }\n"])), function (_a) {
    var $src = _a.$src;
    return "url(".concat($src, ")");
});
var CardContentSuggestion = function (props) {
    var to = props.to, cover = props.cover, duration = props.duration, category = props.category, body = props.body, pill = props.pill, withToken = props.withToken, onClickCTA = props.onClickCTA, price = props.price, isPurchased = props.isPurchased, title = props.title, isSelected = props.isSelected, isCtaDisabled = props.isCtaDisabled;
    var t = useTranslation().t;
    var isLoggedIn = useSession().isLoggedIn;
    var me = useGetMe().data;
    return (_jsxs(Card, { isSelected: isSelected, style: { overflow: 'hidden', paddingBottom: 16 }, mode: 'link', to: to, children: [_jsx(Cover, { "$src": cover, children: _jsx("div", { style: {
                        flexGrow: 1,
                        display: 'flex',
                        alignItems: 'end',
                    }, children: _jsxs("div", { style: {
                            position: 'relative',
                            zIndex: 1,
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            margin: '0 auto 18px',
                            gap: 6,
                        }, children: [pill && (_jsxs(_Fragment, { children: [_jsx(Pill, { size: 'sm', value: pill }), _jsx(Text, { color: '#71717A', children: "|" })] })), title && (_jsxs(_Fragment, { children: [_jsx(Text, { size: 'sm', weight: 'medium', color: 'headline', style: { maxWidth: 200 }, lineClamp: 1, children: title }), _jsx(Text, { color: '#71717A', children: "|" })] })), _jsx(Text, { size: 'sm', weight: 'medium', color: 'headline', children: duration }), category && (_jsxs(_Fragment, { children: [_jsx(Text, { color: '#71717A', children: "|" }), _jsx(Text, { size: 'sm', weight: 'medium', color: 'headline', children: category })] }))] }) }) }), _jsxs("div", { style: {
                    padding: '2px 12px 0',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    gap: 12,
                    flexGrow: 1,
                }, children: [_jsx(Text, { size: 'xs', align: 'center', lineClamp: 3, children: richStringToNodes(body) }), withToken && (_jsx(Text, { size: 'xs', weight: 'medium', style: {
                            background: 'linear-gradient(118.36deg, #DC02FF 0%, #FFD600 100%)',
                            WebkitBackgroundClip: 'text',
                            WebkitTextFillColor: 'transparent',
                            width: 'fit-content',
                            margin: '0 auto',
                        }, children: t('tokenGating:availableWithToken') }))] }), isLoggedIn && (isPurchased || !!price || !!onClickCTA) && (_jsxs(Footer, { children: [_jsxs("div", { style: { display: 'flex', alignItems: 'center', gap: 6 }, children: [isPurchased && (_jsxs(_Fragment, { children: [_jsx(Check, {}), _jsx(Text, { size: 'sm', color: 'headline', children: (me === null || me === void 0 ? void 0 : me.features.flixx)
                                            ? t('purchased')
                                            : t('anteroom:seen') })] })), !isPurchased && !!price && (me === null || me === void 0 ? void 0 : me.features.flixx) && (_jsx(Flixx, { sizeIcon: 27, textSize: 'sm', textWeight: 'semibold', price: price }))] }), !!onClickCTA && (_jsx("div", { style: { display: 'flex', alignItems: 'end' }, children: _jsx(Button, { onClick: onClickCTA, disabled: isCtaDisabled, borderRadius: 4, size: 'small', children: t('common:watchNow') }) }))] }))] }));
};
export default CardContentSuggestion;
var templateObject_1;
