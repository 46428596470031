import { useQuery } from '@tanstack/react-query';
import useApi from 'hooks/useApi';
var useGetCarousel = function (uuid, config) {
    var apiFetch = useApi().apiFetch;
    var _a = (config !== null && config !== void 0 ? config : {}).full, full = _a === void 0 ? false : _a;
    var url = "/carrusels/".concat(uuid, "/items")
        + "?full=".concat(full);
    var getCarousel = function () {
        return apiFetch({ url: url })
            .then(function (res) { return res.data; });
    };
    var options = {
        staleTime: 60000 * 30, // 30 min
        enabled: !!uuid,
    };
    return useQuery(['carousel', uuid, 'lang'], getCarousel, options);
};
export default useGetCarousel;
