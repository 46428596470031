var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled, { css } from 'styled-components';
export var Slide = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  position: relative;\n\n  img {\n    object-position: center;\n    width: 100%;\n    max-height: 100svh; // support for hero\n    object-fit: cover;\n    aspect-ratio: 16 / 9;\n\n    ", "\n  }\n"], ["\n  position: relative;\n\n  img {\n    object-position: center;\n    width: 100%;\n    max-height: 100svh; // support for hero\n    object-fit: cover;\n    aspect-ratio: 16 / 9;\n\n    ", "\n  }\n"])), function (_a) {
    var $isRounded = _a.$isRounded;
    return $isRounded && css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n      border-radius: 8px;\n    "], ["\n      border-radius: 8px;\n    "])));
});
var templateObject_1, templateObject_2;
