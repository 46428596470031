import styled from 'styled-components'

export const Container = styled.div`
  width: 100%;
  height: 100%;
  position: relative;
`

export const PlayerWrapper = styled.div`
  height: 100%;
  width: 100%;

  video {
    width: 100% !important;
    height: 100% !important;
    pointer-events: none !important;
  }
`
