import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { ReactComponent as ChevronSvg } from 'assets/icons/chevron-left.svg';
import { useOpen } from 'hooks';
import { Avatar, ModalCreatorProfile, Text } from 'ui';
import { getAvatarSrc } from 'utils/media';
var Author = function (props) {
    var data = props.data;
    var t = useTranslation().t;
    var modalCreatorProfile = useOpen();
    return (_jsxs(_Fragment, { children: [modalCreatorProfile.isOpen && (_jsx(ModalCreatorProfile, { close: modalCreatorProfile.close, nickname: data.nickname })), _jsxs("button", { onClick: modalCreatorProfile.open, style: {
                    display: 'flex',
                    alignItems: 'center',
                    gap: 12,
                    cursor: 'pointer',
                    backgroundColor: 'transparent',
                    border: 'none',
                    width: '100%',
                }, children: [_jsx("div", { style: {
                            height: 40,
                            width: 40,
                            borderRadius: 6,
                            overflow: 'hidden',
                        }, children: _jsx(Avatar, { src: getAvatarSrc(data === null || data === void 0 ? void 0 : data.Profile, 'h164'), style: { backgroundColor: 'transparent', border: 'none' } }) }), _jsxs("div", { style: {
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            flexGrow: 1,
                        }, children: [_jsx(Text, { children: t('common:creator') }), _jsx(Text, { weight: 'medium', color: 'headline', children: data.nickname })] }), _jsx(ChevronSvg, { height: 24, width: 24, style: {
                            transform: 'rotate(180deg)',
                            color: 'white',
                        } })] })] }));
};
export default Author;
