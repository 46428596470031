import Select from './Select';
import Text from './Text';
import Password from './Password';
import Code from './Code';
import Checkbox from './Checkbox';
var Input = {
    Select: Select,
    Text: Text,
    Password: Password,
    Code: Code,
    Checkbox: Checkbox,
};
export default Input;
