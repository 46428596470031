import React from 'react'
import styled from 'styled-components'

const DivStyled = styled.div`
  ${({ children, theme, ...props }) => props};
`

const Div = ({ children, ...props }) => (
  <DivStyled {...props}>
    {children}
  </DivStyled>
)

export default React.memo(Div)
