import i18n from 'i18n'

export const formatDuration = (secs) => {
  const parsedSecs = parseInt(secs)

  const minutes = Math.floor(parsedSecs / 60)
  const seconds = parsedSecs - (minutes * 60)

  if (minutes >= 1) {
    return `${minutes} ${i18n.t('common:min')}`
  }

  return `${seconds} ${i18n.t('common:sec')}`
}
