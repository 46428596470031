var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import Lottie from 'lottie-react';
import { userApi } from 'api';
import { ReactComponent as ChallengeEmptySvg } from 'assets/challenges_empty.svg';
import { ReactComponent as CloseSvg } from 'assets/icons/exit.svg';
import { ReactComponent as TrophySvg } from 'assets/images/flixx/trophy.svg';
import { useOpen, useResponsive } from 'hooks';
import { Modal } from 'templates';
import { Button, ModalPortal, Skeleton, Text } from 'ui';
import { GAHandler } from 'utils/GA';
import claimAnimation from 'assets/lotties/claim-challenge.json';
import Challenge from './Challenge';
var ScrollContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  overflow-y: scroll;\n  height: 100%;\n\n  &::-webkit-scrollbar {\n    width: 12px;\n    background-color: transparent;\n\n    ", " {\n      width: 0;\n    }\n  }\n\n  &::-webkit-scrollbar-track {\n    margin-bottom: 20px!important;\n    background-color: #373737;\n    border-radius: 15px;\n  }\n\n  &::-webkit-scrollbar-thumb {\n    background-color: #7f39f9;\n    width: 5px;\n    border-radius: 15px;\n  }\n"], ["\n  overflow-y: scroll;\n  height: 100%;\n\n  &::-webkit-scrollbar {\n    width: 12px;\n    background-color: transparent;\n\n    ", " {\n      width: 0;\n    }\n  }\n\n  &::-webkit-scrollbar-track {\n    margin-bottom: 20px!important;\n    background-color: #373737;\n    border-radius: 15px;\n  }\n\n  &::-webkit-scrollbar-thumb {\n    background-color: #7f39f9;\n    width: 5px;\n    border-radius: 15px;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.old.breakpoints.isMobile;
});
var ModalChallenges = function (props) {
    var close = props.close;
    var t = useTranslation().t;
    var isMobile = useResponsive().isMobile;
    var _a = userApi.useGetRewards(), rewards = _a.data, isLoading = _a.isLoading;
    var animationClaim = useOpen();
    var _b = useState('active'), filter = _b[0], setFilter = _b[1];
    var filterFn = filter === 'active'
        ? function (reward) { return reward.claimed.times === 0 || reward.isRepeatable; }
        : function (reward) { return reward.claimed.times !== 0 && !reward.isRepeatable; };
    var sortFn = function (rewardA, rewardB) { return rewardB.unclaimed.times - rewardA.unclaimed.times; };
    var rewardsList = filter === 'active'
        ? rewards === null || rewards === void 0 ? void 0 : rewards.filter(filterFn).toSorted(sortFn)
        : rewards === null || rewards === void 0 ? void 0 : rewards.filter(filterFn);
    useEffect(function () {
        GAHandler('challenges_presented');
    }, []);
    return (_jsx(ModalPortal, { children: _jsxs(Modal, { width: 640, containerStyle: {
                height: 'calc(100dvh - 110px)',
                maxHeight: isMobile ? 'unset' : 614,
            }, children: [animationClaim.isOpen && (_jsx(Lottie, { animationData: claimAnimation, loop: false, onComplete: animationClaim.close, style: {
                        position: 'fixed',
                        bottom: 0,
                        left: '50%',
                        transform: 'translate(-50%)',
                        height: '100%',
                        aspectRatio: 16 / 9,
                        maxWidth: 1000,
                        maxHeight: '70dvh',
                        zIndex: 1,
                    } })), _jsxs("div", { style: { padding: isMobile ? '30px 16px 0 30px' : '30px 12px 0 30px' }, children: [_jsxs("div", { style: { display: 'flex', alignItems: 'center', gap: 8 }, children: [_jsx(TrophySvg, { height: 30 }), _jsx(Text, { size: isMobile ? 24 : 30, weight: 600, color: 'headline', style: { flexGrow: 1 }, children: t('challenges:title') }), _jsx(Button, { iconLeft: CloseSvg, iconSize: 16, size: 'small', variant: 'secondary', onClick: close })] }), _jsx(Text, { style: { marginTop: 10 }, children: t('challenges:description') }), _jsxs("div", { style: { display: 'flex', gap: 28, marginTop: 30 }, children: [_jsx("button", { style: { cursor: 'pointer', backgroundColor: 'transparent', border: 'none' }, onClick: function () { setFilter('active'); }, children: _jsx(Text, { as: 'span', size: 20, weight: 600, color: filter === 'active' ? 'headline' : undefined, style: {
                                            textDecoration: filter === 'active' ? 'underline' : 'unset',
                                            textUnderlineOffset: 8,
                                        }, children: t('challenges:availables') }) }), _jsx("button", { style: { cursor: 'pointer', backgroundColor: 'transparent', border: 'none' }, onClick: function () { setFilter('completed'); }, children: _jsx(Text, { as: 'span', size: 20, weight: 600, color: filter === 'completed' ? 'headline' : undefined, style: {
                                            textDecoration: filter === 'completed' ? 'underline' : 'unset',
                                            textUnderlineOffset: 8,
                                        }, children: t('challenges:completed') }) })] })] }), _jsxs(ScrollContainer, { style: {
                        padding: isMobile ? '0 16px' : '0 16px 0 20px',
                        margin: isMobile ? '22px 0 0' : '22px 16px 0 0',
                    }, children: [isLoading && (_jsxs(_Fragment, { children: [_jsx(Skeleton, { style: { height: 120, marginBottom: 12 } }), _jsx(Skeleton, { style: { height: 120, marginBottom: 12 } }), _jsx(Skeleton, { style: { height: 120, marginBottom: 12 } })] })), !(rewardsList === null || rewardsList === void 0 ? void 0 : rewardsList.length) && !isLoading && (_jsx("div", { style: {
                                height: ' 80%',
                                flexGrow: 1,
                                display: 'grid',
                                placeItems: 'center',
                            }, children: _jsxs("div", { style: {
                                    display: 'flex',
                                    flexDirection: 'column',
                                    alignItems: 'center',
                                }, children: [_jsx(ChallengeEmptySvg, {}), _jsx(Text, { color: '#FBBF24', align: 'center', children: t('challenges:emptyMsg') })] }) })), _jsx("div", { children: rewardsList === null || rewardsList === void 0 ? void 0 : rewardsList.map(function (reward) {
                                var _a;
                                var isClaimed = !!reward.claimed.times && !reward.isRepeatable;
                                var isClaimable = !!reward.unclaimed.times;
                                var target = reward.progressDone;
                                var current = (_a = reward.actual.progressTotal) !== null && _a !== void 0 ? _a : reward.progressDone;
                                return (_jsx("div", { children: _jsx(Challenge, { uuid: reward.uuid, slug: reward.slug, title: reward.title, body: reward.body, thumb: reward.icon, target: target, current: current, pendingClaims: reward.unclaimed.times, isClaimable: isClaimable, isClaimed: !!reward.claimed.times && !reward.isRepeatable, isCompleted: !!reward.completed, isProgressive: reward.isProgressive, onClaimed: animationClaim.open, price: isClaimable
                                            ? reward.unclaimed.amount
                                            : isClaimed
                                                ? reward.claimed.amount
                                                : reward.actual.amount }) }, reward.uuid));
                            }) })] })] }) }));
};
export default ModalChallenges;
var templateObject_1;
