import { useQuery } from '@tanstack/react-query';
import { useApi } from 'hooks';
var useGetMessage = function (props) {
    var address = props.address, enabled = props.enabled;
    var apiFetch = useApi().apiFetch;
    var url = "/auth/externalWallets/".concat(address, "/message");
    var queryFn = function () {
        return apiFetch({ url: url })
            .then(function (res) { return res.data; });
    };
    return useQuery({
        queryKey: ['wallet', 'message'],
        queryFn: queryFn,
        enabled: !!enabled && !!address,
    });
};
export default useGetMessage;
