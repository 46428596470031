import React from 'react'
import { ThemeProvider as ProviderStyledComponents } from 'styled-components'

import theme from 'styles/theme'
import { useLocation } from 'hooks'

const ThemeProvider = ({ children }) => {
  const { isBeCreator, isCreators } = useLocation()

  const isLight = isBeCreator || isCreators
  const currentTheme = isLight ? theme.light : theme.dark

  return (
    <ProviderStyledComponents theme={currentTheme}>
      {children}
    </ProviderStyledComponents>
  )
}

export default ThemeProvider
