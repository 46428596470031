var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
export var GradientBottom = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: absolute;\n  width: 100%;\n  bottom: 0;\n  left: 0;\n  height: 50%;\n  pointer-events: none;\n  z-index: 1;\n\n  background: linear-gradient(\n    0deg,\n    #000000 0%,\n    rgba(0, 0, 0, .6) 50%,\n    rgba(131, 131, 131, 0) 100%\n  );\n\n  ", " {\n    height: 50%;\n  }\n\n  ", " {\n    height: 0%;\n  }\n"], ["\n  position: absolute;\n  width: 100%;\n  bottom: 0;\n  left: 0;\n  height: 50%;\n  pointer-events: none;\n  z-index: 1;\n\n  background: linear-gradient(\n    0deg,\n    #000000 0%,\n    rgba(0, 0, 0, .6) 50%,\n    rgba(131, 131, 131, 0) 100%\n  );\n\n  ", " {\n    height: 50%;\n  }\n\n  ", " {\n    height: 0%;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.old.breakpoints.isTab;
}, function (_a) {
    var theme = _a.theme;
    return theme.old.breakpoints.isMobile;
});
export var GradientTop = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  ", " {\n    position: absolute;\n    height: 25%;\n    width: 100%;\n    top: 0;\n    left: 0;\n    pointer-events: none;\n    background: linear-gradient(\n      180deg,\n      #000000 1%,\n      rgba(0, 0, 0, 0.7) 40%,\n      rgba(131, 131, 131, 0) 100%\n    );\n  }\n\n  ", " {\n    height: 0%;\n  }\n"], ["\n  ", " {\n    position: absolute;\n    height: 25%;\n    width: 100%;\n    top: 0;\n    left: 0;\n    pointer-events: none;\n    background: linear-gradient(\n      180deg,\n      #000000 1%,\n      rgba(0, 0, 0, 0.7) 40%,\n      rgba(131, 131, 131, 0) 100%\n    );\n  }\n\n  ", " {\n    height: 0%;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.old.breakpoints.isMobile;
}, function (_a) {
    var theme = _a.theme;
    return theme.old.breakpoints.isMobile;
});
var templateObject_1, templateObject_2;
