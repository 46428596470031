import { useQuery } from '@tanstack/react-query';
import { useApi } from 'hooks';
import i18n from 'i18n';
var HEROS = {
    es: 'cb83f9ad-b8e3-4776-b2ec-2107bc111069',
    en: '3d194d8a-4b3a-4d1f-84d5-534dc72ab4de',
};
export var MEDIA_TYPES = {
    cover: 1,
    thumbnail: 2,
    poster: 4,
};
var useGetHero = function () {
    var apiFetch = useApi().apiFetch;
    var queryFn = function () {
        var lang = i18n.language;
        var heroUuid = HEROS[lang];
        var url = "/carrusels/".concat(heroUuid, "/items");
        return apiFetch({ url: url })
            .then(function (res) { return res.data; });
    };
    return useQuery({
        queryKey: ['hero', 'lang'],
        queryFn: queryFn,
        staleTime: 60000 * 30, // 30 min
    });
};
export default useGetHero;
