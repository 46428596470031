import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useState } from 'react';
import { ReactComponent as CheckSVG } from 'assets/icons/check.svg';
var Checkbox = function (props) {
    var onChange = props.onChange, children = props.children;
    var _a = useState(false), isChecked = _a[0], setIsChecked = _a[1];
    var handleChange = function (e) {
        var checked = e.target.checked;
        onChange === null || onChange === void 0 ? void 0 : onChange(checked);
        setIsChecked(checked);
    };
    return (_jsxs("label", { style: {
            display: 'flex',
            alignItems: 'center',
            gap: 8,
            cursor: 'pointer',
            width: 'fit-content',
        }, children: [_jsx("div", { style: {
                    width: 16,
                    height: 16,
                    border: '2px solid #FFFFFF',
                    borderRadius: 4,
                    backgroundColor: isChecked ? 'white' : 'transparent',
                    color: isChecked ? '#18181B' : 'transparent',
                    display: 'grid',
                }, children: _jsx(CheckSVG, { height: 12, width: 12 }) }), _jsx("input", { type: 'checkbox', onChange: handleChange, hidden: true }), children] }));
};
export default Checkbox;
