import { useMutation } from '@tanstack/react-query';
import { useApi } from 'hooks';
var useGetPlayWithToken = function (uuid) {
    var apiFetch = useApi().apiFetch;
    var url = "/contents/".concat(uuid, "/play/tokens");
    var mutationFn = function (data) {
        return apiFetch({
            method: 'post',
            url: url,
            data: data,
        })
            .then(function (res) { return res.data; });
    };
    return useMutation({ mutationFn: mutationFn });
};
export default useGetPlayWithToken;
