var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { jsx as _jsx } from "react/jsx-runtime";
import React from 'react';
var COLORS = {
    primary: ['#AC32E4', '#7918F2', '#4801FF'],
    secondary: ['#FC75FF', '#FF4141', '#FF1493'],
};
var RadialGradientBg = function (props) {
    var _a = props.variant, variant = _a === void 0 ? 'primary' : _a, _b = props.blur, blur = _b === void 0 ? 100 : _b, style = __rest(props, ["variant", "blur"]);
    var styles = __assign({ position: 'absolute', display: 'grid', placeItems: 'center', width: 336, height: 305, opacity: 0.4 }, style);
    var colors = COLORS[variant];
    return (_jsx("div", { style: styles, children: _jsx("div", { style: {
                width: '100%',
                height: '100%',
                background: "linear-gradient(225deg, ".concat(colors[0], " 0%, ").concat(colors[1], " 47.87%, ").concat(colors[2], " 100%)"),
                backgroundSize: '100%',
                backgroundRepeat: 'no-repeat',
                filter: "blur(".concat(blur, "px)"),
                borderRadius: '50%',
                WebkitTransform: 'translateZ(0)',
                WebkitPerspective: 1000,
                WebkitBackfaceVisibility: 'hidden',
            } }) }));
};
export default React.memo(RadialGradientBg);
