import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { Anteroom, Pill, Text } from 'ui';
import { getContentTypeStr } from 'utils/contents';
export var SerieInfo = function (props) {
    var title = props.title, body = props.body, tag = props.tag, audioLang = props.audioLang, countSeasons = props.countSeasons, releaseYear = props.releaseYear, subtitles = props.subtitles, likes = props.likes;
    var t = useTranslation().t;
    return (_jsxs(_Fragment, { children: [_jsx(Anteroom.Title, { isLoading: !title, children: title }), _jsxs("div", { style: {
                    display: 'flex',
                    alignItems: 'center',
                    gap: 8,
                    marginTop: 8,
                }, children: [!!tag && (_jsx(Pill, { value: getContentTypeStr(2, 1) })), likes >= 1 && (_jsxs(Text, { size: 'sm', weight: 'medium', color: '#FBBF24', children: ["\u2665\uFE0E ", likes] }))] }), _jsxs(Anteroom.Divider.Root, { isLoading: !tag, children: [_jsx(Anteroom.Divider.Child, { children: releaseYear }), _jsxs(Anteroom.Divider.Child, { children: [countSeasons, " ", t('common:seasons', { count: countSeasons })] }), _jsx(Anteroom.Divider.Child, { children: tag })] }), _jsx(Anteroom.Body, { isLoading: !body, children: body }), _jsxs(Anteroom.Divider.Root, { isLoading: !audioLang, children: [_jsxs(Anteroom.Divider.Child, { children: [t('audio'), " (", audioLang, ")"] }), subtitles.length && (_jsxs(Anteroom.Divider.Child, { children: [t('common:subtitles'), " (", subtitles.join(', '), ")"] }))] })] }));
};
