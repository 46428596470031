var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import Lottie from 'lottie-react';
import { lazy, Suspense, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { contentApi, userApi } from 'api';
import { useBuyContent, useGetContent, } from 'api/content';
import loaderJson from 'assets/lotties/player-loader.json';
import { useExternalWallet, useIsPurchased, useLocation, useOpen, useResponsive, useSession, } from 'hooks';
import useCarouselSlides from 'hooks/useCarouselSlides';
import { Anteroom, CardContentSuggestion, CarouselBase, CarouselButtons, ErrorCard, PlayerAdManager, Seo, Text, } from 'ui';
import { getContentTypeStr, getContentUrl } from 'utils/contents';
import { formatDuration } from 'utils/duration';
import { GAHandler } from 'utils/GA';
import { getCover, getMedia } from 'utils/thumbnails';
import Info from './Info';
var PlayerLanding = lazy(function () { return import('ui/PlayerLanding'); });
var AnteroomShorts = function () {
    var _a;
    var contentUUID = useParams().contentUUID;
    var state = useLocation().state;
    var navigate = useNavigate();
    var t = useTranslation().t;
    var _b = useResponsive(), isMobile = _b.isMobile, isMobileBig = _b.isMobileBig, isTab = _b.isTab;
    var isLoggedIn = useSession().isLoggedIn;
    var isConnected = useExternalWallet().isConnected;
    var balance = userApi.useGetBalance().data;
    var me = userApi.useGetMe().data;
    var _c = useGetContent({ uuid: contentUUID, contentType: 'contents' }), short = _c.data, shortError = _c.error;
    var _d = contentApi.useGetSimilars({
        uuid: contentUUID,
        purchaseMethods: isConnected ? 3 : 1,
    }), data = _d.data, isLoadingSimilars = _d.isLoading;
    var similars = (_a = data === null || data === void 0 ? void 0 : data.contents) !== null && _a !== void 0 ? _a : [];
    var _e = useState(null), playDataTokenGating = _e[0], setPlayDataTokenGating = _e[1];
    var _f = useBuyContent(), buyContent = _f.buyContent, isBuying = _f.isLoading, isErrorBuying = _f.isError, isBought = _f.isSuccess, resetBuyContent = _f.reset;
    var similarsSlidesPerView = useCarouselSlides({
        config: {
            0: 1,
            420: 1.2,
            520: 1.5,
            700: 2,
            1024: 3,
            1400: 4,
            1750: 5,
            2200: 6,
        },
    });
    var contentPlayer = useOpen();
    var adPlayer = useOpen();
    var _g = state !== null && state !== void 0 ? state : {}, autoplay = _g.autoplay, forceClose = _g.forceClose;
    var shortTitle = (state === null || state === void 0 ? void 0 : state.title) || (short === null || short === void 0 ? void 0 : short.title);
    var shortBody = (state === null || state === void 0 ? void 0 : state.body) || (short === null || short === void 0 ? void 0 : short.body);
    var shortCover = (state === null || state === void 0 ? void 0 : state.cover) || getMedia({ media: short === null || short === void 0 ? void 0 : short.media });
    var shortAuthor = (state === null || state === void 0 ? void 0 : state.author) || (short === null || short === void 0 ? void 0 : short.author);
    var titlePlayer = short === null || short === void 0 ? void 0 : short.title;
    // const {
    //   isPurchased,
    // } = short ?? {}
    var _h = useIsPurchased(), isPurchasedFn = _h.isPurchased, isPurchasesLoaded = _h.isPurchasesLoaded;
    var isPurchased = isPurchasedFn(contentUUID);
    var handlePlayContentTG = function (data) {
        setPlayDataTokenGating(data);
        contentPlayer.open();
    };
    var handleBuyContent = function () {
        buyContent({
            contentUuid: short.uuid,
            price: short.price,
        });
        contentPlayer.open();
    };
    var handleCloseEpisode = function () {
        contentPlayer.close();
    };
    var endAd = function (newBalance) {
        var canPurchaseIt = parseFloat(short.price) <= newBalance;
        if (canPurchaseIt) {
            handleBuyContent();
        }
        else {
            GAHandler('reward_insufficient_to_buy', {
                uuid: short.uuid,
                contentType: 'shorts',
            });
        }
        adPlayer.close();
    };
    var handleWatchNow = function () {
        resetBuyContent();
        if (isPurchased) {
            contentPlayer.open();
            return;
        }
        var canPurchaseIt = (!(me === null || me === void 0 ? void 0 : me.features.flixx)
            || parseFloat(short.price) <= parseFloat(balance));
        if (canPurchaseIt) {
            handleBuyContent();
            GAHandler('ui_watch_buy');
        }
        else {
            adPlayer.open();
        }
    };
    useEffect(function () {
        if (forceClose && contentPlayer.isOpen) {
            contentPlayer.close();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [forceClose]);
    /**
     * Closes the player when autoplay is true
     * and user go from short to short
     */
    useEffect(function () {
        if (autoplay && contentPlayer.isOpen) {
            contentPlayer.close();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [contentUUID]);
    useEffect(function () {
        if (!autoplay || !short)
            return;
        navigate(location.pathname, { state: { autoplay: false, forceClose: false }, replace: true });
        handleWatchNow();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [short]);
    if ((shortError === null || shortError === void 0 ? void 0 : shortError.code) === 404) {
        return (_jsx("div", { style: { height: '80vh', display: 'grid' }, children: _jsx(ErrorCard, { title: 'Ups!', description: t('contentNotFound'), code: 'W3001' }) }));
    }
    if (shortError) {
        return (_jsx("div", { style: { height: '80vh', display: 'grid' }, children: _jsx(ErrorCard, { code: 'W3002', title: t('anteroom:error.retrieving.title'), isBackHome: true }) }));
    }
    var abValue = localStorage.getItem('abValue');
    var isRewarded = Number(abValue) >= 50;
    if (autoplay && !isRewarded) {
        return (_jsx("div", { style: {
                position: 'fixed',
                top: 0,
                left: 0,
                right: 0,
                bottom: 0,
                zIndex: 999999999999999,
                backgroundColor: 'black',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                pointerEvents: 'none',
            }, children: _jsx(Lottie, { animationData: loaderJson, loop: true, style: { width: isMobileBig ? 50 : 100 } }) }));
    }
    // This should be part of Anteroom
    if (contentPlayer.isOpen && short && !isErrorBuying) {
        return (_jsx(Suspense, { fallback: null, children: _jsx(PlayerLanding, { title: titlePlayer, author: shortAuthor === null || shortAuthor === void 0 ? void 0 : shortAuthor.nickname, playData: playDataTokenGating, uuid: short.uuid, video: short, creator: shortAuthor.nickname, showCreatorPayment: isBought && (me === null || me === void 0 ? void 0 : me.features.flixx), onClose: handleCloseEpisode, userLiked: short.userLiked, suggestions: similars, isLoading: isBuying }, contentUUID) }));
    }
    return (_jsxs(Anteroom.Root, { children: [_jsx(Seo, { title: shortTitle, description: shortBody }), _jsx(PlayerAdManager, { isOpen: adPlayer.isOpen, close: adPlayer.close, onReward: endAd }), _jsxs(Anteroom.Wrapper, { children: [_jsx(Anteroom.Cover, { src: shortCover, alt: shortTitle }), _jsx(Anteroom.Info, { children: _jsx(Info, { short: short, title: shortTitle, body: shortBody, likes: short === null || short === void 0 ? void 0 : short.likes }) }), _jsx(Anteroom.Actions, { uuid: short === null || short === void 0 ? void 0 : short.uuid, author: shortAuthor, shareText: t('common:share'), onWatchNow: handleWatchNow, onWatchNowTG: handlePlayContentTG, showPrice: isLoggedIn && !isPurchased, watchWithAd: Number(balance) < (short === null || short === void 0 ? void 0 : short.price), contentPrice: short === null || short === void 0 ? void 0 : short.price, watchNowDisabled: !short, purchaseMethods: short === null || short === void 0 ? void 0 : short.purchaseMethods, tokenGates: short === null || short === void 0 ? void 0 : short.tokenGates, hasDRM: short === null || short === void 0 ? void 0 : short.hasDRM, isPurchased: isPurchased })] }), (isLoadingSimilars || !!(similars === null || similars === void 0 ? void 0 : similars.length)) && (_jsxs(_Fragment, { children: [_jsx(Anteroom.Carousel.Title, { children: _jsx(Text, { as: 'h2', color: 'headline', size: isMobile ? 'sm' : 'base', weight: 'medium', children: t('anteroom:similar') }) }), _jsx(CarouselBase, { slidesPerView: similarsSlidesPerView, offsetRight: isTab ? 16 : 40, offsetLeft: isMobile ? 26 : 45, spaceBetween: isMobile ? 14 : 16, rewind: true, renderNavigationBtns: function (props) { return _jsx(CarouselButtons, __assign({}, props, { isAbsolute: true })); }, children: similars.map(function (content) {
                            var to = getContentUrl(content.contentType, content.uuid);
                            var cover = getCover({ media: content.media });
                            var category = content.category.name;
                            var duration = content.contentType === 2
                                ? "".concat(content.numSeasons, " ") + t('common:seasons', { count: Number(content.numSeasons) })
                                : formatDuration(content.duration);
                            var withToken = content.purchaseMethods && content.purchaseMethods !== 1;
                            return (_jsx(CardContentSuggestion, { cover: cover, to: to, duration: duration, category: category, pill: getContentTypeStr(content.contentType, 1), body: content.body, withToken: withToken }, content.uuid));
                        }) })] }))] }));
};
export default AnteroomShorts;
