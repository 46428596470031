import { baseStyles } from '../styles'

export const primary = ({ theme, size, hasIcon }) => {
  const base = baseStyles(theme, size, hasIcon)

  return {
    ...base,

    control: (provided, state) => {
      const { menuIsOpen } = state

      return ({
        ...base.control(provided, state),
        backgroundColor: menuIsOpen
          ? '#3F3F46'
          : theme.input.main.default.background,
        borderRadius: 8,
        border: theme.input.main.default.border,

        ':hover': {
          border: theme.input.main.default.border,
        },
      })
    },

    dropdownIndicator: (provided, state) => ({
      ...base.dropdownIndicator(provided, state),
      color: theme.old.colors.gray[450],

      ':hover': {
        color: theme.old.colors.gray[450],
      },
    }),

    singleValue: (provided, state) => ({
      ...base.singleValue(provided, state),
      fontSize: theme.old.fonts.size.small,
      fontWeight: theme.old.fonts.weight.regular,
      color: theme.old.colors.white,
    }),

    menu: (provided, state) => ({
      ...base.menu(provided, state),
      backgroundColor: theme.old.colors.gray[175],
      color: theme.old.colors.white,
      border: '1px solid #3F3F46',
    }),

    option: (provided, state) => {
      // const { isSelected } = state

      const color = state.isSelected
        ? theme.colors.basics.white
        : '#A1A1AA'

      return {
        ...base.option(provided, state),
        fontSize: theme.old.fonts.size.small,
        fontWeight: theme.old.fonts.weight.regular,
        color,
        backgroundColor: 'transparent',
        height: 36,
        marginBottom: 0,

        ':hover': {
          backgroundColor: '#3F3F46',
          color: 'white',
        },
      }
    },

    valueContainer: (provided, state) => {
      return {
        ...base.valueContainer(provided, state),
      }
    },

    input: (provided, state) => {
      return {
        ...provided,
        fontSize: theme.old.fonts.size.small,
        fontWeight: theme.old.fonts.weight.regular,
        color: theme.old.colors.white,
      }
    },
  }
}
