import styled from 'styled-components'

import ArrowLeftSVG from 'assets/iconsOld/arrowLeft.svg'
import ArrowRightSVG from 'assets/iconsOld/arrowRight.svg'

const NavigationBtn = styled.button`
  width: 35px;
  height: 100%;

  position: absolute;
  top: 0;
  bottom: 0;

  border: none;
  background-color: transparent;
  


  transition: 200ms;
  cursor: pointer;  
  background-color: rgba(0,0,0, .65);

  &::before {
    content: '';
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;

    background-image: none;
    background-size: 10px;
    background-repeat: no-repeat;
    background-position: center;
    opacity: .5;
  }

  &:hover {
    background-color: rgba(0,0,0, .85);

    &::before {
      opacity: 1;
    }
  }

  z-index: 2;
`

export const ButtonPrev = styled(NavigationBtn)`
  right: ${({ $isAbsolute }) => $isAbsolute ? 'unset' : '100%'};
  left: ${({ $isAbsolute }) => $isAbsolute ? 0 : 'unset'};

  &::before {
    background-image: url(${ArrowLeftSVG});
  }
`

export const ButtonNext = styled(NavigationBtn)`
  right: ${({ $isAbsolute }) => $isAbsolute ? 0 : 'unset'};
  left: ${({ $isAbsolute }) => $isAbsolute ? 'unset' : '100%'};

  &::before {
    background-image: url(${ArrowRightSVG});
  }
`
