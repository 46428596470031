import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useShowCarouselButtons } from 'hooks';
import { ArrowBack, ArrowNext } from './styles';
var Buttons = function (props) {
    var nextSlide = props.nextSlide, prevSlide = props.prevSlide;
    var showNavigationBtns = useShowCarouselButtons().showNavigationBtns;
    if (!showNavigationBtns)
        return null;
    return (_jsxs(_Fragment, { children: [_jsx(ArrowBack, { onClick: prevSlide }), _jsx(ArrowNext, { onClick: nextSlide })] }));
};
export default Buttons;
