export var ENVS = {
    development: {
        isDevelopment: true,
        isStaging: false,
        isProduction: false,
        name: 'development',
        apiUrl: 'https://api.dev.flixxo.com',
        GA4_ID: 'G-WN94TPDQSZ',
        REDIRECT_URI: 'https://play.dev.flixxo.com/redirect',
        FACEBOOK_ID: '534304860310580',
        APPLE_ID: 'com.flixxo.play',
        GOOGLE_ID: '379727492656-ehkf81r8cmuundvjm6uvniararu3p8s3.apps.googleusercontent.com',
        AD_MANAGER_TAG_REWARED: '/22981279934/TEST_REWARDED',
        AD_MANAGER_TAG_INSTREAM: 'https://pubads.g.doubleclick.net/gampad/ads?iu=/22981279934/test_dev_instream&sz=640x480&description_url=https%3A%2F%2Fplay.flixxo.com&tfcd=0&npa=0&min_ad_duration=5000&max_ad_duration=45000&gdfp_req=1&unviewed_position_start=1&output=vast&env=vp&impl=s&correlator=',
    },
    staging: {
        isDevelopment: false,
        isStaging: true,
        isProduction: false,
        name: 'staging',
        apiUrl: 'https://api.stg.flixxo.com',
        GA4_ID: 'G-66NMQEQS0L',
        REDIRECT_URI: 'https://play.stg.flixxo.com/redirect',
        FACEBOOK_ID: '534304860310580',
        APPLE_ID: 'com.flixxo.play',
        GOOGLE_ID: '379727492656-ehkf81r8cmuundvjm6uvniararu3p8s3.apps.googleusercontent.com',
        AD_MANAGER_TAG_REWARED: '/22981279934/test_stg_rewarded',
        AD_MANAGER_TAG_INSTREAM: 'https://pubads.g.doubleclick.net/gampad/ads?iu=/22981279934/test_stg_instream&sz=640x480&description_url=https%3A%2F%2Fplay.flixxo.com&tfcd=0&npa=0&min_ad_duration=5000&max_ad_duration=45000&gdfp_req=1&unviewed_position_start=1&output=vast&env=vp&impl=s&correlator=',
    },
    production: {
        isDevelopment: false,
        isStaging: false,
        isProduction: true,
        name: 'production',
        apiUrl: 'https://api.pro.flixxo.com',
        GA4_ID: 'G-CEWJKGYNVB',
        GTM_ID: 'GTM-W2ZG7KX',
        REDIRECT_URI: "".concat(location.origin, "/redirect"),
        FACEBOOK_ID: '534304860310580',
        APPLE_ID: 'com.flixxo.play',
        GOOGLE_ID: '379727492656-hafai1c1nomlve5feeitk8ld7uqnp9n1.apps.googleusercontent.com',
        AD_MANAGER_TAG_REWARED: '/22981279934/Rewarded',
        AD_MANAGER_TAG_INSTREAM: 'https://pubads.g.doubleclick.net/gampad/ads?iu=/22981279934/instream&sz=640x480&description_url=https%3A%2F%2Fplay.flixxo.com&tfcd=0&npa=0&min_ad_duration=5000&max_ad_duration=45000&gdfp_req=1&unviewed_position_start=1&output=vast&env=vp&impl=s&correlator=',
    },
};
export var environment = ENVS[process.env.REACT_APP_ENV];
