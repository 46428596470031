import { jsx as _jsx } from "react/jsx-runtime";
import { Text } from 'ui';
import * as variants from './variants';
var Pill = function (props) {
    var value = props.value, _a = props.variant, variant = _a === void 0 ? 'primary' : _a, _b = props.size, size = _b === void 0 ? 'md' : _b;
    var Variant = variants[variant];
    var isSmall = size === 'sm';
    return (_jsx(Variant, { children: _jsx(Text, { as: 'span', size: isSmall ? 10 : 12, weight: 'semibold', color: 'headline', style: { lineHeight: 'unset', whiteSpace: 'nowrap' }, children: value.toUpperCase() }) }));
};
export default Pill;
