var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { useLayoutEffect, useRef, useState } from 'react';
import { environment } from 'config/environment';
import { GAHandler } from 'utils/GA';
var useAdManager = function () {
    var videoRef = useRef();
    var adDisplayContainer = useRef();
    var adsLoader = useRef();
    var adsRequest = useRef();
    var adsManagerRef = useRef();
    var adContainerRef = useRef();
    var videoWrapperId = 'wrapper';
    var _a = useState(false), isEnded = _a[0], setIsEnded = _a[1];
    var _b = useState(false), isSkippable = _b[0], setIsSkippable = _b[1];
    var _c = useState(0), duration = _c[0], setDuration = _c[1];
    var _d = useState(0), played = _d[0], setPlayed = _d[1];
    var _e = useState(), error = _e[0], setError = _e[1];
    var onAdError = function (ev) {
        if (!environment.isProduction) {
            console.log('---- ERROR -----');
            console.log(ev);
            console.log('---- ----- -----');
        }
        setError(ev.error);
    };
    var playAd = function () {
        adsManagerRef.current.resume();
    };
    var skipAd = function () {
        adsManagerRef.current.skip();
    };
    function onAdsManagerLoaded(adsManagerLoadedEvent) {
        var videoEl = document.getElementById(videoWrapperId);
        var google = window.google;
        // Crear un AdsManager y inicializar la reproducción del anuncio
        // const adsRenderingSettings = new window.google.ima.AdsRenderingSettings()
        // adsRenderingSettings.restoreCustomPlaybackStateOnAdBreakComplete = true
        // const adsManager = adsManagerLoadedEvent.getAdsManager(videoContent, adsRenderingSettings)
        var adsRenderingSettings = new google.ima.AdsRenderingSettings();
        var adsManager = adsManagerLoadedEvent.getAdsManager(videoEl, adsRenderingSettings);
        adsManagerRef.current = adsManager;
        adsManager.addEventListener(google.ima.AdEvent.Type.CLICK, onClick);
        adsManager.addEventListener(google.ima.AdEvent.Type.VIDEO_CLICKED, onVideoClicked);
        adsManager.addEventListener(google.ima.AdEvent.Type.CONTENT_PAUSE_REQUESTED, onContentPauseRequested);
        adsManager.addEventListener(google.ima.AdEvent.Type.CONTENT_RESUME_REQUESTED, onContentResumeRequested);
        adsManager.addEventListener(google.ima.AdEvent.Type.STARTED, function () {
            GAHandler('ad_presented', { type: 'in-stream' });
            if (!environment.isProduction) {
                console.log('AD MANAGER: STARTED');
            }
        });
        adsManager.addEventListener(google.ima.AdEvent.Type.AD_PROGRESS, function (a) {
            var data = a.getAdData();
            setDuration(data.duration);
            setPlayed(data.currentTime);
        });
        adsManager.addEventListener(google.ima.AdEvent.Type.IMPRESSION, function () {
            if (!environment.isProduction) {
                console.log('AD MANAGER: IMPRESSION');
            }
        });
        adsManager.addEventListener(google.ima.AdEvent.Type.USER_CLOSE, function () {
            if (!environment.isProduction) {
                console.log('AD MANAGER: USER_CLOSE');
            }
        });
        adsManager.addEventListener(google.ima.AdEvent.Type.ALL_ADS_COMPLETED, function () {
            GAHandler('ad_finished');
            if (!environment.isProduction) {
                console.log('AD MANAGER: ALL_ADS_COMPLETED');
            }
        });
        adsManager.addEventListener(google.ima.AdEvent.Type.SKIPPED, function () {
            if (!environment.isProduction) {
                console.log('AD MANAGER: SKIPPED');
            }
        });
        adsManager.addEventListener(google.ima.AdEvent.Type.SKIPPABLE_STATE_CHANGED, function (e) {
            if (!environment.isProduction) {
                console.log('AD MANAGER: SKIPPABLE_STATE_CHANGED');
            }
            setIsSkippable(true);
        });
        adsManager.addEventListener(google.ima.AdEvent.Type.LOG, function () {
            if (!environment.isProduction) {
                console.log('AD MANAGER: LOG');
            }
        });
        adsManager.addEventListener(google.ima.AdEvent.Type.INTERACTION, function () {
            if (!environment.isProduction) {
                console.log('AD MANAGER: INTERACTION');
            }
        });
        var h = videoEl.clientHeight;
        var w = videoEl.clientWidth;
        adsManager.init(w, h, window.google.ima.ViewMode.NORMAL);
        // Iniciar la reproducción del anuncio
        adsManager.start();
    }
    function onContentPauseRequested() {
        if (!environment.isProduction) {
            console.log('AD MANAGER: PAUSED');
        }
    }
    function onContentResumeRequested() {
        if (!environment.isProduction) {
            console.log('AD MANAGER: END');
        }
        setIsEnded(true);
    }
    function onVideoClicked() {
        if (!environment.isProduction) {
            console.log('AD MANAGER: VIDEO CLICKED');
        }
    }
    function onClick(e) {
        if (!environment.isProduction) {
            console.log('AD MANAGER: ONCLICK');
        }
        var isClickable = !!e.ad.data.clickThroughUrl;
        // avoid stoping ad on click
        if (!isClickable) {
            adsManagerRef.current.resume();
        }
    }
    var startAd = function () { return __awaiter(void 0, void 0, void 0, function () {
        var videoEl, google;
        return __generator(this, function (_a) {
            videoEl = document.getElementById(videoWrapperId);
            google = window.google;
            if (!google.ima) {
                if (!environment.isProduction) {
                    console.log('---- ERROR START -----');
                }
                setError(true);
                return [2 /*return*/];
            }
            adDisplayContainer.current = new google.ima.AdDisplayContainer(
            // adContainer,
            videoEl);
            adsLoader.current = new google.ima.AdsLoader(adDisplayContainer.current);
            adsRequest.current = new google.ima.AdsRequest();
            // adsRequest.current.adTagUrl = 'https://pubads.g.doubleclick.net/gampad/ads?iu=/21775744923/external/single_preroll_skippable&sz=640x480&ciu_szs=300x250%2C728x90&gdfp_req=1&output=vast&unviewed_position_start=1&env=vp&impl=s&correlator='
            adsRequest.current.adTagUrl = environment.AD_MANAGER_TAG_INSTREAM;
            adsLoader.current.addEventListener(google.ima.AdsManagerLoadedEvent.Type.ADS_MANAGER_LOADED, onAdsManagerLoaded, false);
            adsLoader.current.addEventListener(google.ima.AdErrorEvent.Type.AD_ERROR, onAdError, false);
            adDisplayContainer.current.initialize();
            adsLoader.current.requestAds(adsRequest.current);
            return [2 /*return*/];
        });
    }); };
    useLayoutEffect(function () {
        var handleVisibility = function () {
            if (document.hidden) {
                adsManagerRef.current.pause();
            }
            else {
                adsManagerRef.current.resume();
            }
        };
        var handleResize = function () {
            var google = window.google;
            var adsManager = adsManagerRef.current;
            var videoEl = document.getElementById(videoWrapperId);
            if (adsManager) {
                var width = videoEl === null || videoEl === void 0 ? void 0 : videoEl.clientWidth;
                var height = videoEl === null || videoEl === void 0 ? void 0 : videoEl.clientHeight;
                adsManager.resize(width, height, google.ima.ViewMode.NORMAL);
            }
        };
        window.addEventListener('visibilitychange', handleVisibility);
        window.addEventListener('resize', handleResize);
        return function () {
            var _a;
            (_a = adsManagerRef.current) === null || _a === void 0 ? void 0 : _a.destroy();
            window.removeEventListener('resize', handleResize);
            window.removeEventListener('visibilitychange', handleVisibility);
        };
    }, []);
    return {
        startAd: startAd,
        playAd: playAd,
        skipAd: skipAd,
        error: error,
        duration: duration,
        played: played,
        isSkippable: isSkippable,
        isEnded: isEnded,
        videoRef: videoRef,
        adContainerRef: adContainerRef,
        videoWrapperId: videoWrapperId,
    };
};
export default useAdManager;
