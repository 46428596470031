import i18n from 'i18next';
import langDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import es from './translations/es';
import en from './translations/en';
var normalizeLang = function (lang) {
    var l = lang.split('-')[0];
    var current = ['en', 'es'].includes(l)
        ? l
        : 'en';
    return current;
};
i18n
    .use(langDetector)
    .use(initReactI18next)
    .init({
    detection: {
        lookupLocalStorage: 'lang',
        order: ['localStorage', 'path', 'navigator'],
        caches: [''],
        convertDetectedLanguage: normalizeLang,
    },
    resources: { es: es, en: en },
    fallbackLng: 'en',
    debug: false,
    defaultNS: 'old',
    interpolation: {
        escapeValue: false,
    },
});
export default i18n;
