import { jsx as _jsx } from "react/jsx-runtime";
import { toast } from 'react-toastify';
import { useTranslation } from 'react-i18next';
import { Toast } from 'ui';
import { ReactComponent as CheckSVG } from 'assets/icons/check.svg';
import { ReactComponent as ErrorSVG } from 'assets/icons/error-shield.svg';
var ICONS = {
    success: CheckSVG,
    error: ErrorSVG,
};
var COLORS = {
    error: '#FFE4E6',
};
var useToast = function () {
    var t = useTranslation().t;
    var newToast = function (msg, options) {
        var _a = options !== null && options !== void 0 ? options : {}, _b = _a.theme, theme = _b === void 0 ? 'light' : _b, _c = _a.icon, icon = _c === void 0 ? 'success' : _c, _d = _a.messageBtn, messageBtn = _d === void 0 ? t('common:ok') : _d, onClick = _a.onClick;
        var currentIcon = Object.keys(ICONS).includes(icon)
            ? ICONS[icon]
            : icon;
        var currentColor = Object.keys(COLORS).includes(icon)
            ? COLORS[icon]
            : undefined;
        toast(_jsx(Toast.Message, { message: msg, messageBtn: messageBtn, icon: _jsx(Toast.Icon, { icon: currentIcon, bgColor: currentColor }), onClick: onClick }), { theme: theme });
    };
    return { toast: newToast };
};
export default useToast;
