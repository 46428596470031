export function parseTime (duration) {
  const hrs = ~~(duration / 3600)
  const mins = ~~((duration % 3600) / 60)
  const secs = ~~duration % 60
  let ret = ''

  if (hrs > 0) {
    ret += '' + hrs + ':' + (mins < 10 ? '0' : '')
  }

  ret += '' + mins + ':' + (secs < 10 ? '0' : '')
  ret += '' + secs
  return ret
}

export const capitalize = (text, forceLower = true) => {
  if (typeof text !== 'string') return ''

  const firstLetterUpper = text.charAt(0).toUpperCase()
  const restWord = forceLower
    ? text.slice(1).toLowerCase()
    : text.slice(1)

  return firstLetterUpper + restWord
}

export const capitalizeAll = (text) => {
  if (!text || typeof text !== 'string') return ''

  const words = text.split(' ')

  const capitalized = words.map((word) => {
    const trimmed = word.trim()
    const firstLetterUpper = trimmed.charAt(0).toUpperCase()
    const restWord = trimmed.slice(1)
    return firstLetterUpper + restWord
  })

  return capitalized.join(' ')
}

export const getFlixxInfo = async () => {
  try {
    const url = 'https://pro-api.coinmarketcap.com/v1/cryptocurrency/quotes/latest?symbol=FLIXX'
    const params = {
      headers: {
        'X-CMC_PRO_API_KEY': '1a88f352-348e-462d-a8c3-20502395f0d5',
      },
    }
    const response = await fetch(url, params)
    const result = await response.json()
    return result.data.FLIXX.quote.USD
  } catch (error) {
    console.log(error)
    return null
  }
}

export const dateFormat = fullDate => {
  const rawDay = new Date(fullDate).getDate()
  const rawMonth = new Date(fullDate).getMonth() + 1
  const year = new Date(fullDate).getFullYear()

  const day = rawDay < 10 ? `0${rawDay}` : rawDay
  const month = rawMonth < 10 ? `0${rawMonth}` : rawMonth

  return `${day}/${month}/${year}`
}
