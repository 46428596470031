import { userApi } from 'api';
var useIsFollowing = function () {
    var _a = userApi.useGetFollowings(), followings = _a.data, isFetching = _a.isFetching;
    var isFollowing = function (uuid) {
        var user = followings === null || followings === void 0 ? void 0 : followings.find(function (user) { return user.uuid === uuid; });
        return !!user;
    };
    return {
        isFollowing: isFollowing,
        isFetchingFolloweds: isFetching,
    };
};
export default useIsFollowing;
