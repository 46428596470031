import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { t } from 'i18next';
import { Modal } from 'templates';
import { Button, ModalPortal, RadialGradientBg, Text } from 'ui';
import { ReactComponent as AdBlockSvg } from './adblock.svg';
var Bg = function () { return (_jsx("div", { style: {
        height: '100%',
        width: '100%',
        position: 'absolute',
        top: 0,
        left: 0,
        borderRadius: 'inherit',
        overflow: 'hidden',
    }, children: _jsx(RadialGradientBg, { top: 0, left: '50%', transform: 'translate(-50%)' }) })); };
var ModalAdBlock = function (props) {
    var close = props.close;
    return (_jsx(ModalPortal, { children: _jsx("div", { style: {
                backgroundColor: 'rgba(0,0,0, .1)',
                position: 'fixed',
                top: 0,
                left: 0,
                width: '100%',
                height: '100%',
                zIndex: 9999,
            }, children: _jsxs(Modal, { padding: '30px 0 35px 0', width: 400, renderBg: Bg, containerStyle: {
                    alignItems: 'center',
                    overflow: 'hidden',
                    width: '90%',
                    maxWidth: 316,
                    margin: '0 auto',
                }, children: [_jsx(AdBlockSvg, {}), _jsx(Text, { size: 20, color: '#FBBF24', align: 'center', lineHeight: 28, weight: 600, children: t('adblock:title') }), _jsx(Text, { size: 14, color: 'headline', align: 'center', lineHeight: 20, style: { marginTop: 4 }, children: t('adblock:description') }), _jsx(Button, { onClick: close, style: { marginTop: 32 }, children: t('common:ok') })] }) }) }));
};
export default ModalAdBlock;
