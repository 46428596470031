
import { useQuery } from '@tanstack/react-query'

import { useApi, useSession } from 'hooks'

const useGetNextReward = (props = {}) => {
  const { enabled = true } = props
  const { apiFetch } = useApi()

  const { isLoggedIn } = useSession()

  const url = '/advertisements/reward'

  const queryFn = () => {
    return apiFetch({ url })
      .then(res => res.data)
  }


  return useQuery({
    queryKey: ['nextReward'],
    queryFn,
    refetchOnWindowFocus: false,
    staleTime: 3000,
    enabled: isLoggedIn && enabled,
  })
}

export default useGetNextReward
