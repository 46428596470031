import { ReactComponent as Cry } from 'assets/moods/cry.svg';
import { ReactComponent as Think } from 'assets/moods/think.svg';
import { ReactComponent as Imagine } from 'assets/moods/imagine.svg';
import { ReactComponent as Scream } from 'assets/moods/scream.svg';
import { ReactComponent as Love } from 'assets/moods/love.svg';
import { ReactComponent as Laugh } from 'assets/moods/laugh.svg';
export var MOODS = [
    {
        value: 'cry',
        tag: 'mood-llorar',
        getLabel: function (t) { return t('search:moods.cry'); },
        component: Cry,
        category: 'drama',
    }, {
        value: 'love',
        tag: 'mood-enamorarse',
        getLabel: function (t) { return t('search:moods.love'); },
        component: Love,
        category: 'romance',
    }, {
        value: 'laugh',
        tag: 'mood-reir',
        getLabel: function (t) { return t('search:moods.laugh'); },
        component: Laugh,
        category: 'comedy',
    }, {
        value: 'imagine',
        tag: 'mood-imaginar',
        getLabel: function (t) { return t('search:moods.imagine'); },
        component: Imagine,
        category: 'animation',
    }, {
        value: 'scream',
        tag: 'mood-gritar',
        getLabel: function (t) { return t('search:moods.shout'); },
        component: Scream,
        category: 'horror',
    }, {
        value: 'think',
        tag: 'mood-pensar',
        getLabel: function (t) { return t('search:moods.think'); },
        component: Think,
        category: 'documentary',
    },
];
