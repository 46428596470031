export const STATE_ENABLED = 0x01
export const STATE_CONFIRMED = 0x02
export const STATE_ACTIVE = 0x04
export const STATE_BANNED = 0x80
export const STATE_SMS_CONFIRMED = 0x10

export const FLIXX_DECIMALS = 2

export const TYPE_SERIE = 0x02
export const TYPE_VIDEO = 0x01

export const GROUP_ID = {
  series: 'a6cf1afe-a2bb-4c50-b0e9-fe87f465ff94',
  community: '91770878-21ca-48f8-927e-03fc45d2de92',
}
