import { jsx as _jsx } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { CarouselBase, CarouselButtons, Skeleton } from 'ui';
import { CarouselContainer } from './styles';
var Root = function (_a) {
    var children = _a.children, defaultSlidePosition = _a.defaultSlidePosition, isLoading = _a.isLoading;
    var _b = useState(6), slidesPerView = _b[0], setSlidesPerView = _b[1];
    useEffect(function () {
        var getSelidesPerView = function () {
            var width = document.body.getBoundingClientRect().width;
            if (width < 500)
                setSlidesPerView(1);
            else if (width < 1000)
                setSlidesPerView(2);
            else if (width < 1400)
                setSlidesPerView(3);
            else if (width < 2400)
                setSlidesPerView(4);
            else
                setSlidesPerView(5);
        };
        getSelidesPerView();
        window.addEventListener('resize', getSelidesPerView);
        return function () { window.removeEventListener('resize', getSelidesPerView); };
    }, []);
    if (isLoading) {
        return (_jsx(CarouselContainer, { children: _jsx(CarouselBase, { slidesPerView: slidesPerView, children: Array(8).fill(null).map(function (_, idx) { return (_jsx(Skeleton, {}, idx)); }) }) }, 'loading'));
    }
    return (_jsx(CarouselContainer, { children: _jsx(CarouselBase, { slidesPerView: slidesPerView, renderNavigationBtns: CarouselButtons, defaultSlidePosition: defaultSlidePosition, rewind: true, children: children }) }, 'data'));
};
export default Root;
