import { useQuery } from '@tanstack/react-query';
import { useApi } from 'hooks';
var useGetCreator = function (nickname) {
    var apiFetch = useApi().apiFetch;
    // const { isLoggedIn } = useSession()
    var url = "users/creator/".concat(nickname);
    var getCreator = function () {
        return apiFetch({ url: url })
            .then(function (res) { return res.data; });
    };
    var options = {
        staleTime: 30000, // 30 seg
        // enabled: isLoggedIn,
    };
    return useQuery(['creator', nickname], getCreator, options);
};
export default useGetCreator;
