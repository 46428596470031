import { useQuery } from '@tanstack/react-query';
import { useApi } from 'hooks';
var useGetMovements = function (props) {
    var apiFetch = useApi().apiFetch;
    var _a = props || {}, _b = _a.filter, filter = _b === void 0 ? 'all' : _b, _c = _a.from, from = _c === void 0 ? '' : _c, _d = _a.to, to = _d === void 0 ? '' : _d;
    var url = '/wallet/movements'
        + "?filter=".concat(filter)
        + "&from=".concat(from)
        + "&to=".concat(to);
    var getMovements = function () {
        return apiFetch({ url: url })
            .then(function (res) { return res.data; });
    };
    return useQuery(['movements'], getMovements);
};
export default useGetMovements;
