var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { ReactComponent as ChevronLeftSVG } from 'assets/icons/chevron-left.svg';
import { useOpen } from 'hooks';
import { Anteroom, Button, Text } from 'ui';
var ButtonStyled = styled(Button)(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  &:hover {\n    * {\n      font-weight: 800;\n      stroke-width: 2px;\n    }\n  }\n"], ["\n  &:hover {\n    * {\n      font-weight: 800;\n      stroke-width: 2px;\n    }\n  }\n"])));
var OverflowManager = function (props) {
    var children = props.children;
    var t = useTranslation().t;
    var modalShowMore = useOpen();
    var divRef = useRef();
    var bodyRef = useRef();
    var _a = useState(false), isTextOverflowing = _a[0], setIsTextOverflowing = _a[1];
    var checkOverflow = function () {
        var _a, _b;
        setIsTextOverflowing(((_a = bodyRef.current) === null || _a === void 0 ? void 0 : _a.offsetHeight) > ((_b = divRef.current) === null || _b === void 0 ? void 0 : _b.offsetHeight));
    };
    useEffect(function () {
        checkOverflow();
    }, [children]);
    useEffect(function () {
        if (!bodyRef.current || !divRef.current)
            return;
        checkOverflow();
        window.addEventListener('resize', checkOverflow);
        return function () {
            window.removeEventListener('resize', checkOverflow);
        };
    }, []);
    return (_jsxs(_Fragment, { children: [modalShowMore.isOpen && (_jsx(Anteroom.InfoExtend, { close: modalShowMore.close, children: children })), _jsxs("div", { ref: divRef, style: {
                    flexGrow: 1,
                    overflow: 'hidden',
                    position: 'relative',
                }, children: [_jsx("div", { ref: bodyRef, children: children }), isTextOverflowing && (_jsxs("div", { style: {
                            position: 'absolute',
                            bottom: 0,
                            left: 0,
                            width: '100%',
                        }, children: [_jsx("div", { style: {
                                    height: 35,
                                    background: 'linear-gradient(0deg, black, transparent)',
                                } }), _jsx("div", { style: { backgroundColor: 'black' }, children: _jsx(ButtonStyled, { variant: 'text', onClick: modalShowMore.open, children: _jsxs(Text, { color: 'headline', weight: 'medium', style: { display: 'flex', alignItems: 'center', gap: 4 }, children: [t('common:showMore'), _jsx(ChevronLeftSVG, { height: 20, style: { rotate: '180deg' } })] }) }) })] }))] })] }));
};
export default OverflowManager;
var templateObject_1;
