import { useMutation } from '@tanstack/react-query'

import { useApi } from 'hooks'
import { RECAPTCHA_TYPES } from 'config/constants'

import useResendCode from './useResendCode'

const useChangeMobileNumber = () => {
  const { apiFetch } = useApi()

  const { resendCode } = useResendCode()

  const url = '/auth/mobilenumber'

  const changeMobileNumber = ({ mobileNumber }) => {
    return apiFetch({
      url,
      method: 'PUT',
      data: { mobileNumber },
      captcha: {
        type: RECAPTCHA_TYPES.v3,
        action: 'mobilenumber_web',
      },
    })
      .then(res => {
        resendCode({ mobileNumber })
        return res.data
      })
  }

  const {
    mutate,
    ...rest
  } = useMutation(changeMobileNumber)

  return {
    changeMobileNumber: mutate,
    ...rest,
  }
}

export default useChangeMobileNumber
