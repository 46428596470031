import { useMediaQuery } from 'react-responsive';
var useResponsive = function () {
    // const { height, width } = document.body.getBoundingClientRect()
    // const isMobile = width < 500;
    var isMobile = useMediaQuery({ query: '(max-width: 500px)' });
    var isMobileBig = useMediaQuery({ query: '(max-width: 760px)' });
    var isMobileSmall = useMediaQuery({ query: '(max-width: 374px)' });
    var isTab = useMediaQuery({ query: '(max-width: 850px)' });
    var isTabBig = useMediaQuery({ query: '(max-width: 1100px)' });
    var isTabLand = useMediaQuery({ query: '(max-width: 1300px)' });
    var isLaptop = useMediaQuery({ query: '(max-width: 1600px)' });
    var isDesktop = useMediaQuery({ query: '(max-width: 1799px)' });
    var isBigDesktop = useMediaQuery({ query: '(min-width: 1800px)' });
    var isPortrait = useMediaQuery({ query: '(orientation: portrait)' });
    return {
        isMobile: isMobile,
        isMobileBig: isMobileBig,
        isMobileSmall: isMobileSmall,
        isTab: isTab,
        isTabBig: isTabBig,
        isTabLand: isTabLand,
        isLaptop: isLaptop,
        isDesktop: isDesktop,
        isBigDesktop: isBigDesktop,
        isPortrait: isPortrait,
    };
};
export default useResponsive;
