import { useQuery } from '@tanstack/react-query';
import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as TrophySVG } from 'assets/images/flixx/trophy.svg';
import { useApi, useSession, useToast } from 'hooks';
import useNotifyRewards from './useNotifyRewards';
var useGetNewRewards = function () {
    var apiFetch = useApi().apiFetch;
    var t = useTranslation().t;
    var navigate = useNavigate();
    var isFreshLogIn = useSession().isFreshLogIn;
    var toast = useToast().toast;
    var notifyRewards = useNotifyRewards().notifyRewards;
    var tryRef = useRef(1);
    var url = '/me/rewards/unnoticied';
    var sendToast = function () {
        toast(isFreshLogIn
            ? t('challenges:toast.message.pending')
            : t('challenges:toast.message.new'), {
            icon: TrophySVG,
            onClick: function () { navigate('?view=challenges'); },
            messageBtn: t('challenges:toast.button'),
        });
        notifyRewards();
    };
    var refetch = function () {
        if (tryRef.current === 3 || isFreshLogIn)
            return;
        tryRef.current += 1;
        setTimeout(function () {
            queryFn();
        }, 1500);
    };
    var queryFn = function () {
        return apiFetch({ url: url })
            .then(function (res) {
            if (res.data.length) {
                sendToast();
            }
            else {
                refetch();
            }
            return res.data;
        });
    };
    return useQuery({
        queryKey: ['new-rewards'],
        queryFn: queryFn,
        staleTime: 0,
        cacheTime: 0,
        enabled: false,
    });
};
export default useGetNewRewards;
