import { useQuery } from '@tanstack/react-query';
import { useApi, useSession } from 'hooks';
import useGetMe from './useGetMe';
var useGetBalance = function () {
    var apiFetch = useApi().apiFetch;
    var isLoggedIn = useSession().isLoggedIn;
    var me = useGetMe().data;
    var url = '/me/balance';
    var queryFn = function () {
        return apiFetch({ url: url })
            .then(function (res) { return res.data.amount; });
    };
    return useQuery({
        queryKey: ['balance'],
        queryFn: queryFn,
        staleTime: 30000, // 30 sec
        placeholderData: '0.00',
        enabled: isLoggedIn && !!(me === null || me === void 0 ? void 0 : me.features.flixx),
    });
};
export default useGetBalance;
