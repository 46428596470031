import styled from 'styled-components'

export const InputWrapper = styled.div`
  height: 48px;
  width: 100%;
  max-width: 48px;
  position: relative;

  * {
    width: 100%;
    text-align: center;
  }
`

export const InputStyled = styled.input`
  height: 100%;
  width: 100%;

  text-align: center;
  border-radius: 8px;
  border: ${({ error }) => error
    ? '1px solid #F43F5E'
    : '1px solid #3F3F46'
};
  background-color: #27272A;
  caret-color: white;
  color: white;
`

export const Placeholder = styled.div`
  position: absolute;
  height: 100%;
  display: grid;
  place-items: center;
  pointer-events: none;
  color: ${({ theme }) => theme.text.default};
`
