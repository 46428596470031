var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
var SkeletonStyled = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  animation: fadeInOut 2s infinite linear;\n  width: 100%;\n  height: 100%;\n  border-radius: 8px;\n  aspect-ratio: 16 / 9;\n\n  @keyframes fadeInOut {\n    0% {\n      background-color: ", ";\n      opacity: .9;\n    }\n    50% {\n      background-color: ", ";\n      opacity: .3;\n    }\n    100% {\n      background-color: ", ";\n      opacity: .9;\n    }\n  }\n\n"], ["\n  animation: fadeInOut 2s infinite linear;\n  width: 100%;\n  height: 100%;\n  border-radius: 8px;\n  aspect-ratio: 16 / 9;\n\n  @keyframes fadeInOut {\n    0% {\n      background-color: ", ";\n      opacity: .9;\n    }\n    50% {\n      background-color: ", ";\n      opacity: .3;\n    }\n    100% {\n      background-color: ", ";\n      opacity: .9;\n    }\n  }\n\n"])), function (_a) {
    var theme = _a.theme;
    return theme.old.colors.gray[200];
}, function (_a) {
    var theme = _a.theme;
    return theme.old.colors.gray[100];
}, function (_a) {
    var theme = _a.theme;
    return theme.old.colors.gray[200];
});
export default SkeletonStyled;
var templateObject_1;
