var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import { useGetHero } from 'api/content';
import { useResponsive } from 'hooks';
import useSearchHandler from 'templates/LayoutPlay/Search/useSearchHandler';
import { CarouselCard, Skeleton, } from 'ui';
import { getContentUrl } from 'utils/contents';
import { getMedia } from 'utils/thumbnails';
import Buttons from './Buttons';
import Pagination from './Pagination';
import { GradientBottom, GradientTop, } from './styles';
var AnimContainer = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  animation: heroslide 1500ms linear;\n\n  @keyframes heroslide {\n    from {\n      opacity: 0;\n    }\n    to {\n      opacity: 1;\n    }\n  }\n"], ["\n  animation: heroslide 1500ms linear;\n\n  @keyframes heroslide {\n    from {\n      opacity: 0;\n    }\n    to {\n      opacity: 1;\n    }\n  }\n"])));
var Hero = function () {
    var _a = useGetHero(), hero = _a.data, isInitialLoading = _a.isInitialLoading, isFetching = _a.isFetching;
    var isSearchOpen = useSearchHandler().isOpen;
    var isMobile = useResponsive().isMobile;
    var _b = useState(), height = _b[0], setHeight = _b[1];
    var _c = useState(0), slide = _c[0], setSlide = _c[1];
    var _d = useState([0]), preloads = _d[0], setPreloads = _d[1];
    var _e = useState(false), isHover = _e[0], setIsHover = _e[1];
    var ref = useRef(null);
    var startSwipeRef = useRef();
    var current = hero === null || hero === void 0 ? void 0 : hero.find(function (content) { return content.order === slide; });
    var cover = current
        ? getMedia({ media: current.media, size: 'hero' })
        : '';
    var handleTouchStart = function (e) {
        startSwipeRef.current = e.touches[0].clientX;
    };
    var handleTouchEnd = function (e) {
        var start = startSwipeRef.current;
        if (!start)
            return;
        var end = e.changedTouches[0].clientX;
        var deltaX = end - start;
        if (deltaX > 50) {
            prevSlide();
        }
        if (deltaX < -50) {
            nextSlide();
        }
    };
    var preloadImg = function (order) {
        if (!hero)
            return;
        if (typeof order !== 'number')
            return;
        if (preloads.includes(order))
            return;
        var nextContent = hero.find(function (c) { return c.order === order; });
        if (!nextContent)
            return;
        var nextImg = getMedia({ media: nextContent.media, size: 'hero' });
        var img = new Image();
        img.src = nextImg;
        setPreloads(function (prev) { return __spreadArray(__spreadArray([], prev, true), [order], false); });
    };
    var nextSlide = function () {
        if (!hero)
            return;
        setSlide(function (currentSlide) {
            var next = currentSlide === hero.length - 1
                ? 0
                : currentSlide + 1;
            return next;
        });
    };
    var prevSlide = function () {
        if (!hero)
            return;
        setSlide(function (currentSlide) {
            var prev = currentSlide === 0
                ? hero.length - 1
                : currentSlide - 1;
            return prev;
        });
    };
    var handleHeight = function () {
        if (!ref.current)
            return;
        var h = (9 / 16) * ref.current.offsetWidth;
        setHeight(h);
    };
    var handleHeight = function () {
        if (!ref.current)
            return;
        var h = (9 / 16) * ref.current.offsetWidth;
        setHeight(h);
    };
    useEffect(function () {
        handleHeight();
    }, [isFetching]);
    useEffect(function () {
        if (!hero)
            return;
        var next = slide + 1;
        var isNextValid = next < hero.length;
        var isNextPreloaded = preloads.includes(next);
        if (isNextValid && !isNextPreloaded) {
            preloadImg(next);
        }
        if (isHover)
            return;
        var nextTimeout = setTimeout(function () {
            nextSlide();
        }, 5000);
        return function () {
            clearTimeout(nextTimeout);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [hero, slide, isHover]);
    useEffect(function () {
        window.addEventListener('resize', handleHeight);
        return function () {
            window.removeEventListener('resize', handleHeight);
        };
    }, []);
    if (isInitialLoading || !(hero === null || hero === void 0 ? void 0 : hero.length)) {
        return (_jsxs("div", { ref: ref, style: {
                position: 'relative',
                zIndex: 0,
                height: isSearchOpen ? 0 : height,
                opacity: isSearchOpen ? 0 : 1,
                marginBottom: isSearchOpen ? isMobile ? 100 : 300 : 0,
                transition: 'all 500ms',
                overflow: 'hidden',
                maxHeight: '100svh',
            }, children: [_jsx(Skeleton, { style: { borderRadius: 0 } }), _jsx(GradientTop, {}), _jsx(GradientBottom, {})] }));
    }
    return (_jsxs("div", { ref: ref, onTouchStart: handleTouchStart, onTouchEnd: handleTouchEnd, onPointerEnter: function () { setIsHover(true); }, onPointerLeave: function () { setIsHover(false); }, style: {
            position: 'relative',
            zIndex: 0,
            height: isSearchOpen ? 0 : height,
            opacity: isSearchOpen ? 0 : 1,
            marginBottom: isSearchOpen ? isMobile ? 100 : 300 : 0,
            transition: 'all 500ms',
            overflow: 'hidden',
            maxHeight: '100svh',
        }, children: [_jsx(GradientBottom, {}), _jsx(GradientTop, {}), _jsx(Pagination, { index: slide, total: hero.length }), _jsx(Buttons, { nextSlide: nextSlide, prevSlide: prevSlide }), current && (_jsx(AnimContainer, { children: _jsx(CarouselCard, { title: current.title, imgSrc: cover, to: getContentUrl(current.contentType, current.uuid), state: {
                        title: current.title,
                        body: current.body,
                        cover: cover,
                    }, isHoverEffect: false, isRounded: false }, current.uuid) }, slide))] }));
};
export default Hero;
var templateObject_1;
