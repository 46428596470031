import React, { useEffect, useRef, useState } from 'react'

import { Text as TextUI } from 'ui'

import {
  Container,
  Placeholder,
  SIZES,
  ErrorContainer,
  Input,
} from './styles'


const Text = ({
  id,
  name,
  type,
  label,
  value,
  placeholder,
  onChange,
  size = 'large',
  autoComplete,
  autoFocus,
  iconLeft: IconLeft,
  iconRight: IconRight,
  iconRightLabel,
  iconRightClick,
  onBlur,
  onFocus,
  error,
  maxLength,
  inputMode,
  readOnly,
  inputStyle,
}) => {
  const ref = useRef()
  const inputRef = useRef()
  const buttonRef = useRef()

  const [isHover, setIsHover] = useState(false)
  const [isInputActive, setIsInputActive] = useState(false)
  const [isInputFocused, setIsInputFocused] = useState(false)
  const [isButtonFocused, setIsButtonFocused] = useState(false)

  const handleChange = (e) => onChange(e.target.value)

  const isFocus = isInputFocused || isButtonFocused

  const labelPlaceholder = label || placeholder
  const isActive = isFocus || !!value
  const hidePlaceholder = isActive && !!placeholder
  const currentSize = SIZES[size]

  const handleFocus = () => {
    setIsInputFocused(true)
    setIsInputActive(true)
  }

  const handleBlur = () => {
    setIsInputActive(!!value)
    setIsInputFocused(false)
  }

  /**
   * This actives the input when
   * browser autofill it.
   */
  useEffect(() => {
    if (!inputRef.current?.value || !value) return
    setIsInputActive(true)
  }, [inputRef.current?.value, value])

  useEffect(() => {
    const handleClick = (e) => {
      if (!ref.current) return

      const isClicked = ref.current.contains(e.target)
      setIsInputFocused(isClicked)

      if (isClicked) inputRef.current.focus()
    }

    window.addEventListener('click', handleClick)
    return () => { window.removeEventListener('click', handleClick) }
  }, [])


  return (
    <div style={{ width: '100%' }}>

      <Container
        ref={ref}
        $isFocused={isInputFocused || isButtonFocused}
        $hasError={!!error}
        $size={size}
        onMouseEnter={() => { setIsHover(true) }}
        onMouseLeave={() => { setIsHover(false) }}
      >

        {IconLeft && <IconLeft height={20} width={20} />}

        <label
          autoComplete='off'
          htmlFor={name}
          onBlur={onBlur}
          onFocus={onFocus}
        >

          {labelPlaceholder && (
            <Placeholder
              $hide={hidePlaceholder}
              $isHover={isHover && !value}
            >
              <TextUI
                weight={isInputActive
                  ? 'medium'
                  : 'normal'
                }
                size={isInputActive
                  ? currentSize.labelVariant.focused
                  : currentSize.labelVariant.default
                }
              >
                {labelPlaceholder}
              </TextUI>
            </Placeholder>
          )}


          <Input
            $isActive={isInputActive}
            $size={size}
            ref={inputRef}
            id={id}
            type={type}
            name={name}
            value={value}
            onChange={handleChange}
            autoComplete={autoComplete}
            maxLength={maxLength}
            inputMode={inputMode}
            onFocus={handleFocus}
            onBlur={handleBlur}
            autoFocus={autoFocus}
            readOnly={readOnly}
            style={inputStyle}
          />
        </label>

        {IconRight && iconRightLabel && (
          <span>{iconRightLabel}</span>
        )}

        {IconRight && (
          <button
            ref={buttonRef}
            type='button'
            onClick={iconRightClick}
            onFocus={() => { setIsButtonFocused(true) }}
            onBlur={() => { setIsButtonFocused(false) }}
            style={{
              display: 'grid',
              placeItems: 'center',
              cursor: 'pointer',
              backgroundColor: 'transparent',
              border: 'none',
            }}
          >
            <IconRight
              height={20}
              width={20}
            />
          </button>
        )}

      </Container>

      {error && (
        <ErrorContainer>
          <TextUI
            size={isActive
              ? currentSize.errorVariant
              : currentSize.errorVariant
            }
            color='error'
          >
            {error}
          </TextUI>
        </ErrorContainer>
      )}
    </div>
  )
}

export default Text
