import { jsx as _jsx } from "react/jsx-runtime";
import { Text } from 'ui';
export var richStringToNodes = function (text, options) {
    if (!text)
        return;
    var _a = (options !== null && options !== void 0 ? options : {}).bold, Bold = _a === void 0 ? function (_a) {
        var children = _a.children;
        return (_jsx(Text, { as: 'span', weight: 600, color: '#D4D4D8', children: children }));
    } : _a;
    var list = text.split('**');
    var startsBold = text.startsWith('**');
    var newText = list.map(function (t, idx) {
        var isBold = (idx === 0 && startsBold) || idx % 2 !== 0;
        if (isBold) {
            return (_jsx(Bold, { children: t }, t));
        }
        return t;
    });
    return newText;
};
