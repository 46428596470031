var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled, { css } from 'styled-components';
export var Container = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  ", "\n"], ["\n  ", "\n"])), function (_a) {
    var theme = _a.theme;
    var headerHeight = theme.old.sizes.header;
    var isMobile = theme.old.breakpoints.isMobile;
    return css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n      margin-top: ", ";\n\n      ", " {\n        margin-top: 0;\n      }\n    "], ["\n      margin-top: ", ";\n\n      ", " {\n        margin-top: 0;\n      }\n    "])), "-".concat(headerHeight), isMobile);
});
var templateObject_1, templateObject_2;
