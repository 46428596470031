var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useLayoutEffect } from 'react';
import { isIOS, isMobile } from 'react-device-detect';
import { createPortal } from 'react-dom';
var ModalPortal = function (props) {
    var children = props.children, onBgClick = props.onBgClick, style = props.style;
    var root = document.getElementById('modal');
    var html = document.getElementsByTagName('html');
    var isIphoneIpad = isMobile && isIOS;
    useLayoutEffect(function () {
        if (isIphoneIpad)
            return;
        document.body.style.overflow = 'hidden';
        return function () {
            document.body.style.overflow = 'visible';
        };
    }, []);
    useLayoutEffect(function () {
        if (!isIphoneIpad)
            return;
        var scrollY = window.scrollY;
        document.body.style.overflow = 'hidden';
        document.body.style.top = String(-1 * scrollY) + 'px';
        document.body.style.height = '100lvh';
        document.body.style.width = '100%';
        document.body.style.position = 'fixed';
        return function () {
            html[0].style.scrollBehavior = 'unset';
            document.body.style.position = 'unset';
            document.body.style.overflow = 'visible';
            window.scroll({ top: scrollY });
            html[0].style.scrollBehavior = 'smooth';
        };
    }, []);
    var ModalContainer = (_jsxs("div", { style: __assign({ position: 'fixed', top: 0, left: 0, right: 0, bottom: 0, zIndex: 99, display: 'grid', placeItems: 'center' }, style), children: [_jsx("div", { onClick: onBgClick, style: {
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    right: 0,
                    bottom: 0,
                    backgroundColor: 'rgba(0,0,0, .45)',
                } }), children] }));
    return createPortal(ModalContainer, root);
};
export default ModalPortal;
