var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled, { css } from 'styled-components';
var Title = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  ", "\n"], ["\n  ", "\n"])), function (_a) {
    var theme = _a.theme;
    return css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n    width: fit-content;\n    margin-top: 40px;\n    margin-bottom: 24px;\n    margin-left: ", ";\n    \n    ", " {\n      margin-left: ", ";\n    }\n\n    ", " {\n      margin-top: 32px;\n      margin-bottom: 16px;\n    }\n  "], ["\n    width: fit-content;\n    margin-top: 40px;\n    margin-bottom: 24px;\n    margin-left: ", ";\n    \n    ", " {\n      margin-left: ", ";\n    }\n\n    ", " {\n      margin-top: 32px;\n      margin-bottom: 16px;\n    }\n  "])), theme.old.sizes.sidesMargin.large, theme.old.breakpoints.isTab, function (_a) {
        var theme = _a.theme;
        return theme.old.sizes.sidesMargin.small;
    }, theme.old.breakpoints.isMobile);
});
export default Title;
var templateObject_1, templateObject_2;
