import React, { useState } from 'react'

import { ReactComponent as EyeOnSVG } from 'assets/icons/eye-on.svg'
import { ReactComponent as EyeOffSVG } from 'assets/icons/eye-off.svg'

import Text from '../Text'

const Password = ({
  value,
  label,
  placeholder,
  onChange,
  size,
  error,
  autoComplete,
}) => {
  const [showPassword, setShowPassword] = useState(false)

  const toggleShowPassword = () => { setShowPassword(prev => !prev) }

  return (
    <Text
      label={label}
      placeholder={placeholder}
      value={value}
      onChange={onChange}
      size={size}
      error={error}
      iconRight={showPassword ? EyeOnSVG : EyeOffSVG}
      iconRightClick={toggleShowPassword}
      type={showPassword ? 'text' : 'password'}
      autoComplete={autoComplete}
    />
  )
}

export default Password
