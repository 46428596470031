import { useQuery } from '@tanstack/react-query';
import { useApi } from 'hooks';
var useGetFlixxoInfo = function () {
    var apiFetch = useApi().apiFetch;
    var url = '/wallet/flixx/info';
    var getFlixxoInfo = function () {
        return apiFetch({ url: url })
            .then(function (res) { return res.data; });
    };
    var options = {
        staleTime: 0,
    };
    return useQuery(['flixxInfo'], getFlixxoInfo, options);
};
export default useGetFlixxoInfo;
