import { useQuery } from '@tanstack/react-query';
import { useGetMe } from 'api/user';
import useApi from 'hooks/useApi';
var useGetContent = function (props) {
    var uuid = props.uuid, contentType = props.contentType;
    var me = useGetMe().data;
    var apiFetch = useApi().apiFetch;
    var url = "/".concat(contentType, "/").concat(uuid);
    var queryFn = function () {
        if (!uuid)
            return null;
        return apiFetch({ url: url })
            .then(function (res) { return res.data; });
    };
    return useQuery({
        queryKey: ['content', contentType, uuid, 'lang', me === null || me === void 0 ? void 0 : me.uuid],
        queryFn: queryFn,
        staleTime: 60000 * 30, // 30 min
    });
};
export default useGetContent;
