export var PLAY_STORE_URL = 'https://play.google.com/store/apps/details?id=com.flixxo.apps.flixxoapp';
export var APPLE_STORE_URL = 'https://apps.apple.com/app/flixxo/id1447682880';
export var CAPTCHA_TOKEN = '6LcClsgoAAAAAGIFthp3_GzYV9RrOlLinREhdfMs';
export var RECAPTCHA_TYPES = {
    v2: 'recaptcha-v2',
    v3: 'recaptcha-v3',
    bypass: 'bypass',
};
export var USER_STATUS = {
    enabled: 1,
    registered: 32,
    confirmed: 2,
    active: 4,
    emailConfirmed: 8,
    smsConfirmed: 16,
    banned: 128,
    deleting: 256,
    deleted: 512,
};
export var CONTENT_STATUS = {
    draft: 0x00,
    new: 0x10,
    enabled: 0x20,
    cancel: 0x30,
    paused: 0x40,
};
export var ORDER = {
    asc: 'ascendent',
    desc: 'descendent',
};
export var PLAYER_ID = 'my-player';
export var COINMARKETCAP_URL = 'https://coinmarketcap.com/es/currencies/flixxo/';
export var UNISWAP_URL = 'https://app.uniswap.org/swap?inputCurrency=0xdac17f958d2ee523a2206206994597c13d831ec7&outputCurrency=0xf04a8ac553fcedb5ba99a64799155826c136b0be';
export var BITMART_URL = 'https://www.bitmart.com/trade/en-US?symbol=FLIXX_USDT';
export var CarouselType = {
    vertical: 1,
    horizontal: 2,
};
export var MediaType = {
    cover: 1,
    thumbnail: 2,
    poster: 4,
};
