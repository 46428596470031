
import { useMutation, useQueryClient } from '@tanstack/react-query'

import { useApi } from 'hooks'
import { GAHandler } from 'utils/GA'

const usePostWatchedAd = () => {
  const { apiFetch } = useApi()
  const queryClient = useQueryClient()

  const url = '/advertisements/own'

  const postWatchedAd = ({ id }) => {
    return apiFetch({
      url,
      method: 'POST',
      data: { advertisementId: id },
    })
  }

  const {
    mutateAsync,
    ...rest
  } = useMutation({
    mutationFn: postWatchedAd,
    onSuccess: async ({ data }) => {
      GAHandler('ad_reward_success')
      await queryClient.setQueryData(['balance'], () => {
        return data.newBalance
      })
    },
    onError: (err) => {
      GAHandler('ad_reward_fail')
      throw err
    },
  })

  return {
    postWatchedAd: mutateAsync,
    ...rest,
  }
}

export default usePostWatchedAd
