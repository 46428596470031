import { jsx as _jsx, jsxs as _jsxs, Fragment as _Fragment } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { Anteroom, Pill, Text } from 'ui';
import { getContentTypeStr, getSubtitles } from 'utils/contents';
import { formatDuration } from 'utils/duration';
var Info = function (props) {
    var content = props.short, title = props.title, body = props.body, likes = props.likes;
    var _a = content || { category: {} }, category = _a.category.name, // category.name
    audioLang = _a.audioLang, releaseDate = _a.releaseDate, duration = _a.duration;
    var t = useTranslation().t;
    var release = new Date(releaseDate).getFullYear();
    var dur = formatDuration(duration);
    var subs = getSubtitles(content).join(', ');
    return (_jsxs(_Fragment, { children: [_jsx(Anteroom.Title, { isLoading: !title, children: title }), _jsxs("div", { style: {
                    display: 'flex',
                    alignItems: 'center',
                    gap: 8,
                    marginTop: 8,
                }, children: [!!category && (_jsx(Pill, { value: getContentTypeStr(content.contentType, 1) })), likes >= 1 && (_jsxs(Text, { size: 'sm', weight: 'medium', color: '#FBBF24', children: ["\u2665\uFE0E ", likes] }))] }), _jsxs(Anteroom.Divider.Root, { isLoading: !category, children: [_jsx(Anteroom.Divider.Child, { children: release }), _jsx(Anteroom.Divider.Child, { children: dur }), _jsx(Anteroom.Divider.Child, { children: category })] }), _jsx(Anteroom.Body, { isLoading: !body, children: body }), _jsxs(Anteroom.Divider.Root, { isLoading: !audioLang, children: [_jsxs(Anteroom.Divider.Child, { children: [t('audio'), " (", audioLang, ")"] }), subs.length && (_jsxs(Anteroom.Divider.Child, { children: [t('common:subtitles'), " (", subs, ")"] }))] })] }));
};
export default Info;
