const { default: styled } = require('styled-components')

export const Container = styled.div`
  position: fixed;
  bottom: 24px;
  left: 50%;
  transform: translateX(-50%);
  width: 95%;
  max-width: 800px;
  z-index: 99999999999999999999;
  display: grid;
  place-items: center;
`

export const SnackWrapper = styled.div`
  animation: fade-up 400ms forwards ease-out;
  background-color: ${({ theme, $variant }) => theme.snackbar[$variant].background};
  width: 100%;
  border: ${({ theme, $variant }) => theme.snackbar[$variant].border};
  border-radius: 8px;
  overflow: hidden;
  min-height: 56px;
  padding: 12px;

  @keyframes fade-up {
    from {
      transform: translateY(20px);
      opacity: 0;
    }
    to {
      transform: translateY(0px);
      opacity: 1;
    }
  }
`
