import { useQuery } from '@tanstack/react-query'

import { useApi, useSession } from 'hooks'


const useGetPurchased = () => {
  const { isLoggedIn } = useSession()

  const { apiFetch } = useApi()

  const url = '/me/contents/purchased'

  const queryFn = () => {
    return apiFetch({ url })
      .then(res => res.data)
  }


  return useQuery({
    queryKey: ['purchases'],
    queryFn,
    staleTime: 60000 * 30, // 30 min
    // placeholderData: null,
    enabled: isLoggedIn,
  })
}

export default useGetPurchased
