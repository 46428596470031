import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { useOpen } from 'hooks';
export var TEST_CONFIG = {
    alwaysPlay: 'flixxo-alwaysPlay',
    captchaBypass: 'flixxo-captchaBypass',
    abValue: 'abValue',
};
var Settings = function () {
    var menu = useOpen();
    var tool = useOpen();
    if (tool.isOpen)
        return null;
    return (_jsxs("div", { style: {
            position: 'fixed',
            right: 16,
            bottom: 80,
            zIndex: 99999999,
        }, children: [_jsx("button", { onClick: menu.toggle, style: {
                    border: '1px solid #aaa',
                    height: 40,
                    width: 40,
                    backgroundColor: '#777',
                    borderRadius: 8,
                    backgroundImage: 'url(https://upload.wikimedia.org/wikipedia/commons/d/dc/Settings-icon-symbol-vector.png)',
                    backgroundSize: '80%',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    cursor: 'pointer',
                } }), menu.isOpen && (_jsxs("div", { style: {
                    position: 'absolute',
                    bottom: '120%',
                    right: 0,
                    backgroundColor: '#333',
                    border: '1px solid #aaa',
                    padding: 12,
                    color: 'white',
                    borderRadius: 8,
                    width: 'max-content',
                }, children: [_jsxs("label", { style: {
                            display: 'flex',
                            alignItems: 'center',
                            gap: 12,
                            marginBottom: 14,
                        }, children: [_jsx("input", { defaultChecked: !!localStorage.getItem(TEST_CONFIG.alwaysPlay), onChange: function (v) {
                                    if (v.target.checked) {
                                        localStorage.setItem(TEST_CONFIG.alwaysPlay, 'true');
                                    }
                                    else {
                                        localStorage.removeItem(TEST_CONFIG.alwaysPlay);
                                    }
                                }, type: 'checkbox' }), _jsxs("div", { children: ["Reproducci\u00F3n funcional", _jsx("p", { children: "(pre-requisito: comprar Blind T1E1)" })] })] }), _jsxs("label", { style: {
                            display: 'flex',
                            alignItems: 'center',
                            gap: 12,
                            marginBottom: 14,
                        }, children: [_jsx("input", { defaultChecked: !!localStorage.getItem(TEST_CONFIG.alwaysPlay), onChange: function (v) {
                                    if (v.target.checked) {
                                        localStorage.setItem(TEST_CONFIG.captchaBypass, 'true');
                                    }
                                    else {
                                        localStorage.removeItem(TEST_CONFIG.captchaBypass);
                                    }
                                }, type: 'checkbox' }), "Bypass de captcha"] }), _jsxs("label", { style: {
                            display: 'flex',
                            alignItems: 'start',
                            gap: 12,
                            marginBottom: 14,
                        }, children: [_jsx("input", { defaultValue: Number(localStorage.getItem(TEST_CONFIG.abValue)), type: 'number', min: 0, max: 100, onChange: function (e) {
                                    var value = e.target.value;
                                    localStorage.setItem(TEST_CONFIG.abValue, value);
                                }, style: { width: 50 } }), _jsxs("div", { children: [_jsx("p", { children: "A/B value" }), _jsx("p", { children: "[0-49]: Ads In-Stream" }), _jsx("p", { children: "[50-100]: Ads Rewarded" })] })] }), _jsxs("label", { style: {
                            display: 'flex',
                            alignItems: 'start',
                            gap: 12,
                            marginBottom: 14,
                        }, children: [_jsx("input", { defaultChecked: tool.isOpen, onChange: tool.toggle, type: 'checkbox' }), _jsx("div", { children: _jsx("p", { children: "Ocultar" }) })] })] }))] }));
};
export default Settings;
