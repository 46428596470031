import { jsx as _jsx, Fragment as _Fragment } from "react/jsx-runtime";
import { useDetectAdBlock } from 'adblock-detect-react';
import { useOpen } from 'hooks';
import ModalAdBlock from './ModalAdBlocker';
import PlayerInStream from './PlayerInStream';
import PlayerRewarded from './PlayerRewarded';
var PlayerAdManager = function (props) {
    var isOpen = props.isOpen, close = props.close, onReward = props.onReward;
    var adBlockDetected = useDetectAdBlock();
    var modalAdBlock = useOpen();
    var abValue = localStorage.getItem('abValue');
    var isRewarded = Number(abValue) >= 50;
    var handleClose = function () {
        close();
        modalAdBlock.close();
    };
    if (isOpen && adBlockDetected) {
        return (_jsx(ModalAdBlock, { close: handleClose }));
    }
    if (isOpen) {
        return (_jsx(_Fragment, { children: isRewarded
                ? _jsx(PlayerRewarded, { close: close, onReward: onReward })
                : _jsx(PlayerInStream, { close: close, onEnd: onReward }) }));
    }
    return null;
};
export default PlayerAdManager;
