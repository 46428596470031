import { create } from 'zustand';
var useModalAuthStore = create(function (set) { return ({
    variant: '',
    openLogin: function () { set(function () { return ({ variant: 'login' }); }); },
    openWelcome: function () { set(function () { return ({ variant: 'welcome' }); }); },
    openRegister: function () { set(function () { return ({ variant: 'register' }); }); },
    openRecovery: function () { set(function () { return ({ variant: 'recovery' }); }); },
    openValidate: function () { set(function () { return ({ variant: 'validate' }); }); },
    openReferred: function () { set(function () { return ({ variant: 'referred' }); }); },
    openOnboarding: function () { set(function () { return ({ variant: 'onboarding' }); }); },
    close: function () { set(function () { return ({ variant: '' }); }); },
}); });
export default useModalAuthStore;
