var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useRef, useState } from 'react';
import DatePickerLib from 'react-datepicker';
import { useTranslation } from 'react-i18next';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { localeApi, userApi } from 'api';
import { GENDERS } from 'api/user/useGetMe';
import { ReactComponent as CalendarSvg } from 'assets/icons/calendar.svg';
import { ReactComponent as InfoSvg } from 'assets/icons/info.svg';
import 'react-datepicker/dist/react-datepicker.css';
import { Button, Input, Text } from 'ui';
import { useToast } from 'hooks';
var PAST_13_YEARS = new Date();
PAST_13_YEARS.setFullYear(PAST_13_YEARS.getFullYear() - 13);
var FUTURE_100_YEARS = new Date();
FUTURE_100_YEARS.setFullYear(FUTURE_100_YEARS.getFullYear() + 100);
var DatePickerWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  .react-datepicker-wrapper {\n    pointer-events: none;\n    width: 100%;\n    visibility: hidden;\n    height: 0;\n  }\n  \n  .react-datepicker__input-container {\n    width: 100%;\n  }\n\n  input {\n    width: 100%;\n  }\n"], ["\n  .react-datepicker-wrapper {\n    pointer-events: none;\n    width: 100%;\n    visibility: hidden;\n    height: 0;\n  }\n  \n  .react-datepicker__input-container {\n    width: 100%;\n  }\n\n  input {\n    width: 100%;\n  }\n"])));
var PersonalInfo = function () {
    var _a, _b;
    var t = useTranslation().t;
    var toast = useToast().toast;
    var navigate = useNavigate();
    var datepickerRef = useRef();
    var countries = localeApi.useGetCountries().data;
    var me = userApi.useGetMe().data;
    var _c = userApi.useUpdateProfile(), updateProfile = _c.updateProfile, isLoading = _c.isLoading, isSuccess = _c.isSuccess;
    var _d = useState(''), realName = _d[0], setRealName = _d[1];
    var _e = useState(null), birthdate = _e[0], setBirthdate = _e[1];
    var _f = useState(null), gender = _f[0], setGender = _f[1];
    var _g = useState(null), country = _g[0], setCountry = _g[1];
    var showMessage = !(me === null || me === void 0 ? void 0 : me.profile.realName)
        || !(me === null || me === void 0 ? void 0 : me.profile.birthDate)
        || !(me === null || me === void 0 ? void 0 : me.profile.countryId)
        || !(me === null || me === void 0 ? void 0 : me.profile.gender);
    var compareDates = function () {
        var _a;
        var dateProfile = (_a = me === null || me === void 0 ? void 0 : me.profile.birthDate) === null || _a === void 0 ? void 0 : _a.split('T')[0];
        var dateInput = birthdate === null || birthdate === void 0 ? void 0 : birthdate.toISOString().split('T')[0];
        return dateProfile === dateInput;
    };
    var disableSubmit = !me || (realName && !realName.trim()) || ((me === null || me === void 0 ? void 0 : me.profile.realName) === realName
        && compareDates()
        && ((_a = me === null || me === void 0 ? void 0 : me.profile.countryId) !== null && _a !== void 0 ? _a : null) === ((_b = country === null || country === void 0 ? void 0 : country.id) !== null && _b !== void 0 ? _b : null)
        && (me === null || me === void 0 ? void 0 : me.profile.gender) === (gender === null || gender === void 0 ? void 0 : gender.value));
    var handleSubmit = function (e) {
        e.preventDefault();
        updateProfile({
            realName: realName,
            birthDate: birthdate === null || birthdate === void 0 ? void 0 : birthdate.toISOString().split('T')[0],
            gender: gender === null || gender === void 0 ? void 0 : gender.value,
            countryId: country === null || country === void 0 ? void 0 : country.id,
        });
    };
    useEffect(function () {
        if (!isSuccess)
            return;
        toast(t('userInfo:successMsg'));
        navigate('/');
    }, [isSuccess]);
    useEffect(function () {
        var _a;
        if (!me)
            return;
        setRealName((_a = me.profile.realName) !== null && _a !== void 0 ? _a : '');
        if (me.profile.birthDate) {
            var b = new Date(me.profile.birthDate);
            var year = b.getUTCFullYear();
            var month = b.getUTCMonth();
            var day = b.getUTCDate();
            var utc = new Date(year, month, day);
            setBirthdate(utc !== null && utc !== void 0 ? utc : null);
        }
        if (me.profile.gender) {
            var g = GENDERS.find(function (g) { return g.value === me.profile.gender; });
            setGender(g !== null && g !== void 0 ? g : null);
        }
        if (countries && me.profile.countryId) {
            var c = countries.find(function (c) { return c.id === me.profile.countryId; });
            setCountry(c !== null && c !== void 0 ? c : null);
        }
    }, [me, countries]);
    return (_jsx("div", { style: { padding: 16 }, children: _jsxs("div", { style: { maxWidth: 472, width: '100%', margin: '60px auto 0' }, children: [_jsx(Text, { size: 20, color: 'headline', weight: 600, children: t('userInfo:title') }), _jsx(Text, { size: 16, style: { marginTop: 12 }, children: t('userInfo:description') }), showMessage && (_jsxs("div", { style: {
                        background: '#F43F5E1A',
                        marginTop: 24,
                        padding: 12,
                        display: 'flex',
                        gap: 8,
                    }, children: [_jsx(InfoSvg, { width: 20, height: 20, color: '#F43F5E', style: { minWidth: 20 } }), _jsx(Text, { size: 12, color: 'headline', style: { marginTop: 2 }, children: t('userInfo:message') })] })), _jsxs("form", { onSubmit: handleSubmit, children: [_jsxs("div", { style: { marginTop: 24, display: 'flex', flexDirection: 'column', gap: 16 }, children: [_jsx(Input.Text, { label: t('userInfo:fullname'), onChange: setRealName, value: realName }), _jsxs("div", { style: { position: 'relative' }, children: [_jsx(Input.Text, { label: t('userInfo:birthdate'), value: birthdate ? birthdate.toLocaleDateString() : '', iconRight: function () { return _jsx(CalendarSvg, { color: 'white' }); }, iconRightClick: function () { var _a; return (_a = datepickerRef.current) === null || _a === void 0 ? void 0 : _a.setOpen(true); }, onFocus: function () { var _a; return (_a = datepickerRef.current) === null || _a === void 0 ? void 0 : _a.setOpen(true); }, readOnly: true }), _jsx(DatePickerWrapper, { style: {
                                                position: 'absolute',
                                                left: 0,
                                                right: 0,
                                                bottom: 0,
                                                height: 0,
                                            }, children: _jsx(DatePickerLib, { selected: birthdate || PAST_13_YEARS, ref: datepickerRef, onChange: setBirthdate, dropdownMode: 'select', showYearDropdown: true, showMonthDropdown: true, excludeDateIntervals: [{
                                                        start: PAST_13_YEARS,
                                                        end: FUTURE_100_YEARS,
                                                    }] }) })] }), _jsx(Input.Select, { options: GENDERS, placeholder: t('userInfo:gender'), size: 'large', isSearchable: false, selected: gender, onChange: setGender, getOptionLabel: function (opt) { return t("common:gender.".concat(opt.label)); } }), _jsx(Input.Select, { options: countries, placeholder: t('userInfo:country'), size: 'large', selected: country, onChange: setCountry, getOptionValue: function (opt) { return opt.id; }, getOptionLabel: function (opt) { return opt.Name.value; } })] }), _jsxs("div", { style: {
                                display: 'flex',
                                justifyContent: 'end',
                                gap: 16,
                                marginTop: 32,
                            }, children: [_jsx(Button, { to: '/', variant: 'secondary', children: t('common:cancel') }), _jsx(Button, { isLoading: isLoading, disabled: disableSubmit, children: t('common:save') })] })] })] }) }));
};
export default PersonalInfo;
var templateObject_1;
