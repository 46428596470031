var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled from 'styled-components';
export var Metadata = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  height: 100%;\n  width: 100%;\n  max-width: 460px;\n  min-width: 400px;\n  display: flex;\n  flex-direction: column;\n  height: 100%;\n  max-height: 296px;\n\n  ", " {\n    min-width: unset;\n    max-width: unset;\n  }\n"], ["\n  height: 100%;\n  width: 100%;\n  max-width: 460px;\n  min-width: 400px;\n  display: flex;\n  flex-direction: column;\n  height: 100%;\n  max-height: 296px;\n\n  ", " {\n    min-width: unset;\n    max-width: unset;\n  }\n"])), function (_a) {
    var theme = _a.theme;
    return theme.old.breakpoints.isMobileBig;
});
var templateObject_1;
