import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Slide } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Button, Text } from 'ui';
import { useResponsive } from 'hooks';
import { StyledToastify } from './styles';
import Icon from './Icon';
var Provider = function () { return (_jsx(StyledToastify, { position: 'bottom-center', autoClose: 5000, hideProgressBar: true, newestOnTop: true, transition: Slide, closeButton: false })); };
export var Message = function (props) {
    var onClick = props.onClick, message = props.message, messageBtn = props.messageBtn, icon = props.icon, toastProps = props.toastProps;
    // const { theme } = toastProps!
    var isMobile = useResponsive().isMobile;
    return (_jsxs("div", { style: {
            display: 'flex',
            alignItems: 'center',
            gap: 16,
        }, children: [icon, _jsx("div", { style: { flexGrow: 1 }, children: _jsx(Text, { size: 'sm', weight: 'medium', children: _jsx("span", { style: { color: '#52525B' }, children: message }) }) }), _jsx(Button, { variant: 'secondary-invert', size: isMobile ? 'small' : 'medium', onClick: onClick, weight: 'medium', children: messageBtn })] }));
};
var Toast = {
    Provider: Provider,
    Message: Message,
    Icon: Icon,
};
export default Toast;
