var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled, { css } from 'styled-components';
import ArrowLeftSVG from 'assets/iconsOld/arrow_left.svg';
import ArrowRightSVG from 'assets/iconsOld/arrow_right.svg';
var Arrow = css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  border: none;\n  background-color: ", ";\n  position: absolute;\n  top: 43%;\n  border-radius: 100%;\n  width: 40px;\n  height: 40px;\n  display: grid;\n  place-items: center;\n  transition: ", ";\n  margin: 0;\n  cursor: pointer;\n\n  z-index: 2;\n\n  &::after {\n    content: '';\n    position: absolute;\n    height: 12px;\n    width: 7px;\n    background-repeat: no-repeat;\n    top: 50%;\n    transform: translateY(-50%);\n  }\n\n  &:hover {\n    background-color: ", ";\n  }\n"], ["\n  border: none;\n  background-color: ", ";\n  position: absolute;\n  top: 43%;\n  border-radius: 100%;\n  width: 40px;\n  height: 40px;\n  display: grid;\n  place-items: center;\n  transition: ", ";\n  margin: 0;\n  cursor: pointer;\n\n  z-index: 2;\n\n  &::after {\n    content: '';\n    position: absolute;\n    height: 12px;\n    width: 7px;\n    background-repeat: no-repeat;\n    top: 50%;\n    transform: translateY(-50%);\n  }\n\n  &:hover {\n    background-color: ", ";\n  }\n"])), function (props) { return props.theme.old.colors.gray[200]; }, function (_a) {
    var theme = _a.theme;
    return theme.old.transition.default;
}, function (props) { return props.theme.old.colors.gray[400]; });
export var ArrowBack = styled.div(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  ", "\n  left: 32px;\n  right: auto;\n\n  &::after {\n    background-image: url(", ");\n  }\n"], ["\n  ", "\n  left: 32px;\n  right: auto;\n\n  &::after {\n    background-image: url(", ");\n  }\n"])), Arrow, ArrowLeftSVG);
export var ArrowNext = styled.div(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  ", "\n  left: auto;\n  right: 32px;\n\n  &::after {\n    background-image: url(", ");\n  }\n"], ["\n  ", "\n  left: auto;\n  right: 32px;\n\n  &::after {\n    background-image: url(", ");\n  }\n"])), Arrow, ArrowRightSVG);
var templateObject_1, templateObject_2, templateObject_3;
