import { useLocation as useLocationRRD } from 'react-router-dom';
var useLocation = function () {
    var _a = useLocationRRD(), path = _a.pathname, search = _a.search, state = _a.state;
    var pathname = path.substring(3);
    var isCreators = pathname === '/creators' || pathname.includes('/creators/');
    var isPlay = !isCreators;
    var isSeries = pathname === '/series' || pathname.includes('/series/');
    var isSearch = pathname === '/search';
    var isHome = pathname === '/';
    var isFlixx = pathname === '/flixx';
    var isBeCreator = pathname === '/be-a-creator';
    var isFaqs = pathname === '/faqs';
    return {
        isCreators: isCreators,
        isPlay: isPlay,
        isSeries: isSeries,
        isSearch: isSearch,
        isHome: isHome,
        isFlixx: isFlixx,
        isBeCreator: isBeCreator,
        isFaqs: isFaqs,
        pathname: path,
        search: search,
        state: state,
    };
};
export default useLocation;
