import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import Lottie from 'lottie-react';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import loaderJson from 'assets/lotties/player-loader.json';
import { ReactComponent as PlaySVG } from 'assets/player/play.svg';
import { PLAYER_ID } from 'config/constants';
import { useResponsive } from 'hooks';
import { GAHandler } from 'utils/GA';
import { Container, PlayerWrapper } from './styles';
import useVideojsSamples from './useVideojsSamples';
// import('video.js/dist/video-js.css')
var PLAYER_ERRORS = {
    1: {
        mediaError: 1,
        name: 'aborted',
        msg: 'La carga del video fue abortada',
    },
    2: {
        mediaError: 2,
        name: 'network',
        msg: 'Error de red durante la carga del video',
    },
    3: {
        mediaError: 3,
        name: 'decode',
        msg: 'Error de decodificación del video',
    },
    4: {
        mediaError: 4,
        name: 'src_not_supported',
        msg: 'El video no es compatible con el formato',
    },
    unknown: {
        mediaError: 'unknown',
        name: 'unknown',
        msg: 'Error desconocido en el video',
    },
};
var Player = function (_a) {
    var _b, _c, _d;
    var uuid = _a.uuid, hasDRM = _a.hasDRM, src = _a.src, manifest = _a.manifest, token = _a.token, onCanPlay = _a.onCanPlay, onPlay = _a.onPlay, onEnd = _a.onEnd, onTimeUpdate = _a.onTimeUpdate, onDuration = _a.onDuration, onError = _a.onError, playerRef = _a.playerRef, children = _a.children;
    var ref = useRef();
    var isMobileBig = useResponsive().isMobileBig;
    var _e = useState(true), isFirstPlay = _e[0], setIsFirstPlay = _e[1];
    var _f = useState(true), loader = _f[0], setLoader = _f[1];
    var _g = useState(false), showManualPlay = _g[0], setShowManualPlay = _g[1];
    // const isIPhone = isMobileOnly && isIOS
    var processVideo = useVideojsSamples().processVideo;
    var handlePlay = function () {
        setShowManualPlay(false);
        if (isFirstPlay)
            setIsFirstPlay(false);
        if (ref.current)
            ref.current.play();
        onPlay({ isFirstPlay: isFirstPlay });
    };
    var handleDuration = function (e) { return onDuration(e.target.duration); };
    var handleTimeUpdate = function (e) { return onTimeUpdate(e.target.currentTime); };
    var stopLoader = function () {
        // const { readyState } = e.target
        // const isReadyToPlay = readyState >= 3
        setLoader(false);
        onCanPlay();
    };
    var handleOnError = function (e) {
        var _a, _b, _c, _d, _e, _f, _g, _h, _j;
        var isVjs = !!((_a = e.target) === null || _a === void 0 ? void 0 : _a.player);
        var code = isVjs
            ? (_d = (_c = (_b = e.target) === null || _b === void 0 ? void 0 : _b.player) === null || _c === void 0 ? void 0 : _c.error_) === null || _d === void 0 ? void 0 : _d.code
            : (_f = (_e = e.target) === null || _e === void 0 ? void 0 : _e.error) === null || _f === void 0 ? void 0 : _f.code;
        if (isVjs && code < 5)
            return;
        var vjsError = (_h = (_g = e.target) === null || _g === void 0 ? void 0 : _g.player) === null || _h === void 0 ? void 0 : _h.error_;
        var mediaError = (_j = PLAYER_ERRORS[code]) !== null && _j !== void 0 ? _j : vjsError;
        var error = mediaError !== null && mediaError !== void 0 ? mediaError : PLAYER_ERRORS.unknown;
        GAHandler('player_error', { uuid: uuid, error: error });
        onError(true);
        stopLoader();
    };
    useEffect(function () {
        if (!hasDRM)
            return;
        if (!manifest || !token)
            return;
        processVideo(manifest, token, handleOnError);
        // eslint-disable-next-line
    }, [manifest, token]);
    useEffect(function () {
        if (src)
            return;
        setLoader(true);
        setIsFirstPlay(true);
    }, [src]);
    /**
     * if the video is not auto playing,
     * it shows the button to play it manually.
     */
    useLayoutEffect(function () {
        if (!(playerRef === null || playerRef === void 0 ? void 0 : playerRef.current))
            return;
        var isReadyToPlay = playerRef.current.readyState === 4;
        var isPaused = playerRef.current.paused;
        if (isFirstPlay && !loader && isPaused && isReadyToPlay) {
            setShowManualPlay(true);
        }
    }, [
        isFirstPlay,
        loader,
        (_b = playerRef === null || playerRef === void 0 ? void 0 : playerRef.current) === null || _b === void 0 ? void 0 : _b.paused,
        (_c = playerRef === null || playerRef === void 0 ? void 0 : playerRef.current) === null || _c === void 0 ? void 0 : _c.readyState,
    ]);
    useEffect(function () {
        if (!(playerRef === null || playerRef === void 0 ? void 0 : playerRef.current))
            return;
        var isReadyToPlay = playerRef.current.readyState > 2;
        // const isReadyToPlay = playerRef.current.readyState === 4
        if (isReadyToPlay) {
            stopLoader();
        }
    }, [(_d = playerRef === null || playerRef === void 0 ? void 0 : playerRef.current) === null || _d === void 0 ? void 0 : _d.readyState]);
    return (_jsxs(Container, { children: [_jsx(PlayerWrapper, { children: _jsxs("div", { style: {
                        width: '100%',
                        height: '100%',
                        position: 'absolute',
                        top: '0',
                        left: '0',
                    }, children: [_jsx("video", { id: PLAYER_ID, autoPlay: true, onWaiting: function () { setLoader(true); }, playsInline: true, onTimeUpdate: handleTimeUpdate, onCanPlay: stopLoader, onCanPlayThrough: stopLoader, onPlaying: stopLoader, 
                            // onLoadedMetadata={stopLoader}
                            // onLoadedMetadataCapture={stopLoader}
                            onErrorCapture: handleOnError, onStalledCapture: function () {
                                GAHandler('player_stalled', { uuid: uuid });
                            }, onPlay: handlePlay, onDurationChange: handleDuration, onEnded: onEnd, ref: playerRef || ref, preload: 'metadata', src: src, type: 'video/mp4', 
                            // hidden={cast.isRunning}
                            style: {
                                height: '100%',
                                width: '100%',
                            }, children: children }, uuid), showManualPlay && (_jsx("div", { style: {
                                position: 'absolute',
                                top: 0,
                                left: 0,
                                width: '100%',
                                height: '100%',
                                display: 'grid',
                                placeItems: 'center',
                                backgroundColor: 'rgba(0,0,0, .5)',
                                zIndex: 100,
                            }, children: _jsx("button", { onClick: handlePlay, style: {
                                    backgroundColor: 'rgba(255,255,255, .2)',
                                    borderRadius: '100%',
                                    padding: 30,
                                    display: 'grid',
                                    placeItems: 'center',
                                    cursor: 'pointer',
                                    border: 'none',
                                    color: 'white',
                                }, children: _jsx(PlaySVG, { height: 40, width: 40, style: { marginRight: -3 } }) }) }))] }) }), loader && (_jsx("div", { style: {
                    display: 'flex',
                    position: 'absolute',
                    top: 0,
                    left: 0,
                    width: '100%',
                    height: '100%',
                    justifyContent: 'center',
                    alignItems: 'center',
                    pointerEvents: 'none',
                }, children: _jsx(Lottie, { animationData: loaderJson, loop: true, style: { width: isMobileBig ? 50 : 100 } }) }))] }));
};
export default Player;
