import { useQuery } from '@tanstack/react-query';
import { useApi } from 'hooks';
var useGetCarousels = function (groupId) {
    var apiFetch = useApi().apiFetch;
    var url = "/carrusels?groupId=".concat(groupId);
    var getCarousels = function () {
        return apiFetch({ url: url })
            .then(function (res) { return res.data; });
    };
    var options = {
        staleTime: 60000 * 30, // 30 min
        enabled: !!groupId,
    };
    return useQuery(['carousels', groupId, 'lang'], getCarousels, options);
};
export default useGetCarousels;
