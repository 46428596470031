import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { ReactComponent as CloseSvg } from 'assets/icons/exit.svg';
import { ReactComponent as InfoSvg } from 'assets/icons/info.svg';
import { Modal } from 'templates';
import { Button, ModalPortal, Text } from 'ui';
import { GAHandler } from 'utils/GA';
var ModalContentTokens = function (props) {
    var close = props.close, tokenGates = props.tokenGates;
    var t = useTranslation().t;
    var haveNoTokens = tokenGates.every(function (tg) { return !tg.isAvailable; });
    return (_jsx(ModalPortal, { onBgClick: close, children: _jsxs(Modal, { padding: '24px', width: 500, children: [_jsx("div", { children: _jsxs("div", { style: { display: 'flex', alignItems: 'center', gap: 8 }, children: [_jsx(Text, { size: 18, weight: 600, color: 'headline', style: { flexGrow: 1 }, children: t('anteroom:tokenModal.title') }), _jsx(Button, { iconLeft: CloseSvg, iconSize: 16, size: 'small', variant: 'secondary', onClick: close })] }) }), tokenGates === null || tokenGates === void 0 ? void 0 : tokenGates.map(function (tg) { return (_jsxs("div", { style: {
                        display: 'flex',
                        alignItems: 'center',
                        gap: 8,
                        marginTop: 32,
                    }, children: [_jsx("img", { src: tg.contract.imageUrl, alt: tg.contract.name, title: tg.contract.name, style: {
                                height: 24,
                                width: 24,
                                objectFit: 'cover',
                                objectPosition: 'center',
                                borderRadius: 100,
                            } }), _jsxs(Text, { color: 'headline', style: { flexGrow: 1 }, children: [tg.contract.name, tg.requiredBalance > 1 && (_jsxs(Text, { as: 'span', children: [' ', "(x", tg.requiredBalance, ")"] }))] }), tg.contract.url && (_jsx("a", { href: tg.contract.url, target: '_blank', rel: 'noreferrer', onClick: function () {
                                GAHandler('tg_show_more_token', { token: tg });
                            }, children: _jsx(Button, { variant: 'flame-inverted', size: 'small', style: { padding: '0 8px', borderRadius: 4 }, children: t('common:showMore') }) }))] }, tg.uuid)); }), haveNoTokens && (_jsxs("div", { style: {
                        backgroundColor: '#FBBF2426',
                        padding: '12px 16px',
                        marginTop: 24,
                        display: 'flex',
                        gap: 12,
                        alignItems: 'center',
                        borderRadius: 8,
                    }, children: [_jsx(InfoSvg, { height: 24, width: 24, color: '#FBBF24', style: { flexShrink: 0 } }), _jsx(Text, { size: 14, weight: 500, color: 'headline', children: t('anteroom:tokenModal.warning') })] }))] }) }));
};
export default ModalContentTokens;
