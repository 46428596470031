import { useQuery } from '@tanstack/react-query';
import { useDebounce } from 'use-debounce';
import { useApi } from 'hooks';
import { GAHandler } from 'utils/GA';
var useSearch = function (props) {
    var _a = props.contentType, contentType = _a === void 0 ? 'all' : _a, _b = props.query, query = _b === void 0 ? '' : _b, _c = props.filters, filters = _c === void 0 ? '' : _c, _d = props.page, page = _d === void 0 ? '' : _d, _e = props.amountPerPage, amountPerPage = _e === void 0 ? '' : _e, _f = props.isEnabled, isEnabled = _f === void 0 ? false : _f, _g = props.delay, delay = _g === void 0 ? 300 : _g;
    var curentDelay = query ? delay : 0;
    var queryBounced = useDebounce(query, curentDelay)[0];
    var enabled = query
        ? !!queryBounced
        : isEnabled;
    var type = contentType === 'all'
        ? 'contentType=series&contentType=shorts&contentType=microshorts&contentType=movies'
        : "contentType=".concat(contentType);
    var url = '/search/contents?'
        + type
        + "&searchData=".concat(queryBounced)
        + "&filters=".concat(filters)
        + "&page=".concat(page)
        + "&amountPerPage=".concat(amountPerPage);
    var apiFetch = useApi().apiFetch;
    var search = function () {
        GAHandler('content_search');
        return apiFetch({ url: url })
            .then(function (res) { return res.data.contents; });
    };
    var options = {
        staleTime: 60000 * 30, // 30 min
        enabled: enabled,
    };
    var queryKey = [
        'search',
        contentType,
        queryBounced,
        filters,
        page,
        amountPerPage,
    ];
    return useQuery(queryKey, search, options);
};
export default useSearch;
