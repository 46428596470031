import styled from 'styled-components'

export const Container = styled.div`
  position: absolute;
  bottom: 90px;
  left: 50%;
  transform: translateX(-50%);
  width: fit-content;
  display: flex;
  justify-content:center;
  gap: 6px;
  z-index: 2;

  ${({ theme }) => theme.old.breakpoints.isTab} {
    bottom: 60px;
  }
  
  ${({ theme }) => theme.old.breakpoints.isMobile} {
    bottom: 10%;
  }
`

export const Dot = styled.div`
  height: 12px;
  width: 12px;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.old.colors.white};
  opacity: ${({ $isActive }) => $isActive ? 1 : 0.3};
  
  ${({ theme }) => theme.old.breakpoints.isMobile} {
    height: 10px;
    width: 10px;
  }
`
