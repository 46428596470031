import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { ReactComponent as OopsSVG } from 'assets/errors/oops.svg';
import { useResponsive } from 'hooks';
import { Button, Text } from 'ui';
import { Code, Container, } from './styles';
var ErrorCard = function (props) {
    var t = useTranslation().t;
    var _a = props.icon, Icon = _a === void 0 ? OopsSVG : _a, _b = props.title, title = _b === void 0 ? t('error:default.title') : _b, _c = props.description, description = _c === void 0 ? t('error:default.description') : _c, isRefresh = props.isRefresh, isBackHome = props.isBackHome, isRadiusOnlyBottom = props.isRadiusOnlyBottom, _d = props.code, code = _d === void 0 ? 'W1001' : _d, theme = props.theme, children = props.children, stack = props.stack;
    var isMobileBig = useResponsive().isMobileBig;
    var isCreators = theme === 'light';
    return (_jsxs(Container, { "$isCreators": isCreators, "$isRadiusOnlyBottom": isRadiusOnlyBottom, children: [_jsx(Icon, { width: isMobileBig ? 90 : 180, height: isMobileBig ? 90 : 180 }), _jsx(Text, { size: isMobileBig ? 14 : 16, style: { marginTop: 14 }, color: 'headline', weight: 'semibold', theme: isCreators ? 'light' : 'dark', children: title }), _jsx(Text, { size: isMobileBig ? 12 : 14, style: { marginTop: 10, maxWidth: '80%' }, theme: isCreators ? 'light' : 'dark', align: 'center', children: description }), code && (_jsx(Code, { "$isCreators": isCreators, style: {
                    borderRadius: isMobileBig ? 4 : 6,
                    padding: isMobileBig
                        ? '4px 8px'
                        : '6px 12px',
                }, children: _jsx(Text, { size: isMobileBig ? 'xs' : 'sm', weight: 'semibold', children: code }) })), _jsxs("div", { style: { marginTop: 16 }, children: [isRefresh && (_jsx(Button, { size: isMobileBig ? 'small' : 'medium', variant: 'text', onClick: function () { window.location.reload(); }, children: _jsx(Text, { color: 'inherit', size: isMobileBig ? 'sm' : 'base', children: t('retry') }) })), isBackHome && (_jsx(Button, { size: isMobileBig ? 'small' : 'medium', variant: 'text', to: '/', children: _jsx(Text, { color: 'inherit', size: isMobileBig ? 'sm' : 'base', children: t('goToHome') }) }))] }), children, stack && (_jsx("div", { style: {
                    height: 'fit-content',
                    maxHeight: 300,
                    width: '95%',
                    maxWidth: 550,
                    backgroundColor: '#111',
                    padding: 14,
                    borderRadius: 6,
                    textWrap: 'wrap',
                    overflowY: 'auto',
                    overflowX: 'hidden',
                    whiteSpace: 'pre',
                    wordBreak: 'break-all',
                    marginTop: 16,
                }, children: _jsx(Text, { size: 'xs', children: stack }) }))] }));
};
export default ErrorCard;
