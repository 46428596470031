import styled from 'styled-components'

export const Container = styled.div`
  height: fit-content;
  display: grid;
  place-items: center;

  svg {
    animation: spin 1s infinite linear;
  }

  @keyframes spin {
    0% {
      transform: rotateZ(0deg);
    }
    100% {
      transform: rotateZ(360deg);
    }
  }
`
