
export const isValid = {

  mobileNumber: (number) => {
    const re = new RegExp(/^\+?[1-9]\d{4,14}$/)
    return re.test(number)
  },

  smsCode: (code, length = 6) => {
    const re = new RegExp(`^[0-9]{${length}}$`)
    return re.test(code)
  },

  passwordLength: (pwd) => {
    return pwd.length >= 8 && pwd.length <= 30
  },

  passwordHasUpperCase: (pwd) => {
    return pwd !== pwd.toLowerCase()
  },

  passwordHasLowerCase: (pwd) => {
    return pwd !== pwd.toUpperCase()
  },

  passwordHasNumber: (pwd) => {
    const re = new RegExp('(?=.*?[0-9])')
    return re.test(pwd)
  },

  password: (pwd) => (
    isValid.passwordLength(pwd)
    && isValid.passwordHasLowerCase(pwd)
    && isValid.passwordHasUpperCase(pwd)
    && isValid.passwordHasNumber(pwd)
  ),

  usernameHasLetter: (username) => {
    const re = new RegExp('[a-zA-Z]')
    return re.test(username)
  },

  usernameCharacters: (username) => {
    const re = new RegExp('^[a-zA-Z0-9_]{0,}$')
    return re.test(username)
  },

  usernameLength: (username) => {
    const re = new RegExp('^(.){3,16}$')
    return re.test(username)
  },

  email: (email) => {
    const re = new RegExp('^(.){2,}@(.+)[.](.){2,}$')
    return re.test(email)
  },
}
