import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { Modal } from 'templates';
import Button from 'ui/Button';
import { ReactComponent as CloseSVG } from 'assets/icons/exit.svg';
import { ReactComponent as GooglePlaySVG } from 'assets/stores/google-play.svg';
import { ReactComponent as AppStoreSVG } from 'assets/stores/app-store.svg';
import { ReactComponent as TrophySVG } from 'assets/images/flixx/trophy.svg';
import qrImg from 'assets/stores/qr-code.png';
import bgImg from 'assets/images/modals/bg.png';
import { useResponsive } from 'hooks';
import Text from 'ui/Text';
import { APPLE_STORE_URL, PLAY_STORE_URL } from 'config/constants';
import { GAHandler } from 'utils/GA';
var BgDesktop = function () { return (_jsx("div", { style: {
        position: 'absolute',
        top: 0,
        left: 0,
        background: "url(".concat(bgImg, ")"),
        backgroundSize: 'auto 100%',
        backgroundPosition: 'center right',
        backgroundRepeat: 'no-repeat',
        width: '100%',
        height: '100%',
    } })); };
var BgResponsive = function () { return (_jsx("div", { style: {
        position: 'absolute',
        top: 0,
        left: 0,
        background: 'radial-gradient(#2B2157, transparent)',
        backgroundSize: '100%',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
        width: '100%',
        height: '100%',
    } })); };
var ModalDownloadApp = function (props) {
    var close = props.close, title = props.title, description = props.description;
    var t = useTranslation().t;
    var isMobileBig = useResponsive().isMobileBig;
    var handleClick = function (store) {
        GAHandler("ui_store_".concat(store));
    };
    if (isMobileBig) {
        return (_jsxs(Modal, { padding: '36px 16px 24px', renderBg: BgResponsive, children: [_jsx("div", { style: {
                        position: 'absolute',
                        top: 16,
                        right: 16,
                    }, children: _jsx(Button, { variant: 'tertiary', iconLeft: CloseSVG, size: 'small', onClick: close }) }), _jsx("div", { style: {
                        width: 72,
                        height: 72,
                        borderRadius: 100,
                        backgroundColor: '#A1A1AA',
                        margin: '0 auto',
                        display: 'grid',
                        placeItems: 'center',
                    }, children: _jsx(TrophySVG, { width: 32, height: 32 }) }), _jsx("div", { style: { marginTop: 16 }, children: _jsx(Text, { size: 'xl', align: 'center', color: 'headline', children: title }) }), _jsxs("div", { style: { marginTop: 16 }, children: [_jsx(Text, { align: 'center', children: description }), _jsx(Text, { align: 'center', color: 'headline', children: t('modals:downloadApp.getTheMostOut') })] }), _jsxs("div", { style: {
                        display: 'flex',
                        gap: 38,
                        justifyContent: 'center',
                        marginTop: 24,
                    }, children: [_jsx("a", { href: PLAY_STORE_URL, target: '_blank', rel: 'noopener noreferrer', onClick: function () { handleClick('android'); }, onAuxClick: function () { handleClick('android'); }, children: _jsx(GooglePlaySVG, {}) }), _jsx("a", { href: APPLE_STORE_URL, target: '_blank', rel: 'noopener noreferrer', onClick: function () { handleClick('apple'); }, onAuxClick: function () { handleClick('apple'); }, children: _jsx(AppStoreSVG, { width: 107, height: 36 }) })] })] }));
    }
    return (_jsxs(Modal, { width: 795, height: 287, renderBg: BgDesktop, children: [_jsx("div", { style: {
                    position: 'absolute',
                    top: 24,
                    right: 24,
                }, children: _jsx(Button, { variant: 'secondary', iconLeft: CloseSVG, size: 'medium', onClick: close }) }), _jsxs("div", { style: {
                    marginTop: 40,
                    marginLeft: 40,
                }, children: [_jsx("div", { style: { width: 415 }, children: _jsx(Text, { size: '2xl', color: 'headline', children: title }) }), _jsxs("div", { style: { width: 378, marginTop: 16, marginBottom: 20 }, children: [_jsx(Text, { children: description }), _jsx(Text, { color: 'headline', children: t('modals:downloadApp.getTheMostOut') })] }), _jsxs("div", { style: { display: 'flex', gap: 24 }, children: [_jsx("a", { href: PLAY_STORE_URL, target: '_blank', rel: 'noopener noreferrer', onClick: function () { handleClick('android'); }, onAuxClick: function () { handleClick('android'); }, children: _jsx(GooglePlaySVG, {}) }), _jsx("a", { href: APPLE_STORE_URL, target: '_blank', rel: 'noopener noreferrer', onClick: function () { handleClick('apple'); }, onAuxClick: function () { handleClick('apple'); }, children: _jsx(AppStoreSVG, {}) })] })] }), _jsx("div", { style: {
                    position: 'absolute',
                    bottom: 40,
                    right: 24,
                    borderRadius: 6,
                    overflow: 'hidden',
                    display: 'grid',
                }, children: _jsx("img", { src: qrImg, width: 80, height: 80, alt: '' }) })] }));
};
export default ModalDownloadApp;
