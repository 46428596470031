import { useContext } from 'react'
import { createPortal } from 'react-dom'

import { snackbarContext } from 'providers/snackbar'

import { Container, SnackWrapper } from './styles'
import Error from './Error'


const Snackbar = () => {
  const root = document.getElementById('snackbar')
  const { data } = useContext(snackbarContext)

  const { isOpen, message, icon } = data

  const ModalComponent = (
    <Container>
      <SnackWrapper $variant={'error'}>
        <Error icon={icon}>
          {message}
        </Error>
      </SnackWrapper>
    </Container>
  )

  return createPortal(isOpen ? ModalComponent : null, root)
}

export default Snackbar
