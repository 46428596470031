import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTheme } from 'styled-components';
import { useResponsive } from 'hooks';
import useCarouselSlides from 'hooks/useCarouselSlides';
import { CarouselBase, CarouselButtons, Text, } from 'ui';
var CarouselFullWidth = function (props) {
    var title = props.title, children = props.children, isLoading = props.isLoading, isError = props.isError, isVertical = props.isVertical;
    var slidesPerView = useCarouselSlides({ preset: isVertical ? 'poster' : 'cover' });
    var _a = useResponsive(), isTab = _a.isTab, isMobile = _a.isMobile;
    var theme = useTheme();
    // const {
    //   isLoading,
    //   isError,
    //   data = []
    // } = fetchFn(uuid, true)
    if (isError) {
        return (_jsx("div", { children: "ERROR!" }));
    }
    // if (hideUntilData && !data.length) return null
    return (_jsxs("div", { style: { position: 'relative', zIndex: 20000000000000, margin: isTab ? 0 : "0 ".concat(theme.old.sizes.sidesMargin.large) }, children: [_jsx("div", { style: {
                    margin: isTab
                        ? "40px ".concat(theme.old.sizes.sidesMargin.small, " 15px")
                        : '40px 0 15px',
                }, children: _jsx(Text, { size: isMobile ? 'base' : 'xl', color: 'headline', weight: 'semibold', children: title }) }), _jsx(CarouselBase, { slidesPerView: slidesPerView, offsetRight: isTab ? 16 : 0, rewind: true, renderNavigationBtns: CarouselButtons, isLoading: isLoading, children: children }, 'content')] }));
};
export default CarouselFullWidth;
