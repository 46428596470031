import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Fragment } from 'react';
import { Skeleton, Text } from 'ui';
import { useResponsive } from 'hooks';
import { DividerRootContainer, DividerStyled, } from './styles';
var Root = function (_a) {
    var children = _a.children, isLoading = _a.isLoading;
    var items = Array.isArray(children)
        ? children
        : [children];
    if (isLoading) {
        return (_jsx(DividerRootContainer, { children: _jsx(Skeleton, { style: {
                    height: 20,
                    width: '50%',
                    borderRadius: 4,
                } }) }));
    }
    return (_jsx(DividerRootContainer, { children: items.filter(function (child) { return !!child; }).map(function (child, idx) { return (_jsxs(Fragment, { children: [idx !== 0 && _jsx(DividerStyled, {}), child] }, idx)); }) }));
};
var Child = function (_a) {
    var children = _a.children;
    var isMobileBig = useResponsive().isMobileBig;
    return (_jsx(Text, { size: isMobileBig ? 'sm' : 'base', color: 'headline', children: children }));
};
var Divider = { Root: Root, Child: Child };
export default Divider;
