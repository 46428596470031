import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useSession } from 'hooks';
import { Home } from 'pages';
import { Spinner } from 'ui';
var Vas = function () {
    var _a = useParams(), _b = _a.provider, provider = _b === void 0 ? 'faro' : _b, token = _a.token;
    var navigate = useNavigate();
    var _c = useSession(), isLogging = _c.isLogging, loginVas = _c.loginVas, isLoggedIn = _c.isLoggedIn;
    useEffect(function () {
        loginVas({ provider: provider, token: token });
        document.body.style.overflow = 'hidden';
        return function () {
            document.body.style.overflow = 'visible';
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    useEffect(function () {
        if (!isLoggedIn)
            return;
        navigate('/', { replace: true });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isLoggedIn]);
    return (_jsxs(_Fragment, { children: [isLogging && (_jsx("div", { style: {
                    position: 'fixed',
                    top: 0,
                    left: 0,
                    bottom: 0,
                    right: 0,
                    backgroundColor: 'rgba(0,0,0, .4)',
                    zIndex: 99,
                    display: 'grid',
                    placeItems: 'center',
                }, children: _jsx(Spinner, { size: 50 }) })), _jsx(Home, {})] }));
};
export default Vas;
