var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import { isMobile } from 'react-device-detect';
import styled, { css } from 'styled-components';
var baseStyles = css(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  display: flex;\n  flex-direction: column;\n  height: 100%;\n  width: 100%;\n\n  border: 1px solid #3F3F46;\n  border-radius: 12px;\n  background-color: ", ";\n\n  transition: all 200ms ease;\n"], ["\n  display: flex;\n  flex-direction: column;\n  height: 100%;\n  width: 100%;\n\n  border: 1px solid #3F3F46;\n  border-radius: 12px;\n  background-color: ", ";\n\n  transition: all 200ms ease;\n"])), function (_a) {
    var $theme = _a.$theme, $variant = _a.$variant;
    return $theme.card[$variant].default.background;
});
export var BaseCard = styled.article(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n  ", "\n"], ["\n  ", "\n"])), baseStyles);
export var InteractiveCard = styled.article(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  ", "\n\n  cursor: pointer;\n\n  ", "\n\n  &:hover {\n   ", "\n  }\n"], ["\n  ", "\n\n  cursor: pointer;\n\n  ", "\n\n  &:hover {\n   ", "\n  }\n"])), baseStyles, function (_a) {
    var $theme = _a.$theme, $variant = _a.$variant, $isSelected = _a.$isSelected;
    return $isSelected && ({
        // backgroundColor: $theme.card[$variant].focus.background,
        border: '1px solid #FFFFFF',
    });
}, function (_a) {
    var $isSelected = _a.$isSelected;
    return !isMobile && !$isSelected && ({
        border: '1px solid #71717A',
    });
});
var templateObject_1, templateObject_2, templateObject_3;
