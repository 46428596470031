var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import React from 'react';
import { Container } from './styles';
var Modal = function (props) {
    var children = props.children, width = props.width, height = props.height, padding = props.padding, _a = props.borderRadius, borderRadius = _a === void 0 ? 24 : _a, RenderBg = props.renderBg, modalStyle = props.modalStyle, containerStyle = props.containerStyle;
    return (_jsxs(Container, { "$width": width, "$height": height, "$borderRadius": borderRadius, style: modalStyle, children: [RenderBg && _jsx(RenderBg, {}), _jsx("div", { style: __assign({ position: 'relative', display: 'flex', flexDirection: 'column', flexGrow: 1, padding: padding, overflow: 'auto' }, containerStyle), children: children })] }));
};
export default Modal;
