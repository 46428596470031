import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ReactComponent as TriangleSvg } from 'assets/icons/triangle-rounded.svg';
import { CopiedMessage, CopiedWrapper } from './styles';
var TooltipAction = function (props) {
    var t = useTranslation().t;
    var children = props.children, _a = props.message, message = _a === void 0 ? t('common:linkCopied') : _a;
    var _b = useState(false), isVisible = _b[0], setIsVisible = _b[1];
    var show = function () { setIsVisible(true); };
    var hide = function () { setIsVisible(false); };
    useEffect(function () {
        if (!isVisible)
            return;
        setTimeout(function () {
            hide();
        }, 3000);
    }, [isVisible]);
    return (_jsxs("div", { style: { position: 'relative', display: 'flex' }, children: [typeof children === 'function'
                ? children({ show: show, hide: hide })
                : children, isVisible && (_jsxs(CopiedWrapper, { children: [_jsx(CopiedMessage, { children: message }), _jsx(TriangleSvg, { color: '#064E3B', style: {
                            position: 'absolute',
                            bottom: '105%',
                            left: '50%',
                            transform: 'translate(-50%)',
                        } })] }))] }));
};
export default TooltipAction;
