var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { browserName } from 'react-device-detect';
import videojs from 'video.js';
import { PLAYER_ID } from 'config/constants';
import { environment } from 'config/environment';
import 'videojs-contrib-eme';
function isWidevineSupported() {
    var _a;
    // const video = document.createElement('video')
    // return video.canPlayType('video/mp4; codecs="avc1.64001E, mp4a.40.2"') !== ''
    //   && !!navigator.requestMediaKeySystemAccess
    return (_a = navigator.requestMediaKeySystemAccess) === null || _a === void 0 ? void 0 : _a.call(navigator, 'com.widevine.alpha', [
        { initDataTypes: ['cenc'], videoCapabilities: [{ contentType: 'video/mp4; codecs="avc1.64001E"' }] },
    ]).then(function () { return true; }).catch(function () { return false; });
}
function isPlayReadySupported() {
    var _a;
    return (_a = navigator.requestMediaKeySystemAccess) === null || _a === void 0 ? void 0 : _a.call(navigator, 'com.microsoft.playready', [
        { initDataTypes: ['cenc'], audioCapabilities: [{ contentType: 'audio/mp4; codecs="mp4a.40.2"' }] },
    ]).then(function () { return true; }).catch(function () { return false; });
}
function isFairPlaySupported() {
    var _a;
    return (_a = navigator.requestMediaKeySystemAccess) === null || _a === void 0 ? void 0 : _a.call(navigator, 'com.apple.fps.1_0', [
        { initDataTypes: ['cenc'], videoCapabilities: [{ contentType: 'video/mp4; codecs="avc1.64001E"' }] },
    ]).then(function () { return true; }).catch(function () { return false; });
}
function getDrmType() {
    return __awaiter(this, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, isWidevineSupported()];
                case 1:
                    if (_a.sent()) {
                        if (!environment.isProduction) {
                            console.log("Drm: Widevine supported (".concat(browserName, ")"));
                        }
                        return [2 /*return*/, 'Widevine'];
                    }
                    return [4 /*yield*/, isPlayReadySupported()];
                case 2:
                    if (_a.sent()) {
                        if (!environment.isProduction) {
                            console.log("Drm: PlayerReady supported (".concat(browserName, ")"));
                        }
                        return [2 /*return*/, 'PlayReady'];
                    }
                    return [4 /*yield*/, isFairPlaySupported()];
                case 3:
                    if (_a.sent()) {
                        if (!environment.isProduction) {
                            console.log("Drm: FairPlay supported (".concat(browserName, ")"));
                        }
                        return [2 /*return*/, 'FairPlay'];
                    }
                    if (!environment.isProduction) {
                        console.log("Drm: No support (".concat(browserName, ")"));
                    }
                    return [2 /*return*/];
            }
        });
    });
}
export function isDrmSupported() {
    return __awaiter(this, void 0, void 0, function () {
        var supportedDrm, drm;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    supportedDrm = ['Widevine'];
                    return [4 /*yield*/, getDrmType()];
                case 1:
                    drm = _a.sent();
                    if (!drm)
                        return [2 /*return*/, false];
                    return [2 /*return*/, supportedDrm.includes(drm)];
            }
        });
    });
}
function getPlayerConfig(props) {
    var type = props.type, manifest = props.manifest, licenseUri = props.licenseUri, token = props.token;
    var config;
    if (type === 'FairPlay') {
        // config = {
        //     src: hlsUri,
        //     type: 'application/x-mpegurl',
        //     keySystems: {
        //         'com.apple.fps.1_0': {
        //             getCertificate: function (emeOptions, callback) {
        //                 videojs.xhr({
        //                     url: fairplayCertUri,
        //                     method: 'GET',
        //                 }, function (err, response, responseBody) {
        //                     if (err) {
        //                         callback(err)
        //                         return
        //                     }
        //                     callback(null, base64DecodeUint8Array(responseBody));
        //                 })
        //             },
        //             getContentId: function (emeOptions, initData) {
        //                 const contentId = arrayToString(initData);
        //                 return contentId.substring(contentId.indexOf('skd://') + 6);
        //             },
        //             getLicense: function (emeOptions, contentId, keyMessage, callback) {
        //                 videojs.xhr({
        //                     url: licenseUri,
        //                     method: 'POST',
        //                     responseType: 'text',
        //                     body: 'spc=' + base64EncodeUint8Array(keyMessage),
        //                     headers: {
        //                         'Content-type': 'application/x-www-form-urlencoded',
        //                         'pallycon-customdata-v2': fairplayToken
        //                     }
        //                 }, function (err, response, responseBody) {
        //                     if (err) {
        //                         callback(err)
        //                         return
        //                     }
        //                     callback(null, base64DecodeUint8Array(responseBody))
        //                 })
        //             }
        //         }
        //     }
        // };
    }
    if (type === 'PlayReady') {
        config = {
            src: manifest,
            type: 'application/dash+xml',
        };
        config.keySystemOptions = [];
        config.keySystemOptions.push({
            name: 'com.microsoft.playready',
            options: {
                serverURL: licenseUri,
                httpRequestHeaders: {
                    'pallycon-customdata-v2': token,
                },
            },
        });
    }
    if (type === 'Widevine') {
        config = {
            src: manifest,
            type: 'application/dash+xml',
            keySystems: {
                'com.widevine.alpha': licenseUri,
            },
        };
    }
    if (config) {
        return config;
    }
    console.log('No DRM supported in this browser');
}
function processVideo(manifest, token, onError) {
    return __awaiter(this, void 0, void 0, function () {
        var drmType, licenseUri, players, player, playerConfig;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0: return [4 /*yield*/, getDrmType()];
                case 1:
                    drmType = _a.sent();
                    if (!drmType)
                        return [2 /*return*/];
                    licenseUri = "https://license-global.pallycon.com/ri/licenseManager.do?pallycon-customdata-v2=".concat(token);
                    players = videojs.getAllPlayers();
                    players.forEach(function (eachPlayer) { return eachPlayer.dispose(); });
                    player = videojs(PLAYER_ID);
                    window.widevineToken = token;
                    if (typeof player.eme === 'function') {
                        // initialize videojs-contrib-eme for DRM
                        player.eme();
                    }
                    playerConfig = getPlayerConfig({
                        type: drmType,
                        licenseUri: licenseUri,
                        manifest: manifest,
                        token: token,
                    });
                    player.src(playerConfig);
                    if (onError) {
                        player.on('error', onError);
                    }
                    window.playerDRM = player;
                    return [2 /*return*/];
            }
        });
    });
}
var useVideojsSamples = function () {
    return { processVideo: processVideo };
};
export default useVideojsSamples;
