import useShowCarouselButtons from 'hooks/useShowCarouselButtons'

import { ButtonNext, ButtonPrev } from './styles'

const CarouselButtons = ({
  classPrev,
  classNext,
  nextSlide,
  prevSlide,
  isAbsolute,
}) => {
  const { showNavigationBtns } = useShowCarouselButtons()

  if (!showNavigationBtns) return null

  return (
    <>
      <ButtonPrev
        className={classPrev}
        onClick={prevSlide}
        $isAbsolute={isAbsolute}
      />
      <ButtonNext
        className={classNext}
        onClick={nextSlide}
        $isAbsolute={isAbsolute}
      />
    </>
  )
}

export default CarouselButtons
