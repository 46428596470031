var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { Text } from 'ui';
import { formatAmount } from 'utils/amounts';
import { ReactComponent as FlixxCoinSVG } from 'assets/brand/flixx-coin-3d.svg';
var SIZES = {
    sm: 16,
    base: 20,
    xl: 24,
    '2xl': 28,
};
var Flixx = function (props) {
    var price = props.price, _a = props.size, size = _a === void 0 ? 'base' : _a, sizeIcon = props.sizeIcon, textSize = props.textSize, textWeight = props.textWeight, theme = props.theme, children = props.children, _b = props.showIcon, showIcon = _b === void 0 ? true : _b, styleContainer = props.styleContainer;
    var currentSize = SIZES[size];
    return (_jsxs("span", { style: __assign({ display: 'inline-flex', alignItems: 'center', height: currentSize, gap: 2 }, styleContainer), children: [showIcon && (_jsx("span", { style: { display: 'grid', marginBottom: 1 }, children: _jsx(FlixxCoinSVG, { height: sizeIcon || currentSize, width: sizeIcon || currentSize }) })), !!price && (_jsxs(Text, { theme: theme, as: 'span', size: textSize || size, color: 'headline', weight: textWeight, children: [formatAmount(price), children] }))] }));
};
export default Flixx;
