import { useLayoutEffect, useState } from 'react'
import { isDesktop as isDesktopDevice } from 'react-device-detect'

import { useResponsive } from 'hooks'

const useShowCarouselButtons = () => {
  const [showNavigationBtns, setShowNavigationBtns] = useState()

  const { isTab } = useResponsive()

  useLayoutEffect(() => {
    const showBtns = isDesktopDevice && !isTab
    setShowNavigationBtns(showBtns)
  }, [isTab])

  return { showNavigationBtns }
}

export default useShowCarouselButtons
