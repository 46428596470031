const es = {
  common: {
    value: 'Valor',
    ok: 'Entendido',
    needHelp: '¿Necesitás ayuda?',
    contents: 'Contenidos',
    all: 'Todo',
    microseries_one: 'Microserie',
    microseries_other: 'Microseries',
    shorts_one: 'Corto',
    shorts_other: 'Cortos',
    movies_one: 'Película',
    movies_other: 'Películas',
    microshorts_one: 'Microcorto',
    microshorts_other: 'Microcortos',
    following: 'Siguiendo',
    follow: 'Seguir',
    cancel: 'Cancelar',
    close: 'Cerrar',
    continue: 'Continuar',
    logout: 'Cerrar sesión',
    save: 'Guardar',
    confirm: 'Confirmar',
    edit: 'Editar',
    seasons_short: 'T',
    seasons_one: 'Temporada',
    seasons_other: 'Temporadas',
    episodes_short: 'E',
    episodes_one: 'Episodio',
    episodes_other: 'Episodios',
    creator: 'Creador',
    watchNow: 'Ver ahora',
    back: 'Atrás',
    finish: 'Finalizar',
    linkCopied: '¡Link copiado!',
    support_creators: 'Apoyar',
    new: 'Nuevo',
    home: 'Inicio',
    conecting: 'Conectando',
    showMore: 'Ver más',
    none: 'Ninguno',
    subtitles: 'Subtítulos',
    createdBy: 'de',
    sec: 'seg',
    share: 'Compartir',
    status: {
      pending: 'Pendiente',
      confirmed: 'Confirmado',
      cancelled: 'Cancelado',
    },
    gender: {
      male: 'Hombre',
      female: 'Mujer',
      other: 'Otro',
    },
  },
  vouchers: {
    link: 'Canjeá tu voucher',
    title: 'Vouchers',
    description: {
      default: 'Ingresá tu voucher y obtené el contenido asociado',
      owned: 'Los contenidos canjeados están ahora en tu cuenta de manera permanente',
    },
    redeem: 'Canjear',
    success: '¡Canjeaste tu voucher y recibiste contenido!',
    errors: {
      invalid: 'El voucher no es válido. Puede ser que esté vencido, que ya se haya utilizado, o que el contenido todavía no esté disponible. Probá mas tarde o consultános a info@flixxo.com',
      owned: 'Ya posees el contenido asociado a este voucher',
    },
  },
  ads: {
    error: 'No se pudo cargar el anuncio. Intenta de nuevo más tarde.',
  },
  profile: {
    title: 'Editar perfil',
    description: 'Modifica tu apodo, imagen de perfil y la bio que quieres mostrar',
    nickname: 'Tu apodo',
    nicknameValidation: 'Entre 3 y 16 caracteres, con al menos 1 letra',
    bioPlaceholder: 'Comparte algo sobre ti...',
    optionsTitle: 'Editar foto de perfil',
    optionsChoose: 'Elegir desde dispositivo',
    optionsTake: 'Tomar foto',
    optionsDelete: 'Eliminar foto',
    takeDescription: 'Tomate una foto para colocar de foto de perfil',
    confirmTitle: 'Confirmar foto de perfil',
    confirmDescription: 'Antes, ajustá la imagen como más te guste. Si no estás conforme podes tomar otra.',
    confirmReTake: 'Tomar otra foto',
    toastSuccess: 'Los cambios se guardaron exitosamente',
    toastError: 'No se pudieron guardar los cambios, intente nuevamente más tarde',
    toastSuccessError: 'Algunos cambios no se pudieron guardar, intente nuevamente más tarde',
    toastNickname: 'El apodo que ingresaste ya está registrado.',
  },
  favorites: {
    link: 'Creadores favoritos',
    title: 'Tus creadores favoritos',
    placeholder: 'Buscar...',
    empty: {
      title: 'Elegí tus creadores favoritos',
      description: 'Desde la antesala de un contenido podés ver a su creador y marcarlo como favorito para no perderte nada.',
    },
  },
  userInfo: {
    title: 'Información personal',
    description: 'Modifica tus datos personales que no se visualizan en tu Perfil',
    message: 'Para mejorar tu experiencia en Flixxo, te pedimos que completes estos datos.',
    fullname: 'Nombre y Apellido',
    birthdate: 'Fecha de nacimiento',
    gender: 'Género',
    country: 'País de residencia',
    successMsg: 'Los cambios se guardaron exitosamente',
  },
  challenges: {
    inviteMsg: '¡Te invito a Flixxo! Descubrí las mejores microseries y cortometrajes. Registrate desde el link y ganá tus primeros Flixx: play.flixxo.com/referrer/{{nickname}}',
    title: 'Desafíos',
    description: 'Ganá Flixx completando desafíos',
    availables: 'Disponibles',
    completed: 'Cumplidos',
    invite: 'Invitar',
    claim: 'Reclamar',
    claimed: 'Reclamado',
    newRegisters_zero: 'No hay nuevos registros',
    newRegisters_one: '1 nuevo registro',
    newRegisters_other: '{{count}} nuevos registros',
    watchVideo: 'Ver video',
    emptyMsg: 'Aún no tenés desafíos cumplidos :(',
    toast: {
      button: 'Ver desafíos',
      message: {
        pending: '¡Tenés recompensas para reclamar!',
        new: '¡Cumpliste un nuevo desafío!',
      },
    },
  },
  search: {
    placeholder: 'Buscar...',
    results: 'Tus resultados para: ',
    notFound_title: 'No encontramos resultados :(',
    notFound_opt_1: 'Intentá buscar otra microserie o corto',
    notFound_opt_2: 'Modificá los filtros de tipo de contenido',
    notFound_opt_3: 'Utilizá palabras claves',
    notFound_opt_4: 'Ingresá el nombre de actores o géneros',
    moods: {
      title: 'Busca contenido por tu estado de ánimo',
      cry: 'Llorar',
      love: 'Enamorarse',
      laugh: 'Reír',
      imagine: 'Imaginar',
      shout: 'Gritar',
      think: 'Pensar',
    },
  },
  content: {
    allContents: 'Todo el contenido',
    onlyMicroseries: 'Sólo microseries',
    onlyShorts: 'Sólo cortos',
    onlyMovies: 'Sólo películas',
    onlyMicroshorts: 'Sólo microcortos',
  },
  creators: {
    seeViewBy: 'Ver vista por:',
    balance: {
      quote: 'Cotización del Flixx',
      value: 'Valor de tus Flixx',
      withdraw: 'Retirar Flixx',
      withdrawals: 'Estado de retiros',
    },
    modalWithdraw: {
      title: 'Retirar Flixx',
      description: 'Enviá facilmente a tu wallet los Flixx que tengas acumulados',
      step1: {
        connectWallet: 'Conectar wallet',
        walletConnected: 'Wallet conectada',
        disconnect: 'Desconectar',
        howMany: '¿Cuánto Flixx querés retirar?',
        available: 'Disponible',
        withdrawTotal: 'Retirar total',
        minLimit: 'Monto mínimo a retirar 1,000 Flixx',
        maxLimit: 'Monto máximo a retirar 100,000 Flixx',
      },
      step2: {
        summary: 'Resumen del envío',
      },
      step3: {
        title: '¡Retiro de Flixx realizado con éxito!',
      },
      info: {
        amount: 'Flixx a enviar',
        wallet: 'Wallet de destino',
        gas: 'El <strong>GAS</strong> de la transacción corre por cuenta nuestra ;)',
        keepInMind: 'Tené en cuenta lo siguiente:',
        bullet1: 'Dentro de las 72 horas hábiles te recibirás por mail la confirmación de la operación.',
        bullet2: 'Mientras hacemos la transferencia por el monto de Flixx, no lo verás en tu saldo.',
        bullet3: 'El seguimiento del estado de la transacción, lo verás desde <btn>estado de retiros.</btn>',
      },
    },
    modalWithdrawals: {
      title: 'Estado de retiros',
      description: 'Mirá el estado de los retiros de Flixx realizados',
      dateTime: 'FECHA Y HORA',
      details: 'DETALLES DE RETIRO',
      status: 'ESTADO',
      flixxTitle: 'Flixx retirados',
    },
  },
  modalOnboarding: {
    title: '¡Un regalito de bienvenida!',
    description: 'Te hicimos un pequeño video para que le saques el máximo provecho a Flixxo. Además, cuando finalice, obtendrás Flixx.',
    cta: 'Ver video de bienvenida',
  },
  lang: {
    changeMessage: 'Adaptamos el contenido al idioma seleccionado.',
  },
  anteroom: {
    loginToWatch: 'Ingresá a Flixxo para ver el contenido',
    watchFor: 'Ver por <0></0>',
    watchFor_female: 'Mirala por <0></0>',
    watchFor_male: 'Miralo por <0></0>',
    similar: 'Contenidos similares',
    adMsg: 'Se mostrará un anuncio y ganarás Flixx',
    creatorPayment: '<yellow>¡{{creator}}</yellow> recibió tus Flixx!',
    keepGoingWith: 'Ahora seguí con…',
    seen: 'Visto',
    unsupportedByDRM: 'Navegador no soportado',
    withFlixx: 'CON FLIXX',
    withToken: 'TOKEN GATING / TICKET 3.0',
    downloadApp: 'Descargá la app para ingresar Flixx',
    haveTheFlixx: '¡Tienes los Flixx necesarios!',

    tokenModal: {
      title: 'Accedé al contenido con:',
      warning: 'Debes tener al menos un token/ticket 3.0 en tu wallet para poder acceder al contenido.'
    },
    series: {
      watchNextEp: 'Ver próximo episodio',
    },
    postroll: {
      title: '¡Algo de esto\n te va a gustar!',
    },
    error: {
      retrieving: {
        title: 'Error al obtener el contenido',
      },
      buyContent: 'No se pudo realizar la compra del contenido',
    },
  },
  error: {
    default: {
      title: 'Algo salió mal',
      description: 'Hubo un error inesperado. Recargá la página y reintentalo.',
    },
    player: {
      playback: {
        title: 'Error de reproducción',
        description: 'Hubo un error en la reproducción del contenido',
      },
    },
    api: {
      unexpected: 'Hubo un error inesperado. Recargá la página y reintentalo.',
      tooManyRequests: 'Demasiados intentos. Probá ingresar más tarde o comunicate con nosotros.',
      humanVerification: 'No podemos completar tu solicitud. Probá ingresar más tarde o comunicate con nosotros.',
    },
  },
  modals: {
    downloadApp: {
      getTheMostOut: '¡Descubrí cómo aprovechar Flixxo al máximo!',
      fullExperience: {
        title: '¡Descargá la App de Flixxo!',
        description: '¡Disfrutá de todas las microseries y cortometrajes en cualquier momento y lugar!',
      },
      buyFlixxApp: {
        title: '¡Descargá la app y adquirí Flixx!',
        description: 'En la app podés aprovechar distintas ofertas de Flixx que tenemos para vos.',
      },
      wallet: {
        title: '¡Descargá la app para donar flixx a tus creadores favoritos!',
        description: 'Desde la app contás con una wallet para poder transferir flixx a creadores y usuarios',
      },
    },
    welcome: {
      title: '¡Registrate y te regalamos Flixx!',
      description: 'El mejor contenido independiente de creadores de todo el mundo',
      enterWith: 'Ingresá con',
      alsoYouCan: 'O también podés',
      prepaid: '¿Tenés cuenta Flixxo prepago?',
    },
    register: {
      nickname: 'Apodo',
      validation: 'Tu apodo debe tener entre 3 y 16 caracteres, con al menos una letra.',
      terms: 'Al continuar confirmás nuestros <0>términos y condiciones</0>, las <1>políticas de privacidad</1> y que sos mayor de 13 años.',
      haveAccount: '¿Ya tenes cuenta? <0>Iniciá sesión</0>',
      enterEmail: 'Ingresá un e-mail y creá tu apodo',
    },
    creatorProfile: {
      content: 'Contenido del creador',
      notFound: 'No encontramos creadores asociados a este link',
    },
    deleteAccount: {
      title: '¿Querés eliminar tu cuenta?',
      disclaimer: 'Perderás tus Flixx, credenciales y contenidos',
      check: 'Acepto eliminar mi cuenta',
      accept: 'Eliminar Cuenta',
      toastMessage: 'Tu solicitud de eliminación de cuenta ha sido registrada exitosamente',
    },
    referred: {
      flow: 'Referidos',
      title: '¿Te invitaron a Flixxo?',
      description: 'Ingresá el apodo de quién te invitó y <0>ambos ganan Flixx.</0>',
      placeholder: 'Apodo de quién te invitó',
      cta: 'Validar invitación',
      cta_loading: 'Validando invitación...',
      skip: 'Continuar sin invitación',
      error: 'Los datos que ingresaste no corresponden al apodo de un usuario.',
      toastSuccess: 'Apodo de referido validado con éxito',
    },
  },
  moreFlixx: {
    title: '¿Querés más Flixx?',
    ad: '¡Mirá un anuncio y ganá Flixx!',
    invite: 'Invitá a quién quieras y ¡ambos ganan Flixx!',
    inviteToast: '¡Se copió el link correctamente!\nCompartilo con las personas que querés que sean parte de Flixxo',
  },
  ad: {
    message: 'Ganando flixx...',
  },
  adblock: {
    title: 'Tenés activado tu\nbloqueador de anuncios',
    description: 'Los anunciantes nos ayudan a que el contenido sea gratis para vos. Desactivá el bloqueador y recargá la página.',
  },
  footer: {
    aboutUs: 'Acerca de nosotros',
    flixx: '¿Qué es un Flixx?',
    terms: 'Términos y condiciones',
    privacy: 'Políticas de Privacidad',
    business: 'Empresa',
    contactUs: 'Contactate con nosotros',
  },
  flixx: {
    title: 'Flixx es la moneda de Flixxo',
    description: 'Con el Flixx vas a poder ver microseries, cortometrajes y bancar a los creadores. Tomamos prestada tecnología del Bitcoin para desarrollar nuestra propia criptomoneda y asegurarnos una monetización justa y transparente. Los Flixx son tuyos, de los creadores, de toda nuestra comunidad, y tienen valor dentro y fuera de nuestras apps',
    imFlixx: 'Hola! Yo soy Flixx',
    getFlixx_title: 'Varias maneras de conseguir Flixx',
    buy: {
      title: 'Comprá Flixx desde Flixxo',
      description: 'Preparamos para vos ofertas de Flixx para que puedas disfrutar la experiencia Flixxo al máximo',
      example_title: '¿Querés más Flixx?',
      example_description: 'Aprovecha las ofertas que tenemos preparadas para vos.',
      example_cta: 'Comprar',
    },
    ads: {
      title: 'Ganá Flixx mirando anuncios',
      description: 'Podés ganar Flixx mirando anuncios cuando quieras, o previo a la reproducción de un contenido si no tenés Flixx suficientes',
    },
    challenges: {
      title: 'Ganá recompensas cumpliendo desafíos',
      description: 'Cumplí los desafíos que tenemos preparados para vos y ganá jugosas recompensas en Flixx (para acceder a los desafíos <0>descargate la App</0>)',
      example_title: 'El primero de muchos...',
      example_description: 'Como bienvenida, te regalamos Flixx por tu primer contenido visto',
      example_prize: 'Premio',
      example_cta: 'Reclamar recompensa',
    },
    exchanges: {
      title: 'Comprá Flixx en un exchange',
      description: 'Desde <0>Uniswap</0> o <1>Bitmart</1> podés comprar y transferir Flixx a tu billetera',
    },
    faqs: {
      title: 'Más información',

      question_1: 'Una vez registrado, ¿qué necesito para ver un contenido?',
      answer_1: 'Depende de cómo hayas llegado a la plataforma. Si tenés Flixxo a través de una operadora telefónica <high>(solo disponible en algunos países)</high> podrás ver contenido cuando quieras, donde quieras. Sino, necesitarás Flixx en tu cuenta: podés conseguir Flixx viendo anuncios o cumpliendo distintos challenges.',

      question_2: '¿Cuál es la cotización del Flixx?',
      answer_2: 'Podés ver la cotización del Flixx en <0>CoinMarketCap</0>',

      question_3: '¿Por qué Flixxo utiliza Flixx?',
      answer_3: 'Utilizar nuestra criptomoneda nos permitió crear un ecosistema transparente e inmediato, donde el intercambio de Flixx va sin intermediarios del anunciante al espectador y del espectador al creador.',

      doubts: '¿Te quedaron dudas? <0>contactate con nosotros</0>',
    },
  },
  faq: {
    description: 'Respuestas a las preguntas más frecuentes.',

    whatIsFlixxo_question: '¿Qué es Flixxo?',
    whatIsFlixxo_answer: 'Flixxo es una plataforma de microseries y cortos de todo el mundo. El modelo de distribución y monetización en Flixxo es diferente a todo lo que existe, ya que permite:<bold>A los espectadores:</bold><enter /><li>Que tengan una alternativa para <high>encontrar nuevas voces y contenidos independientes y originales</high>, es decir, que no dependan del algoritmo.</li><li>Que puedan ver en poco tiempo, buenas historias.</li><bold>A los creadores:</bold><enter /><li>Que puedan <high>monetizar de manera instantánea y transparente.</high></li>',

    whatContent_question: '¿Qué tipo de contenido encuentro en Flixxo?',
    whatContent_answer: 'En Flixxo vas a encontrar diversidad de contenidos de creadores emergentes e independientes que descubrimos en festivales de todo el mundo. Nuestra curaduría está enfocada en las nuevas voces creativas de todos los géneros.<enter />Nuestro contenido está dividido en Cortometrajes y Microseries, ideales para ver en cualquier momento y lugar, debido a su corta duración ;)',

    howAccessContent_question: '¿Cómo accedo a mirar el contenido de Flixxo?',
    howAccessContent_answer: '¡Fácil! En la web presionando el botón <high>Ingresar</high>, arriba a la derecha, y siguiendo las instrucciones. También está disponible la App de Flixxo en <android>Android</android> y en <ios>iOS</ios>.',

    canUploadMyContent_question: 'Soy creador, ¿puedo subir mi contenido a Flixxo?',
    canUploadMyContent_answer: 'Claro, los detalles en <a>Flixxo Creators</a>',

    flixxoDifference_question: '¿Qué diferencia a Flixxo de otras plataformas?',
    flixxoDifference_1: 'Contenidos de corta duración',
    flixxoDifference_2: 'Apoyo transparente y directo al creador de contenido',
    flixxoDifference_3: 'Diversidad de voces y realizadores emergentes',
    flixxoDifference_4: 'En Flixxo la visibilidad de los contenidos no depende del algoritmo, sino de la curaduría',
    flixxoDifference_5: 'Ecosistema creativo entre creadores y audiencia',

    producing_question: '¿Flixxo produce?',
    producing_answer: 'Como casa productora, Flixxo desarrolla distintos contenidos originales: los <high>Flixxo Originals</high>.\n\nAdemás, como distribuidora, a través de <high>XO Distribution</high>, Flixxo apoya a más de 30 <high>Creadores 3.0</high> alrededor del mundo promoviendo sus historias en mercados internacionales y otras plataformas.',

    creators3_question: '¿Qué son los Creadores 3.0?',
    creators3_1: 'Creadores 3.0 son los protagonistas de un nuevo paradigma de creatividad colaborativa y descentralizada, propias de la tecnología y cultura de la web 3.0.',
    creators3_2: 'El valor fundamental de la web 3.0 es la descentralización, es decir, que no haya un intermediario poniendo las reglas. Esto le devuelve el poder al creador de contenidos para monetizar de forma directa.',

    doubts: '¿Te quedaron dudas? <mail>contáctate con nosotros</mail>',
  },
  beCreator: {
    title: 'Impulsemos tu contenido\n<high>cerca de tu comunidad</high>',
    description: 'Acompañamos a creadores independientes de todo el mundo en la producción y distribución de su contenido.',
    cta: 'Quiero ser creador 3.0',
    yourContent: 'Tu contenido',
    benefits: {
      global: '<high>Microseries y Cortometrajes</high> de cualquier parte del mundo.',
      independent: '<high>Creadores independientes</high> que se animan a desafiar el paradigma.',
      diverse: '<high>Contenidos diversos</high> de distintos temas y géneros.',
      advice: '<high>Te asesoramos</high> para que tu contenido cumpla altos estándares.',
      reach: '<high>Te impulsamos</high> para que alcances otros mercado y plataformas.',
      rules: '<high>Tu contenido, tus reglas.</high> Te acompañamos en tu visión.',
    },
    steps: {
      title: 'En Flixxo te abrimos las puertas',
      description: 'Comprendemos el valor y potencial que hay en los contenidos independientes, es por eso, que lo primero que vas a recibir en Flixxo es un abrazo. De ahí en más nuestra misión es acompañarte.',
      reachUs: {
        title: 'Contactate con nosotros',
        description: 'Presentanos tu contenido',
        example_time: 'Hace {{number}} min.',
        example_creator_chat: 'Hola Flixxo! tengo una microserie y un cortometraje que me gustaría publicar en su plataforma 🤍',
        example_flixxo_chat: 'Hola creador, genial! nos encanta recibir propuestas. Trabajemos juntos para publicar el contenido lo antes posible en Flixxo.',
      },
      publish: {
        title: 'Publicamos tu contenido',
        description: 'Una vez que recibimos el contenido, trabajamos juntos para publicarlo',
        example_content: 'Tu contenido',
      },
      monetize: {
        title: 'Monetizá con cada compra',
        description: 'Cada vez que alguien compra un contenido tuyo, ganás Flixx. Los recibís de manera directa, sin intermediarios',
      },
    },
    protagonist: {
      title: 'Convertite en un creador 3.0',
      description: 'Sé el protagonista de tu contenido y de tu comunidad.',
    },
    dashboard: {
      title: 'Cada vez que un usuario compra tu contenido, ganás Flixx',
      description: 'El Flixx es la criptomoneda de Flixxo. Cada vez que alguien compra tu contenido recibís los Flixx directo a tu cuenta.',
      content_description: 'Seguí de cerca las interacciones de tu contenido de manera desglosada.',
      earnings: 'Tus ganancias',
      earnings_description: 'Analizá la evolución de tus ganancias totales o por períodos específicos.',
    },
    footer: {
      title: '¿Estás listo para convertirte en un creador 3.0?',
      faq: '¿Te quedaron preguntas? Seguro las respondimos en nuestras <0>preguntas frecuentes</0>',
    },
  },
  tokenGating: {
    seo_description: 'Viví la experiencia Token Gating en Flixxo. Conectá tu wallet y desbloqueá el acceso a contenidos únicos con tokens válidos',
    description: 'Si tenés un token específico, podés desbloquear contenidos únicos',
    description2: 'Conectá tu wallet y descubrilos',
    cta: 'Conectar Wallet',
    walletConnected: 'Wallet conectada',
    walletDisconnected: 'Wallet desconectada',
    connect: 'Conectar',
    disconnect: 'Desconectar',
    step1: 'Obtené el token',
    step2: 'Conectá tu wallet',
    step3: 'Mirá contenidos',
    availableWithToken: 'Disponible con token',
    needToConnectWallet: 'Debes conectar tu wallet',
    whichTokensNeed: '¿Qué token/ticket 3.0 necesitás?',
    haveToken: '¡Tenés tu token/ticket 3.0!',
    waitingSignature: 'Esperando firma...',
    validating: 'Validando...',
    faqs: {
      title: 'Preguntas frecuentes',
      question_1: '¿Qué es Token Gating?',
      answer_1: 'Token Gating es una sección de contenidos exclusivos en Flixxo. Los contenidos de esta sección se habilitarán únicamente a los usuarios que posean el token requerido. Con Token Gating encontramos una nueva forma de seguir construyendo vínculos más significativos en la comunidad del cine independiente.',
      question_2: '¿Qué necesito para acceder?',
      answer_2: 'Para acceder a los contenidos exclusivos necesitás tener el token correspondiente en la wallet que vincules a Flixxo. Dale play y nuestro sistema verificará la posesión del token y habilitará la reproducción.',
      question_3: '¿Cómo consigo tokens?',
      answer_3: 'Cada contenido exclusivo tiene un token propio que lo habilita. Podés haber adquirido el token previamente, sino en la antesala del contenido te indicaremos dónde conseguirlo.',
      question_4: 'Ya tengo el token... ¿ahora qué hago?',
      answer_4: 'Iniciá sesión en Flixxo, vinculá la wallet donde tenés el token... ¡y listo! Ya podés disfrutar del contenido exclusivo.',
      question_5: '¿Puedo acceder a contenidos sin el token',
      answer_5: 'Si tenés cuenta en Flixxo podés acceder a +300 contenidos a través de los Flixx en tu saldo. Si no tenés Flixx, mirá antes un anuncio. Los contenidos exclusivos de esta sección solo podrás verlos con el token externo que corresponda.',
      question_6: '¿Cómo conecto mi wallet?',
      answer_6: 'Conectá tu wallet en esta misma sección, desde el botón Conectar wallet. Elegí la wallet y colocá tus credenciales. Una vez conectada, verás el indicador correspondiente. Podrás desconectarla en cualquier momento.',
    },
    error: {
      alreadyUsed: 'La wallet ya se encuentra asociada a otra cuenta',
    },
  },
  vas: {
    loginModal: {
      title: '¡Gracias por ser parte de Flixxo!',
      description: 'Para ingresar con tu cuenta Flixxo Prepago, contactá a tu operadora telefónica y te dará el acceso. ¡Te esperamos!',
    },
  },
  privacy: {
    title: 'Políticas de privacidad',
    date: '<b>Fecha de Vigencia:</b> 22 de marzo de 2019',
    item_1: {
      title: '1. Introducción',
      p1: 'Nosotros, Flixxo, somos una plataforma descentralizada de distribución de videos basada en tecnología blockchain y bit torrent. Flixxo es operada por Flixxo Limited, una compañía registrada en Gibraltar. Nuestra dirección es Suite 23, Portland House, Glacis Road, Gibraltar. Nuestra misión es permitir que cualquier usuario de Internet se convierta en distribuidor de videos, ayudar a los autores de contenido a monetizar su producción recibiendo pagos directamente de los consumidores, y permitir que los anunciantes conozcan mejor y se relacionen más de cerca con su audiencia. Nuestro objetivo principal es contribuir a cambiar el mercado como lo conocemos hoy en día, eliminando intermediarios y favoreciendo las conexiones entre pares.',
      p2: 'Recopilamos su información personal para permitirle el uso de la Plataforma y los Sitios, compartir noticias e información relevante y mantener una comunidad vibrante de Usuarios a nivel mundial (nuestro “Propósito”). Esta política de privacidad (la “Política de Privacidad” o esta “Política”) regula la forma en que recopilamos, usamos y divulgamos su información personal a nivel mundial.',
      p3: 'Esta Política también está regulada por los Términos y Condiciones disponibles en nuestro Sitio. Los términos en mayúsculas en este documento siguen las definiciones proporcionadas en nuestros Términos y Condiciones.',
    },
    item_2: {
      title: '2. Ámbito de esta Política',
      p1: 'Esta Política se aplica al procesamiento de toda la Información Personal, como se define a continuación, con respecto a todos los Usuarios, incluidas todas las personas que se registren en nuestra Plataforma.',
      p2: 'Si bien esta Política tiene la intención de describir la gama más amplia de nuestras actividades de procesamiento de información a nivel mundial, dichas actividades pueden estar más limitadas en algunas jurisdicciones según las restricciones de sus leyes. Por ejemplo, las leyes de una jurisdicción en particular pueden limitar los tipos de información personal que podemos recopilar o las formas en que procesamos dicha información personal. En esos casos, ajustamos nuestras prácticas y políticas internas para cumplir con las leyes aplicables.',
    },
    item_3: {
      title: '3. Información Personal que Procesamos',
      p1: 'El término “Información Personal” en esta Política se refiere a la información que lo identifica o que es capaz de identificarlo como individuo. Los tipos de información personal que procesamos (que pueden variar según la jurisdicción y las leyes aplicables) pueden incluir:',
      p2: 'Siempre puede visitar nuestros Sitios sin iniciar sesión ni revelar quién es. La mayoría de la información personal que utilizamos es información que usted nos proporciona. Sin embargo, en otros casos, procesamos información personal que podemos inferir sobre usted en función de otra información que nos proporcione o de nuestras interacciones con usted, o información personal sobre usted que recibimos de un tercero. Esta Política también se aplica a la información sobre usted que recibimos de otros, la cual también puede estar sujeta a políticas de privacidad adicionales de terceros.',
      ul1: 'Su nombre y apellido, correo electrónico, teléfono móvil, dirección, imagen, datos de ubicación;',
      ul2: 'Información, comentarios o contenido que proporcione respecto a sus preferencias de contacto y suscripción;',
      ul3: 'Información recopilada cuando accede a nuestra Plataforma o a uno de nuestros Sitios, incluyendo la dirección IP asignada automáticamente a su computadora, la fecha y hora de sus visitas, las secciones que accede y el tiempo que pasa en cada sección, el Contenido que abre, el tipo de dispositivo, navegador y sistema operativo que utiliza, y la URL de cualquier sitio web que haya visitado antes y después de visitar nuestra Plataforma o nuestros Sitios;',
      ul4: '¿Información recopilada cuando se comunica con nosotros, incluyendo correos electrónicos, mensajes y registros;',
      ul5: 'Información, comentarios o contenido que proporcione en nuestra Plataforma y nuestros Sitios y en los de terceros, incluidos perfiles públicos en redes sociales, estado e información;',
      ul6: 'Su actividad en Flixx y todos los movimientos en su billetera digital.',
    },
    item_4: {
      title: '4. Información Personal Sensible',
      p1: 'Parte de la información personal que procesamos se considera Información Personal Sensible según las leyes de privacidad de algunas jurisdicciones. Dichas leyes definen la “Información Personal Sensible” como información personal a partir de la cual podemos conocer o inferir la participación política, votos, opiniones, creencias religiosas u otras creencias de naturaleza similar, creencias filosóficas; membresía en sindicatos o en asociaciones profesionales, condiciones de salud física o mental, tratamientos médicos, datos genéticos, vida sexual o datos judiciales (incluyendo información relacionada con la comisión o presunta comisión de un delito). En algunos casos específicos, los registros financieros pueden formar parte de la Información Personal Sensible según su ubicación. Solo procesamos Información Personal Sensible en su jurisdicción si y en la medida en que lo permita o exija la ley aplicable.',
      p2: 'Cuando usted acepta esta Política, en la medida permitida por las leyes aplicables, está otorgando su consentimiento expreso y por escrito para el procesamiento de cualquier información personal que nos proporcione y que se considere Información Personal Sensible. Excepto en los casos requeridos por la ley, usted puede negarse a proporcionarnos cualquier Información Personal Sensible, y esto no afectará su participación en nuestras actividades o su participación en nuestra Plataforma o en nuestros Sitios.',
    },
    item_5: {
      title: '5. Cómo Procesamos su Información Personal',
      p1: 'Sujeto a las leyes aplicables, podemos procesar, recopilar, usar y divulgar su información personal para:',
      p2: 'Todo el procesamiento de su información personal se basa en una base legal para el procesamiento. En la mayoría de los casos, el procesamiento se justificará sobre la base de que: (a) el procesamiento está en los intereses legítimos de Flixxo, sujetos a sus intereses y derechos fundamentales; (b) el procesamiento es necesario para el cumplimiento de un contrato en el que usted es parte, o para tomar medidas (a su solicitud) para celebrar un contrato; (c) el procesamiento es necesario para cumplir con una obligación legal relevante (por ejemplo, cuando estamos obligados a hacer divulgaciones según las leyes aplicables); (d) el procesamiento es necesario para el cumplimiento de una tarea realizada en interés público (incluida la protección de los derechos humanos). En algunas circunstancias, utilizaremos su consentimiento como base para procesar su información personal, por ejemplo, cuando la ley nos exija obtener su consentimiento previo para comunicarnos con usted.',
      p3: 'Antes de recopilar y/o usar cualquier Información Personal Sensible, estableceremos una exención legal que nos permita usar esa información. Esta exención será típicamente su consentimiento explícito; el establecimiento, ejercicio o defensa por nosotros o terceros de reclamos legales; o una exención específica al contexto proporcionada por las leyes locales.',
      p4: 'Conservamos su información personal durante el tiempo necesario para cumplir con nuestro Propósito o por un período más largo según lo exijan las leyes aplicables.',
      ul1: 'Contactarlo y enviarle noticias y actualizaciones relevantes sobre nuestras actividades;',
      ul2: 'Proporcionarle un contacto mejorado o más personalizado, incluidas noticias e información de terceros (como patrocinadores y socios comerciales), y para interactuar con usted en diversas plataformas de conectividad y canales de redes sociales;',
      ul3: 'Realizar investigaciones, encuestas de participación de seguidores y auditorías;',
      ul4: 'Solicitar, procesar y gestionar pagos en Flixx;',
      ul5: 'Responder a solicitudes de información, incluidos servicios de terceros (como patrocinadores, socios comerciales u otras billeteras virtuales que usted autorice);',
      ul6: 'Garantizar la seguridad de nuestros Usuarios y equipos;',
      ul7: 'Gestionar y mantener nuestros registros, realizar monitoreo y capacitación, facilitar auditorías y cumplir con requisitos legales y regulatorios.',
    },
    item_6: {
      title: '6. Transferencia y Divulgación de su Información Personal',
      p1: 'En algunas circunstancias, podemos transferir o divulgar su información personal. Siempre realizaremos dicha transferencia y divulgación bajo los requisitos de todas las leyes aplicables. En algunas jurisdicciones, las leyes de protección de datos pueden requerir que obtengamos su consentimiento antes de transferir su información desde su país de origen a otras jurisdicciones. Al aceptar esta política, usted está otorgando, en la medida en que lo requieran y lo permitan las leyes aplicables, su consentimiento activo para la transferencia de su información personal a otras jurisdicciones con el propósito y el alcance establecidos en esta Política.',
      p2: 'Podemos transferir y divulgar información personal en situaciones que incluyen:',
      p3: '<b>Nuestros Empleados, Socios, Agentes, Contratistas y Proveedores de Servicios.</b> Podemos contratar empleados, socios, agentes, contratistas y proveedores de servicios para ayudarnos a contactar a los usuarios con el propósito de gestionar nuestro contacto con los seguidores, eventos y otras actividades. Al igual que muchas otras entidades, podemos externalizar el procesamiento de ciertas actividades y/o información a terceros. Tomamos medidas razonables diseñadas para garantizar que nuestros empleados, socios, agentes, contratistas y proveedores de servicios involucrados en el procesamiento de datos personales lo hagan bajo acuerdos escritos de confidencialidad, hayan recibido la capacitación adecuada para manejar datos personales y estén informados de la naturaleza confidencial de dichos datos. Cuando externalizamos el procesamiento de su información personal a terceros o proporcionamos su información personal a proveedores de servicios externos, obligamos a esos terceros a proteger su información personal conforme a los términos y condiciones de esta Política, con medidas de seguridad adecuadas.',
      p4: 'Usamos Amazon Web Services para gestionar nuestros sitios.Estos proveedores de servicios y otros terceros, incluidos Google, pueden usar cookies para proporcionar una mejor experiencia de navegación. Puede desactivar estas cookies específicas que realizan un seguimiento de visitas anteriores con el fin de hacer publicidad de seguimiento optando por no hacerlo en <googlePolicies>https://policies.google.com/technologies/ads</googlePolicies>.',
      p5: '<b>Requisitos Legales; Cumplimiento de Leyes y Autoridades Judiciales.</b> Cooperamos con entidades gubernamentales y organizaciones privadas para hacer cumplir y cumplir con la ley. Nos reservamos el derecho de compartir cualquier información personal que tengamos sobre usted si estamos obligados a hacerlo por un tribunal de justicia o solicitados legalmente por una entidad gubernamental. También podemos divulgar cualquier información sobre usted a funcionarios gubernamentales o de la ley o a partes privadas, según lo consideremos necesario o apropiado para responder a reclamaciones y procesos legales, cumplir con la ley, proteger la seguridad de cualquier persona o prevenir o detener cualquier actividad que consideremos ilegal, poco ética o legalmente objetable. También nos reservamos el derecho de retener la información personal recopilada y procesarla para cumplir con las reglas y regulaciones fiscales y contables y cualquier ley específica sobre retención de registros.',
      p6: 'En caso de que el usuario desee eliminar su cuenta y borrar sus datos personales, puede solicitarlo a <mailto>info@flixxo.com</mailto>.',
    },
    item_7: {
      title: '7. Información Agregada e Información No Identificativa',
      p1: 'Podemos compartir información agregada que no incluya información personal y divulgar de otra manera información no identificativa y datos de registro a terceros para análisis de seguidores, auditorías generales e informes, creación de perfiles demográficos y otros fines. Cualquier información agregada compartida en estos contextos no contendrá su información personal.',
    },
    item_8: {
      title: '8. Cookies',
      p1: 'Podemos utilizar tecnología de cookies en nuestra Plataforma y nuestros Sitios para permitirnos auditar y mejorar las actividades de nuestra Plataforma y nuestros Sitios. Las cookies por sí solas no pueden utilizarse para revelar su información personal. Identifican su navegador, pero no a usted, para nuestros servidores cuando visita nuestros Sitios. Si no desea aceptar cookies, puede bloquearlas ajustando la configuración de su navegador. Sin embargo, si las bloquea, no podrá utilizar todas las características de nuestros sitios web.',
    },
    item_9: {
      title: '9. Uso de su Información Personal en Plataformas de Redes Sociales y Otros Sitios Web',
      p1: 'Nuestra Plataforma y nuestros Sitios también pueden contener complementos y características de conectividad que integran plataformas de redes sociales de terceros en nuestros Sitios. Si usa dichos complementos, los terceros que operan estas plataformas pueden ser capaces de identificarlo, pueden determinar cómo usa este sitio web y pueden vincular y almacenar esta información con su perfil de redes sociales. Consulte las políticas de protección de datos de estas plataformas sociales para entender qué harán con su información personal.',
      p2: 'Los usuarios que decidan retirar Flixx a otras carteras virtuales fuera de Flixxo estarán sujetos a los términos y políticas de privacidad de esa cartera virtual. Esta Política, y nuestra responsabilidad, se limita a nuestras propias prácticas de procesamiento de información. No somos responsables, y no siempre podemos garantizar, las prácticas de procesamiento de información o las políticas de privacidad de otras carteras, plataformas o sitios donde envíe su información personal directamente. Además, no podemos garantizar el contenido de los sitios mantenidos por estos terceros o nuestros proveedores de servicios, incluso si son accesibles mediante un enlace desde nuestra Plataforma o nuestros Sitios. Le recomendamos leer las políticas de privacidad y seguridad de cualquier cartera, plataforma o sitio de terceros antes de proporcionar cualquier información personal al acceder a esos sitios.',
    },
    item_10: {
      title: '10. Seguridad',
      p1: 'La seguridad de sus datos personales es importante para nosotros. Usamos software de Secured Socket Layer ("SSL") para cifrar la información personal que nos proporciona. Seguimos estándares generalmente aceptados para proteger los datos personales que se nos envían. Sin embargo, somos conscientes de que ningún método de transmisión por Internet o de almacenamiento electrónico es 100% seguro y no podemos garantizar su seguridad absoluta.',
      p2: 'La información personal que recopilamos de usted en línea es almacenada por nosotros y nuestros proveedores de servicios en bases de datos protegidas mediante una combinación de controles de acceso, tecnología de seguridad y otras medidas de seguridad de última generación. No obstante, estas medidas de seguridad no pueden prevenir siempre todas las pérdidas, el uso indebido o cambios no autorizados de información personal, y en la mayor medida permitida por la ley aplicable, no somos responsables de los daños o responsabilidades relacionados con dichos incidentes.',
      p3: 'Mantenemos una política interna de seguridad de datos, que incorpora medidas de seguridad técnicas, administrativas y físicas diseñadas para garantizar la seguridad e integridad de los datos personales. Las personas interesadas pueden solicitar una copia de nuestra documentación de seguridad de datos, la cual proporcionaremos bajo un acuerdo de confidencialidad.',
      p4: 'Le informaremos, como se requiere legalmente, de cualquier violación de la seguridad, confidencialidad o integridad de los datos personales almacenados por nosotros de la manera más rápida y sin demoras injustificadas. Tomaremos todas las medidas necesarias para determinar el alcance de la violación y restaurar la integridad razonable del sistema de datos para que usted pueda tomar las acciones apropiadas para la protección de sus derechos.',
    },
    item_11: {
      title: '11. Tus Derechos',
      p1: 'Siempre puedes elegir qué información personal deseas proporcionarnos. Sin embargo, si decides no proporcionar ciertos detalles, algunas de tus experiencias con nosotros pueden verse afectadas. Por ejemplo, es posible que no puedas firmar una petición sin proporcionar tu nombre.',
      p2: 'Nos comprometemos a respetar tus derechos sobre tus datos personales, incluidos los siguientes derechos:',
      p3: 'Si deseas ejercer alguno de los derechos anteriores, por favor contáctanos aquí para que podamos considerar tu solicitud conforme a la ley aplicable.',
      p4: 'Si deseas obtener información sobre cualquier dato personal que podamos tener sobre ti, puedes hacerlo enviándonos una solicitud escrita por carta o correo electrónico a las direcciones indicadas en la sección "Contáctanos" a continuación. Asegúrate de incluir tu nombre completo, dirección y número de teléfono, así como una copia de un documento que demuestre tu identidad (como una cédula de identidad o pasaporte) para que podamos confirmar tu identidad y verificar si tenemos información personal sobre ti, o en caso de que necesitemos contactarte para obtener más información para procesar tu solicitud.',
      p5: 'Nos reservamos el derecho de cobrarte una tarifa, cuando lo permita la legislación aplicable, por procesar dicha solicitud. Si nos envías más de una solicitud, podemos responder a tu solicitud posterior refiriéndonos a nuestra primera respuesta e identificando únicamente los elementos que hayan cambiado. Puedes solicitar que corrijamos, eliminemos y dejemos de procesar la información personal que tenemos sobre ti, y procederemos conforme a la ley aplicable.',
      ul1: '<b>Derecho de acceso:</b> Puede que tengas el derecho de acceder a los datos personales que nos has proporcionado.',
      ul2: '<b>Derecho a la rectificación:</b> Puede que tengas el derecho a exigirnos que corrijamos tus datos personales.',
      ul3: '<b>Derecho a la eliminación:</b> Puede que tengas el derecho a la eliminación de los datos personales que tenemos sobre ti.',
      ul4: '<b>Derecho a restringir el procesamiento:</b> Puede que tengas el derecho a solicitar que dejemos de procesar tus datos personales y a dejar de enviarte mensajes.',
      ul5: '<b>Derecho a oponerte al procesamiento</b>, y el derecho a no proporcionar o retirar tu consentimiento en cualquier momento;',
      ul6: '<b>Derecho a la portabilidad de los datos:</b> El derecho a recibir tus datos personales en un formato estructurado, de uso común y legible por máquina, y el derecho a transmitir esos datos a otro ente sin obstáculos;',
      ul7: '<b>Derecho a rechazar la toma de decisiones automatizadas:</b> El derecho a no ser objeto de decisiones basadas únicamente en el procesamiento automatizado, que produzcan efectos legales o significativos para ti, excepto cuando estas sean (i) necesarias para un contrato en el que seas parte; (ii) autorizadas por la ley; (iii) basadas en tu consentimiento explícito. Actualmente, no utilizamos toma de decisiones automatizada (incluyendo la toma de decisiones automatizada mediante perfiles) cuando procesamos tu información personal. Si alguna vez utilizamos una solución de toma de decisiones automatizada, tienes derecho a solicitar que una decisión basada en tu información personal no se tome únicamente de manera automatizada.',
      ul8: '<b>Derecho a presentar una queja</b> ante una autoridad de control, designada por la legislación aplicable.',
    },
    item_12: {
      title: '12. Cambios a esta Política',
      p1: 'Podemos actualizar esta Política de vez en cuando. En tales casos, publicaremos la versión actual en nuestro sitio principal y revisaremos la fecha de la versión ubicada en la parte superior de este documento. Te animamos a revisar periódicamente esta Política.',
    },
    item_13: {
      title: '13. Contáctanos',
      p1: 'Hemos designado a un Oficial de Privacidad de Datos que puede ser contactado en <mailto>legal@flixxo.com</mailto>.',
      p2: 'Nuestra dirección principal es Suite 23, Portland, House Glacis Road, Gibraltar.',
      p3: 'Todas las solicitudes de acceso a tu información personal deben ser enviadas por escrito, ya sea por carta, formulario o correo electrónico, y deben incluir una copia de un documento de identidad. Podemos responder a tu solicitud por correo electrónico, teléfono o cualquier otro método adecuado.',
    },
  },
  old: {
    /**
     * COMMON
     */
    goToHome: 'Ir al inicio',
    spanish: 'Español',
    english: 'Inglés',
    enterEmail: 'Ingresá tu e-mail',
    creators: 'Creadores',
    download: 'Descargar',
    downloadApp: 'Descargá la app',
    back: 'Atrás',
    series: 'SERIES',
    min: 'min',
    faqs: 'Preguntas frecuentes',
    o: 'o',
    accept: 'Aceptar',
    language: 'Idioma',
    content: 'Contenido',

    /**
     * MODAL
     */
    modal_enjoyYourContent_title: 'Disfrutá tu contenido',
    modal_enjoyYourContent_content: 'Conocé todas las funcionalidades en nuestra app.',
    modal_spendFlixx_title: 'Gastá tus Flixx',
    modal_spendFlixx_content: 'Conocé todas las funcionalidades en nuestra app.',
    scanCodeQR: 'Escanea el código QR y accede a Flixxo',
    fromYourMobilePhone: 'desde tu teléfono móvil.',
    availableOn: 'Disponible en',
    liveFullExperience: '¡Viví la experiencia completa!',

    /**
     * NEW MESSAGES
     */
    retry: 'Reintentar',
    openApp: 'ABRIR EN LA APLICACIÓN',
    backToPlay: 'Volver a Play Flixxo',
    goToCreators: 'Ir a Flixxo Creators',
    showDetail: 'Ver detalle',
    sendCode: 'Obtener código',
    enterPhone: 'Ingresa tu numero de telefono',
    enterCode: 'Ingresa aqui el código',
    newPassword: 'Nueva contraseña',
    confirmPassword: 'Confirmá tu contraseña',
    didNotGetCode: '¿No te llegó el código?',
    sendAgain: 'Volver a enviar',
    enter: 'Ingresar',
    purchased: 'Comprado',
    watchFor: 'Ver por',
    watchSE: 'Ver T{{season}} E{{episode}}',
    season: 'Temporada',
    seasons: 'Temporadas',
    audio: 'Audio',
    video: 'Video',
    videos: 'Videos',
    nextEpisode: 'Próximo episodio',
    starting: 'Comenzando',
    continueWatching: 'Continuar viendo',
    didYouEnterWrongNumber: '¿Ingresaste un número incorrecto?',
    selectOption: 'Seleccionar una opción',
    next: 'Siguiente',
    enjoyFullExperience: 'Disfruta de la experiencia completa en nuestra app',
    open: 'Abrir',
    continueOnBrowser: 'Continuar en el navegador',

    statistics: 'Estadísticas',
    newFollowers: 'Nuevos Seguidores',
    status: 'Estado',
    releaseDate: 'Estreno',
    purchases: 'Compras',
    earnings: 'Ganancias',
    price: 'Precio',

    /**
     * MODALS WELCOME, LOGIN, REGISTER, RECOVERY
     */
    login: 'Iniciar sesión',
    loggingIn: 'Iniciando sesión...',
    createAccount: 'Crear cuenta',
    creatingAccount: 'Creando cuenta...',
    goodToSeeYouAgain: '¡Que bueno verte otra vez!👋',
    fillInYourDetails: 'Completá tus datos para ingresar',
    nicknameOrEmail: 'Apodo o e-mail',
    password: 'Contraseña',
    forgotMyPassword: 'Olvidé mi contraseña',
    firstTimeInFlixxo: '¿Primera vez en Flixxo?',
    createYourAccount: 'Creá tu cuenta',
    recoverPassword: 'Recuperar contraseña',
    recoveringPassword: 'Recuperando contraseña...',
    enterYourMobileNumber: 'Ingresá tu número de celular',
    mobileNumber: 'Número de celular',
    mobileInfo: 'Te solicitamos tu número de teléfono para contar con un segundo factor de seguridad al recuperar una contraseña.',
    passwordsDoesntMatch: 'Las contraseñas que ingresaste no coinciden.',
    enterCodeAndCreateNewPwd: 'Ingresá el código que te enviamos y creá la nueva contraseña',
    resendCode: 'Reenviarme el código',
    repeatPassword: 'Repetir contraseña',
    passwordReqs: 'Debe contener al menos una minúscula, una mayúscula y un número. Mínimo 8 caracteres, máximo 30.',
    passwordRecoverySuccessfull: '¡Recuperaste tu contraseña exitosamente!',
    nowYouCanLoginWithNewPwd: 'Ya podés iniciar sesión con la nueva contraseña',
    great: 'Genial',
    email: 'E-mail',

    createPassword: 'Creá una contraseña',
    mustContainOneLowerCase: 'Debe contener al menos una minúscula',
    mustContainOneUpperCase: 'Debe contener al menos una mayúscula',
    mustContainOneNumber: 'Debe contener al menos un número',
    lengthMinMax: 'Mínimo {{min}} caracteres, máximo {{max}}',
    congratsHaveCreatedAnAccount: '¡Felicitaciones! \n Creaste tu cuenta en Flixxo',
    nowYouAreFlixxer: '¡De ahora en más sos Flixxer! 🤘',
    validateAccount: 'Validar cuenta',
    validatingAccount: 'Validando cuenta...',
    finishAccountValidation: 'Finalizá la validación de tu cuenta ingresando tu número de celular',
    enterCodeSentTo: 'Ingresá el código enviado al:\n{{mobileNumber}}',

    recaptcha: 'Este sitio está protegido por reCAPTCHA y se aplican las <0>Políticas de privacidad</0> y los <1>Términos de servicio</1> de Google.',


    /**
     * SEO
     */
    seoSeriesTitle: 'Series',
    seoSeriesDescription: 'Microseries originales del mundo',
    seoSearchTitle: 'Búsqueda',
    seoSearchDescription: 'Buscá los mejores contenidos y videos cortos en Flixxo',
    seoDefaultTitle: 'Flixxo',
    seoDefaultDescription: 'Microseries y videos cortos al estilo de la web3',
    seoBeCreatorTitle: 'Convertite en creador',
    seoBeCreatorDescription: 'Acompañamos a creadores independientes de todo el mundo en la producción y distribución de su microserie.',
    seoFlixxTitle: 'Flixx, nuestra criptomoneda',
    seoFlixxDescription: 'Flixx es la criptomoneda de Flixxo, con la que vas a poder ver microseries grátis y bancar a sus creadores.',

    /**
     * CREATORS
     */
    fromBeginning: 'Desde el inicio',
    last30Days: 'Últimos 30 días',
    last7Days: 'Últimos 7 días',
    fromBeginningPhrase: 'Desde que subiste tu primer contenido',
    periodPhrase: 'Desde el {{ start }} hasta el {{ end }}',
    allContent: 'Todo el contenido',

    noSearchResults: 'No hay resultados para tu búsqueda',
    noSearchResultsDescription: 'Intenta escribiendo de otra manera o busca otro de tus contenidos',
    noContentYet: 'Todavía no tenés ningún contenido',
    noContentYetDescription: 'Comenzá a subir contenido y empieza a crear todo tu público',
    noInternet: 'No tienes conexión a internet',
    noInternetDescription: 'Intenta revisar tu conexión y luego prueba recargando la página',
    serverError: 'Hubo un problema con el servidor',
    serverErrorDescription: 'Prueba recargando la página',

    searchYourVideo: 'Buscá tu video...',
    viewOnFlixxo: 'Ver en Flixxo',
    published: 'Publicado',
    deleted: 'Eliminado',
    paused: 'Pausado',
    unknown: 'Desconocido',

    seasonEpisode: 'T{{season}}E{{episode}}',
    earningsTooltip: 'De tus ingresos (compras), una parte se utiliza para apoyar a la Comunidad.',
    yourContentIsPaused: 'Tu contenido está pausado',
    knowMoreAboutContent: 'Si queres saber más acerca de este contenido escribinos a content@flixxo.com',
    sendUsYourQuestion: 'Escribinos tu consulta',

    /**
     * ERROR MESSAGES
     */
    contentNotFound: 'El contenido que estás buscando no existe',
    wrongCredentials: 'Apodo, e-mail o contraseña incorrecta.',
    emailRegistered: 'El e-mail que ingresaste ya está registrado.',
    emailInvalid: 'El e-mail que ingresaste no posee un formato válido.',
    nicknameRegistered: 'El apodo que ingresaste ya está registrado.',
    passwordsDontMatch: 'Las contraseñas que ingresaste no coinciden',
    mobileNumberInvalid: 'El número que ingresaste no es válido',
    mobileNumberRegistered: 'El número que ingresaste ya está registrado',
    mobileNumberUnknown: 'El número ingresado no pertenece a ningún usuario registrado',
    codeInvalid: 'El código que ingresaste no es válido',
    operationLimitExceeded: 'Superaste la cantidad de intentos permitidos. Por favor intentá de nuevo más tarde.',
    notPossibleLogInThisAccount: 'No es posible iniciar sesión con esta cuenta.',


    /**
     * CONTENT PLAYER
     */
    seasonEpisodeHyphen: 'T{{season}}-E{{episode}}',
  },
}

export default es
