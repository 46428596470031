var __makeTemplateObject = (this && this.__makeTemplateObject) || function (cooked, raw) {
    if (Object.defineProperty) { Object.defineProperty(cooked, "raw", { value: raw }); } else { cooked.raw = raw; }
    return cooked;
};
import styled, { css } from 'styled-components';
import { Swiper } from 'swiper/react';
export var SwiperWrapper = styled.div(templateObject_1 || (templateObject_1 = __makeTemplateObject(["\n  position: relative;\n  z-index: 0; // Swiper set index 1 automacally\n"], ["\n  position: relative;\n  z-index: 0; // Swiper set index 1 automacally\n"])));
export var SwiperStyled = styled(Swiper)(templateObject_3 || (templateObject_3 = __makeTemplateObject(["\n  position: relative;\n  padding-right: ", ";\n  padding-left: ", ";\n  height: 100%;\n  max-height: 100dvh; // needed for hero\n\n  ", "\n"], ["\n  position: relative;\n  padding-right: ", ";\n  padding-left: ", ";\n  height: 100%;\n  max-height: 100dvh; // needed for hero\n\n  ", "\n"])), function (_a) {
    var $offsetLeft = _a.$offsetLeft;
    return "".concat($offsetLeft, "px");
}, function (_a) {
    var $offsetRight = _a.$offsetRight;
    return "".concat($offsetRight, "px");
}, function (_a) {
    var $isFade = _a.$isFade;
    return $isFade && css(templateObject_2 || (templateObject_2 = __makeTemplateObject(["\n    .swiper-slide {\n      opacity: 0 !important;\n      transition: opacity 0s !important;\n    }\n    \n    .swiper-slide-active {\n      transition: opacity 1.5s !important;\n      opacity: 1 !important;\n    }\n  "], ["\n    .swiper-slide {\n      opacity: 0 !important;\n      transition: opacity 0s !important;\n    }\n    \n    .swiper-slide-active {\n      transition: opacity 1.5s !important;\n      opacity: 1 !important;\n    }\n  "])));
});
var templateObject_1, templateObject_2, templateObject_3;
