import { ReactComponent as SpinnerSVG } from 'assets/spinner.svg'

import { Container } from './styles'

const Spinner = ({ size = 20 }) => (
  <Container>
    <SpinnerSVG height={size} width={size} color='white' />
  </Container>
)

export default Spinner
