import React, { createContext, useState } from 'react'

export const modalContext = createContext({})
modalContext.displayName = 'ModalContext'

const defaultData = { variant: '', props: {} }

const ModalProvider = ({ children }) => {
  const [data, setData] = useState(defaultData)

  const value = { data, setData }

  return (
    <modalContext.Provider value={value}>
      {children}
    </modalContext.Provider>
  )
}

export default ModalProvider
