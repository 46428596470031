import { useQueryClient } from '@tanstack/react-query'
import {
  createContext,
  useEffect,
  useState,
} from 'react'
import { useTranslation } from 'react-i18next'

import { useLogin, useLoginExternal, useLoginVas } from 'api/auth'
import { useGetMe } from 'api/user'
import { ReactComponent as ErrorSVG } from 'assets/icons/snackbar/error.svg'
import { useExternalWallet, useSnackbar } from 'hooks'
import { useStore } from 'stores'
import { GAHandler } from 'utils/GA'


export const sessionContext = createContext({})
sessionContext.displayName = 'SessionContext'


const SessionProvider = ({ children }) => {
  const { t } = useTranslation()
  const { Snackbar } = useSnackbar('error')

  const {
    secret,
    setSecret,
    setIsSeriesCreator,
  } = useStore()

  const isReadyToLogIn = !!secret

  const [isLoggedIn, setIsLoggedIn] = useState(isReadyToLogIn)
  const [isPreLoggedIn, setIsPreLoggedIn] = useState(false)

  const [isLogging, setIsLogging] = useState(false)
  const [isFreshLogIn, setIsFreshLogIn] = useState(false)

  const [method, setMethod] = useState('')

  const queryClient = useQueryClient()

  const {
    login,
    data: loginData,
    error: loginError,
    reset: resetLogin,
  } = useLogin()

  const {
    loginExternal,
    data: loginExtData,
    error: loginExtError,
    reset: resetLoginExt,
  } = useLoginExternal()

  const {
    mutateAsync: loginVas,
    data: loginVasData,
    error: loginVasError,
    reset: resetLoginVas,
  } = useLoginVas()

  const {
    data: meData,
    error: meError,
    refetch,
    remove,
  } = useGetMe()

  const {
    isConnected,
    disconnect,
  } = useExternalWallet()


  const handleLogin = async ({ email, password } = {}) => {
    setIsLogging(true)

    if (isPreLoggedIn) {
      /**
       * User pre-logged means that we have all data
       * but user is not logged, so we just invalidate /me
       * in order to update user.status and check it again.
       */
      // await queryClient.invalidateQueries(['me'])
      remove()
      await refetch()

      return
    }

    setMethod('credentials')
    login({ email, password })
  }

  const loginWithSecret = (newSecret) => {
    setIsLogging(true)
    setMethod('secret')
    setSecret(newSecret)
  }

  const handleLoginExternal = ({ provider, token }) => {
    setIsLogging(true)
    setMethod(provider)
    loginExternal({ provider, token })
  }

  const handleLoginVas = ({ provider, token }) => {
    setIsLogging(true)
    setMethod('vas')
    loginVas({ provider, token })
  }

  const internalLogin = () => {
    if (!meData.isConfirmed) {
      setIsPreLoggedIn(true)
    } else {
      setIsLoggedIn(true)
      setIsFreshLogIn(true)
      window.localStorage.setItem('secret', secret)
    }

    setIsLogging(false)
  }

  const logout = () => {
    if (isConnected) disconnect()

    queryClient.removeQueries(['me'])
    queryClient.removeQueries(['balance'])
    queryClient.removeQueries(['purchases'])
    queryClient.removeQueries(['suggestions_series'])
    queryClient.removeQueries(['all-rewards'])
    queryClient.removeQueries(['new-rewards'])
    queryClient.removeQueries(['rewards'])
    queryClient.removeQueries(['profile'])

    localStorage.removeItem('secret')
    localStorage.removeItem('lang')

    resetLogin()
    resetLoginExt()
    setSecret('')
    setIsLogging(false)
    setIsLoggedIn(false)
    setIsPreLoggedIn(false)
    setIsFreshLogIn(false)
  }


  useEffect(() => {
    if (!meError) return

    logout()
    Snackbar.open({
      icon: ErrorSVG,
      message: t('error:api.unexpected'),
    })
  }, [meError])


  /**
   * STEP 2: LOGIN DATA
   * This runs after the login executed by the user
   */
  useEffect(() => {
    if (!loginData && !loginExtData && !loginVasData) return

    const data = loginData || loginExtData || loginVasData

    setSecret(data.secret)
  }, [loginData, loginExtData, loginVasData])


  /**
   * STEP 3: ME DATA
   * when me data comes:
   *  - we set i18n lang and invalidate queries to get them in user lang
   *  - add extra data to user and we save it in the state (context)
   */
  useEffect(() => {
    if (!meData || !isLogging) return

    // TODO: mutate react-query cache instead save it on localStorage
    const userData = {
      ...meData,
      isModerator: (loginData || loginExtData)?.isModerator,
      isSerieCreator: (loginData || loginExtData)?.seriesCreator,
    }

    const { isDeleting, isDeleted, isBanned } = meData

    if (isDeleting || isDeleted || isBanned) {
      logout()
      Snackbar.open({
        icon: ErrorSVG,
        message: t('notPossibleLogInThisAccount'),
      })
      return
    }

    if (userData.isSerieCreator) {
      localStorage.setItem('isSeriesCreator', true)
      setIsSeriesCreator(true)
      GAHandler('login_creator')
    } else {
      localStorage.removeItem('isSeriesCreator')
      setIsSeriesCreator(false)
    }

    internalLogin()
  }, [meData, isLogging])


  useEffect(() => {
    if (loginError || loginExtError || loginVasError) {
      setIsLogging(false)
    }
  }, [loginError, loginExtError, loginVasError])


  useEffect(() => {
    if (isLogging && loginError) resetLogin()
    if (isLogging && loginExtError) resetLoginExt()
    if (isLogging && loginVasError) resetLoginVas()
  }, [isLogging, loginError, loginExtError, loginVasError])


  const value = {
    isLogging,
    isPreLoggedIn,
    isLoggedIn,
    isFreshLogIn,
    method,
    secret,
    login: handleLogin,
    loginExternal: handleLoginExternal,
    loginVas: handleLoginVas,
    loginError: loginError || loginExtError || loginVasError,
    logout,
    loginWithSecret,
  }

  return (
    <sessionContext.Provider value={value}>
      {children}
    </sessionContext.Provider>
  )
}

export default SessionProvider
