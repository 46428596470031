import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useEffect, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { adsApi, contentApi, userApi } from 'api';
import { ReactComponent as CheckSvg } from 'assets/icons/check-round.svg';
import { ReactComponent as InfoSvg } from 'assets/icons/info.svg';
import { ReactComponent as ShareSvg } from 'assets/icons/share.svg';
import { useDrmSupported, useExternalWallet, useOpen, useResponsive, useSession } from 'hooks';
import { Button, Flixx, ModalBuyFlixxApp, Skeleton, Text } from 'ui';
import { GAHandler } from 'utils/GA';
import Author from '../Author';
import ModalContentTokens from './ModalContentTokens';
var Actions = function (props) {
    var uuid = props.uuid, author = props.author, shareText = props.shareText, showPrice = props.showPrice, contentPrice = props.contentPrice, onWatchNow = props.onWatchNow, watchNowDisabled = props.watchNowDisabled, watchWithAd = props.watchWithAd, _a = props.shareUrl, shareUrl = _a === void 0 ? location.href : _a, purchaseMethods = props.purchaseMethods, tokenGates = props.tokenGates, onWatchNowTG = props.onWatchNowTG, isPurchased = props.isPurchased, _b = props.hasDRM, hasDRM = _b === void 0 ? false : _b;
    var t = useTranslation().t;
    var isMobileBig = useResponsive().isMobileBig;
    var isLoggedIn = useSession().isLoggedIn;
    var _c = useExternalWallet(), isConnected = _c.isConnected, connect = _c.connect, sign = _c.sign, signature = _c.signature, address = _c.address, isWaitingSignature = _c.isWaitingSignature;
    var _d = useDrmSupported(hasDRM), isLoadingDRM = _d.isLoading, isSupported = _d.isSupported;
    var _e = contentApi.useGetPlayWithToken(uuid), getPlayWithToken = _e.mutateAsync, playData = _e.data, isSuccess = _e.isSuccess, isGettingPlayData = _e.isLoading;
    var me = userApi.useGetMe().data;
    var _f = userApi.useGetBalance(), balance = _f.data, isLoadingBalance = _f.isInitialLoading;
    var _g = adsApi.useGetNextReward({ enabled: purchaseMethods !== 2 }), nextReward = _g.data, isLoadingAdReward = _g.isInitialLoading;
    var modalBuyFlixxApp = useOpen();
    var modalContentTokens = useOpen();
    var _h = useState(false), isLinkCopied = _h[0], setIsLinkCopied = _h[1];
    var _j = useState(isConnected ? 2 : 1), method = _j[0], setMethod = _j[1];
    var isSomeTgAvailable = tokenGates === null || tokenGates === void 0 ? void 0 : tokenGates.some(function (tg) { return tg.isAvailable; });
    var currentShowPrice = showPrice && (me === null || me === void 0 ? void 0 : me.features.flixx);
    var isLoading = isLoadingDRM || isLoadingBalance || isLoadingAdReward;
    var copyUrl = function () {
        navigator.clipboard.writeText(shareUrl);
        GAHandler('share');
        setIsLinkCopied(true);
        setTimeout(function () {
            setIsLinkCopied(false);
        }, 3000);
    };
    var handleWatchNow = function () {
        GAHandler('watch_now');
        onWatchNow();
    };
    useEffect(function () {
        if (!isSuccess)
            return;
        onWatchNowTG(playData);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isSuccess]);
    useEffect(function () {
        if (!signature || !address)
            return;
        getPlayWithToken({ address: address, signature: signature });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [signature]);
    if (!author) {
        return (_jsx(Skeleton, { style: { height: '100%', width: '100%' } }));
    }
    return (_jsxs("div", { style: {
            width: '100%',
            // width: 310,
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-between',
            gap: 30,
            maxHeight: 296,
            minWidth: 310,
            margin: isMobileBig ? '0 auto' : 'unset',
            gridArea: 'actions',
        }, children: [_jsxs("div", { style: {
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    border: '1px solid #27272A',
                    borderRadius: 8,
                    padding: '14.5px 12px',
                    gap: 12,
                }, children: [_jsx(Author, { data: author }), _jsx(Button, { onClick: copyUrl, variant: isLinkCopied ? 'success' : 'secondary', iconLeft: isLinkCopied ? CheckSvg : ShareSvg, isFull: true, children: isLinkCopied
                            ? t('common:linkCopied')
                            : shareText })] }), modalBuyFlixxApp.isOpen && (_jsx(ModalBuyFlixxApp, { close: modalBuyFlixxApp.close })), modalContentTokens.isOpen && (_jsx(ModalContentTokens, { close: modalContentTokens.close, tokenGates: tokenGates })), !isLoggedIn && (_jsxs(Text, { color: 'headline', style: {
                    backgroundColor: '#7F39FA33',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: 6,
                    width: 'fit-content',
                    margin: '0 auto',
                    padding: '5px 8px',
                    borderRadius: 4,
                }, children: [_jsx(InfoSvg, { height: 20, width: 20 }), _jsx(Text, { as: 'span', color: 'inherit', size: 'xs', weight: 'semibold', style: { textTransform: 'uppercase' }, children: t('anteroom:loginToWatch') })] })), isLoading && (_jsx(Skeleton, { style: { height: '100%', width: '100%' } })), isLoggedIn && !isLoading && !isSupported && (_jsxs(Text, { color: 'headline', style: {
                    backgroundColor: '#7F39FA33',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    gap: 6,
                    width: 'fit-content',
                    margin: '0 auto',
                    padding: '5px 8px',
                    borderRadius: 4,
                }, children: [_jsx(InfoSvg, { height: 20, width: 20 }), _jsx(Text, { as: 'span', color: 'inherit', size: 'xs', lineHeight: 'unset', weight: 'semibold', style: { textTransform: 'uppercase' }, children: t('anteroom:unsupportedByDRM') })] })), isLoggedIn && !isLoading && isSupported && isPurchased && (_jsx("div", { style: {
                    width: '100%',
                    display: 'flex',
                    justifyContent: 'center',
                    gap: 16,
                }, children: _jsx(Button, { size: isMobileBig ? 'medium' : 'large', onClick: handleWatchNow, disabled: watchNowDisabled, style: { flexGrow: 1, maxWidth: 286 }, children: t('common:watchNow') }) })), isLoggedIn && !isLoading && isSupported && !isPurchased && purchaseMethods === 1 && (_jsxs("div", { style: {
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                    gap: 12,
                    padding: isMobileBig ? 'unset' : '0 13px',
                }, children: [Number(balance) >= contentPrice ? (_jsx(Text, { size: 'sm', weight: 'semibold', color: '#059669', children: t('anteroom:haveTheFlixx') })) : (Number(balance) + Number(nextReward) >= contentPrice)
                        ? (_jsx(Text, { size: 14, weight: 500, color: '#FBBF24', style: {
                                visibility: currentShowPrice ? 'visible' : 'hidden',
                                whiteSpace: 'nowrap',
                            }, children: t('anteroom:adMsg') })) : (_jsx(Button, { variant: 'text', size: 'small', onClick: modalBuyFlixxApp.open, children: _jsx(Text, { size: 'sm', weight: 'semibold', as: 'span', color: '#FBBF24', style: { textDecoration: 'underline', textUnderlineOffset: 4 }, children: t('anteroom:downloadApp') }) })), _jsx("div", { style: {
                            width: '100%',
                            display: 'flex',
                            justifyContent: 'center',
                            gap: 16,
                        }, children: _jsx(Button, { size: isMobileBig ? 'medium' : 'large', disabled: watchNowDisabled || Number(balance) + Number(nextReward) < contentPrice, onClick: handleWatchNow, style: { flexGrow: 1, maxWidth: 286 }, children: _jsx("span", { style: { display: 'flex', alignItems: 'center', gap: 3 }, children: _jsx(Trans, { defaults: currentShowPrice ? t('anteroom:watchFor') : t('common:watchNow'), components: [
                                        _jsx(Flixx, { sizeIcon: 27, textSize: 'sm', textWeight: 'semibold', price: contentPrice }, 'flixx'),
                                    ] }) }) }) })] })), isLoggedIn && !isLoading && isSupported && !isPurchased && purchaseMethods === 2 && (_jsxs("div", { children: [!isConnected && (_jsx(Button, { variant: 'text', onClick: function () { connect('anteroom'); }, style: { margin: '0 auto' }, children: _jsx(Text, { as: 'span', color: '#FBBF24', style: { textDecoration: 'underline', textUnderlineOffset: 4 }, children: t('tokenGating:needToConnectWallet') }) })), isConnected && (_jsx(Button, { variant: 'text', onClick: modalContentTokens.open, style: { margin: '0 auto', height: 'unset' }, children: _jsx(Text, { as: 'span', size: 'sm', weight: 600, color: '#fff', style: { textDecoration: 'underline', textUnderlineOffset: 4 }, children: isSomeTgAvailable
                                ? t('tokenGating:haveToken')
                                : t('tokenGating:whichTokensNeed') }) })), _jsx(Button, { variant: 'flame', size: 'large', isFull: true, style: { marginTop: 16 }, disabled: !isConnected || !isSomeTgAvailable, onClick: sign, isLoading: isWaitingSignature || isGettingPlayData, children: isWaitingSignature
                            ? t('tokenGating:waitingSignature')
                            : isGettingPlayData
                                ? t('tokenGating:validating')
                                : t('common:watchNow') })] })), isLoggedIn && !isLoading && isSupported && !isPurchased && purchaseMethods === 3 && (_jsxs("div", { children: [_jsxs("div", { style: {
                            display: 'flex',
                            margin: '0 auto',
                            width: ' fit-content',
                        }, children: [_jsx(Button, { size: 'small', variant: method === 1 ? 'secondary' : 'tertiary', weight: 'semibold', style: { borderRadius: 4 }, onClick: function () { setMethod(1); }, children: t('anteroom:withFlixx') }), _jsx(Button, { size: 'small', variant: method === 2 ? 'secondary' : 'tertiary', weight: 'semibold', style: { borderRadius: 4 }, onClick: function () { setMethod(2); }, children: t('anteroom:withToken') })] }), method === 2 && (_jsxs("div", { children: [!isConnected && (_jsx(Button, { variant: 'text', onClick: function () { connect('anteroom'); }, style: { margin: '16px auto 0' }, children: _jsx(Text, { as: 'span', size: 'sm', color: '#FBBF24', style: { textDecoration: 'underline', textUnderlineOffset: 4 }, children: t('tokenGating:needToConnectWallet') }) })), isConnected && (_jsx(Button, { variant: 'text', onClick: modalContentTokens.open, style: { margin: '16px auto 0', height: 'unset' }, children: _jsx(Text, { as: 'span', size: 'sm', weight: 600, color: '#fff', style: { textDecoration: 'underline', textUnderlineOffset: 4 }, children: isSomeTgAvailable
                                        ? t('tokenGating:haveToken')
                                        : t('tokenGating:whichTokensNeed') }) })), _jsx(Button, { variant: 'flame', size: 'large', isFull: true, style: { margin: '16px auto 0', maxWidth: 286 }, disabled: !isConnected || !isSomeTgAvailable, onClick: sign, isLoading: isWaitingSignature || isGettingPlayData, children: isWaitingSignature
                                    ? t('tokenGating:waitingSignature')
                                    : isGettingPlayData
                                        ? t('tokenGating:validating')
                                        : t('common:watchNow') })] })), method === 1 && (_jsxs("div", { style: { marginTop: 16 }, children: [Number(balance) >= contentPrice ? (_jsx(Text, { align: 'center', size: 'sm', weight: 'semibold', color: '#059669', children: t('anteroom:haveTheFlixx') })) : (Number(balance) + Number(nextReward) >= contentPrice)
                                ? (_jsx(Text, { align: 'center', size: 14, weight: 500, color: '#FBBF24', style: {
                                        visibility: currentShowPrice ? 'visible' : 'hidden',
                                        whiteSpace: 'nowrap',
                                    }, children: t('anteroom:adMsg') })) : (_jsx(Button, { variant: 'text', size: 'small', onClick: modalBuyFlixxApp.open, style: { margin: '0 auto' }, children: _jsx(Text, { size: 'sm', weight: 'semibold', as: 'span', color: '#FBBF24', style: { textDecoration: 'underline', textUnderlineOffset: 4 }, children: t('anteroom:downloadApp') }) })), _jsx(Button, { size: 'large', onClick: handleWatchNow, disabled: watchNowDisabled || Number(balance) + Number(nextReward) < contentPrice, isFull: true, style: { margin: '16px auto 0', flexGrow: 1, maxWidth: 286 }, children: _jsx("span", { style: { display: 'flex', alignItems: 'center', gap: 3 }, children: _jsx(Trans, { defaults: currentShowPrice ? t('anteroom:watchFor') : t('common:watchNow'), components: [
                                            _jsx(Flixx, { sizeIcon: 27, textSize: 'sm', textWeight: 'semibold', price: contentPrice }, 'flixx'),
                                        ] }) }) })] }))] }))] }));
};
export default Actions;
