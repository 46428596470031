import React, { useEffect, useState, useRef } from 'react'

import { Div } from 'ui'
import Text from 'ui/Text'
import { useResponsive } from 'hooks'

import { isValid } from '../validators'

import { Error, InputStyled, InputWrapper, Placeholder } from './styles'


const Code = ({
  value = '',
  onChange,
  digits = 6,
  placeholder = '_',
  error,
}) => {
  const containerRef = useRef()
  const [idxFocus, setIdxFocus] = useState(0)

  const { isMobile } = useResponsive()

  const refs = []
  const currentValue = value || Array(digits + 1).join(placeholder)

  const handleFocus = (e) => {
    const inputId = e.target.attributes.id.value
    const inputIndex = parseInt(inputId.replaceAll('code-', ''))
    setIdxFocus(inputIndex)
  }

  const handleChange = (e) => {
    const inputId = e.target.attributes.id.value
    const inputIndex = parseInt(inputId.replaceAll('code-', ''))

    const oldVal = currentValue[inputIndex]
    const newVal = e.target.value.replace(oldVal, '')

    const isDeleting = e.nativeEvent.data === null

    const re = new RegExp('^[0-9]$')
    const isNumber = re.test(newVal)

    if (!isNumber && !isDeleting) return

    const prev = currentValue.slice(0, inputIndex)
    const next = currentValue.slice(inputIndex + 1, digits)
    const newChar = isDeleting ? '_' : newVal

    const newValue = `${prev}${newChar}${next}`

    onChange(newValue)

    const nextIdx = isDeleting
      ? inputIndex - 1
      : inputIndex + 1

    const validNextIdx = (nextIdx < 0) || (nextIdx > digits - 1)
      ? null
      : nextIdx

    setIdxFocus(validNextIdx)

    if (validNextIdx !== null) {
      refs[validNextIdx].focus()
      refs[validNextIdx].select()
    } else {
      refs[inputIndex].blur()
    }
  }

  useEffect(() => {
    const handleClick = (e) => {
      const isClickOutside = !containerRef.current.contains(e.target)
      if (isClickOutside) setIdxFocus(null)
    }

    window.addEventListener('pointerdown', handleClick)
    return () => { window.removeEventListener('pointerdown', handleClick) }
  }, [])

  useEffect(() => {
    const handlePaste = (e) => {
      const nodeId = e.target.attributes?.id?.value
      if (!nodeId || !nodeId.includes('code')) return

      e.preventDefault()

      const paste = (e.clipboardData || window.clipboardData).getData('text')

      if (isValid.smsCode(paste)) onChange(paste)
    }

    window.addEventListener('paste', handlePaste)
    return () => { window.removeEventListener('paste', handlePaste) }
  }, [])

  useEffect(() => {
    const goLeft = () => {
      const nextIdx = idxFocus === 0
        ? null
        : idxFocus - 1

      setIdxFocus(nextIdx)

      if (nextIdx === null) {
        refs[idxFocus].blur()
        return
      }

      refs[nextIdx].focus()
      refs[nextIdx].select()
    }

    const goRight = () => {
      const nextIdx = idxFocus === digits - 1
        ? null
        : idxFocus + 1

      setIdxFocus(nextIdx)

      if (nextIdx === null) {
        refs[idxFocus].blur()
        return
      }

      refs[nextIdx].focus()
      refs[nextIdx].select()
    }

    const handleKeyboard = (e) => {
      const pressed = e.code || e.key

      if (idxFocus === null) return


      if (pressed === 'ArrowLeft') {
        e.preventDefault()
        goLeft()
      }

      if (pressed === 'ArrowRight') {
        e.preventDefault()
        goRight()
      }

      if (pressed === 'Backspace') {
        const val = currentValue[idxFocus]

        if (val === placeholder) {
          e.preventDefault()
          goLeft()
        } else {
          refs[idxFocus].focus()
          refs[idxFocus].select()
        }
      }
    }

    window.addEventListener('keydown', handleKeyboard)
    return () => { window.removeEventListener('keydown', handleKeyboard) }
  }, [idxFocus, digits, value])

  return (
    <div ref={containerRef}>
      <Div
        display='flex'
        gap={isMobile ? 8 : 12}
        justifyContent='space-between'
        width='100%'
        cursor='text'
      >
        {Array(digits).fill({}).map((_, idx) => {
          const showPlaceholder = idxFocus !== idx && currentValue[idx] === '_'

          return (
            <InputWrapper key={idx} onClick={() => { setIdxFocus(idx) }}>
              {showPlaceholder && (
                <Placeholder>
                  {placeholder}
                </Placeholder>
              )}

              <InputStyled
                ref={(ref) => refs.push(ref)}
                value={currentValue[idx] === '_'
                  ? ''
                  : currentValue[idx]
                }
                onChange={handleChange}
                onFocus={handleFocus}
                autoFocus={idx === 0}
                inputMode='numeric'
                autoComplete='off'
                id={`code-${idx}`}
                name='code'
                error={!!error}
              />
            </InputWrapper>
          )
        })}
      </Div>
      {error && (
        <Div marginTop={6}>
          <Text size='sm' color='error'>
            {error}
          </Text>
        </Div>
      )}
    </div>
  )
}

export default Code
