import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { contentApi, userApi } from 'api';
import { CarouselType } from 'config/constants';
import { useResponsive, useSession } from 'hooks';
import { Hero, Seo, } from 'ui';
import { GROUP_ID } from 'utils/constants';
import CarouselContent from './CarouselContent';
import CarouselSuggestions from './CarouselSuggestions';
import Observer from './Observer';
import { Container } from './styles';
var Series = function () {
    var isLoggedIn = useSession().isLoggedIn;
    var isMobile = useResponsive().isMobile;
    var carousels = contentApi.useGetCarousels(GROUP_ID.series).data;
    var purchases = userApi.useGetPurchased().data;
    return (_jsxs(Container, { children: [_jsx(Seo, {}), _jsx(Hero, {}), _jsxs("div", { style: {
                    transform: isMobile ? 'translateY(0)' : 'translateY(-115px)',
                }, children: [isLoggedIn && (_jsx(CarouselSuggestions, {})), carousels === null || carousels === void 0 ? void 0 : carousels.map(function (carousel) { return (_jsx(Observer, { children: _jsx(CarouselContent, { uuid: carousel.uuid, title: carousel.title, isVertical: carousel.type === CarouselType.vertical }) }, carousel.uuid)); })] })] }));
};
export default Series;
