import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import { useTranslation } from 'react-i18next';
import { userApi } from 'api';
import { ReactComponent as TickSvg } from 'assets/icons/check.svg';
import { useResponsive } from 'hooks';
import { Button, Flixx, Text } from 'ui';
import ActionInvite from './ActionInvite';
import ActionOnboard from './ActionOnboard';
import ActionProfile from './ActionProfile';
var Progress = function (props) {
    var value = props.value, target = props.target, hideValue = props.hideValue;
    var percent = (value * 100) / target;
    var isComplete = value === target;
    return (_jsxs("div", { children: [_jsx("div", { style: {
                    height: 8,
                    borderRadius: 8,
                    backgroundColor: '#373737',
                }, children: _jsx("div", { style: {
                        backgroundColor: isComplete ? '#059669' : '#E9B00C',
                        width: "".concat(percent, "%"),
                        borderRadius: 8,
                        height: '100%',
                    } }) }), !hideValue && (_jsxs(Text, { size: 14, weight: 400, style: { marginTop: 8 }, children: [value, ' ', _jsxs(Text, { as: 'span', color: 'headline', weight: 600, children: ["/", ' ', target] })] }))] }));
};
var Challenge = function (props) {
    var uuid = props.uuid, slug = props.slug, title = props.title, body = props.body, current = props.current, target = props.target, thumb = props.thumb, price = props.price, isClaimable = props.isClaimable, isClaimed = props.isClaimed, hideProgress = props.hideProgress, isProgressive = props.isProgressive, pendingClaims = props.pendingClaims, isCompleted = props.isCompleted, onClaimed = props.onClaimed;
    var _a = userApi.useClaimReward(uuid, { onSuccess: onClaimed }), claim = _a.mutateAsync, isLoading = _a.isLoading;
    var isMobile = useResponsive().isMobile;
    var t = useTranslation().t;
    var getAction = function (slug) {
        if (isClaimed)
            return null;
        if (slug === 'invites')
            return _jsx(ActionInvite, { invites: pendingClaims });
        if (slug === 'onboard')
            return _jsx(ActionOnboard, { isCompleted: isCompleted });
        if (slug === 'profile')
            return _jsx(ActionProfile, { isCompleted: isCompleted });
        return null;
    };
    return (_jsxs("div", { style: {
            padding: isMobile ? 8 : 16,
            display: 'flex',
            flexDirection: isMobile ? 'column' : 'row',
            gap: isMobile ? 8 : 16,
            border: '1px solid #3F3F46',
            borderRadius: 8,
            marginBottom: 12,
        }, children: [!isMobile && (_jsx("div", { style: {
                    minWidth: 96,
                    maxWidth: 96,
                    minHeight: 96,
                    maxHeight: 96,
                    backgroundImage: "url(".concat(thumb, ")"),
                    backgroundSize: '100%',
                } })), _jsxs("div", { style: {
                    flexGrow: 1,
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'space-between',
                    gap: 8,
                }, children: [_jsxs("div", { style: { display: 'flex', alignItems: 'center', gap: 8 }, children: [isMobile && (_jsx("div", { style: {
                                    minWidth: 50,
                                    maxWidth: 50,
                                    minHeight: 50,
                                    maxHeight: 50,
                                    backgroundImage: "url(".concat(thumb, ")"),
                                    backgroundSize: '100%',
                                } })), _jsxs("div", { children: [_jsx(Text, { size: 16, weight: 600, color: 'headline', children: title }), _jsx("div", { children: _jsx(Text, { size: isMobile ? 12 : 14, weight: isMobile ? 400 : 500, children: body }) })] })] }), !hideProgress && isProgressive && (_jsx(Progress, { value: current, target: target, hideValue: isMobile })), getAction(slug)] }), _jsxs("div", { style: {
                    minWidth: isMobile ? 'unset' : 100,
                    maxWidth: isMobile ? 'unset' : 100,
                    display: 'flex',
                    flexDirection: isMobile ? 'row' : 'column',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                }, children: [_jsx("div", { style: { padding: isMobile ? 0 : '5px 0' }, children: isClaimed ? (_jsxs("div", { style: { display: 'flex', alignItems: 'center', gap: 8, height: 40 }, children: [_jsx("div", { style: {
                                        height: isMobile ? 20 : 30,
                                        width: isMobile ? 20 : 30,
                                        minHeight: isMobile ? 20 : 30,
                                        minWidth: isMobile ? 20 : 30,
                                        backgroundColor: '#064e3b',
                                        borderRadius: 100,
                                        display: 'grid',
                                        placeItems: 'center',
                                        marginLeft: 4,
                                    }, children: _jsx(TickSvg, { color: '#2fc894', width: isMobile ? 14 : 20, height: isMobile ? 14 : 20 }) }), _jsx(Flixx, { showIcon: false, size: 16, textWeight: 600, price: price })] })) : (_jsx(Flixx, { sizeIcon: isMobile ? 26 : 39, size: 16, textWeight: 600, price: price })) }), isClaimed && (_jsx("div", { style: {
                            border: '1px solid #05986A',
                            padding: '6.5px 12px',
                            borderRadius: 8,
                        }, children: _jsx(Text, { size: 14, color: 'headline', children: t('challenges:claimed') }) })), !isClaimed && (_jsx(Button, { size: 'small', isFull: !isMobile, disabled: !isClaimable, isLoading: isLoading, onClick: claim, children: !isLoading && (_jsx(Text, { as: 'span', size: 14, color: 'headline', children: t('challenges:claim') })) }))] })] }));
};
export default Challenge;
