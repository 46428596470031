import { useMutation } from '@tanstack/react-query'

import { useApi } from 'hooks'
import { RECAPTCHA_TYPES } from 'config/constants'

const useResendCode = () => {
  const { apiFetch } = useApi()

  const url = '/auth/resendcode/sms'

  const resendCode = ({ mobileNumber }) => {
    return apiFetch({
      url,
      method: 'POST',
      data: { email: mobileNumber },
      captcha: {
        type: RECAPTCHA_TYPES.v3,
        action: 'resendcode_web',
      },
    })
      .then(res => res.data)
  }

  const {
    mutate,
    ...rest
  } = useMutation(resendCode)

  return {
    resendCode: mutate,
    ...rest,
  }
}

export default useResendCode
