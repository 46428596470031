import { jsx as _jsx } from "react/jsx-runtime";
// import { Container } from './styles'
import { dark } from 'styles/theme/dark';
import { light } from 'styles/theme/light';
import LinkLang from 'ui/LinkLang';
import { BaseCard, InteractiveCard } from './styles';
var Card = function (props) {
    var children = props.children, onClick = props.onClick, onHover = props.onHover, _a = props.isSelected, isSelected = _a === void 0 ? false : _a, _b = props.theme, theme = _b === void 0 ? 'dark' : _b, _c = props.mode, mode = _c === void 0 ? 'base' : _c, _d = props.variant, variant = _d === void 0 ? 'primary' : _d, style = props.style, className = props.className, to = props.to;
    var currentTheme = theme === 'dark' ? dark : light;
    var Container = (to || onClick)
        ? InteractiveCard
        : BaseCard;
    var as = (mode === 'button' || mode === 'select')
        ? 'button'
        : mode === 'link'
            ? LinkLang
            : 'article';
    return (_jsx(Container, { as: as, to: to, onClick: onClick, onMouseEnter: function () { return onHover === null || onHover === void 0 ? void 0 : onHover(true); }, onMouseLeave: function () { return onHover === null || onHover === void 0 ? void 0 : onHover(false); }, "$isSelected": isSelected, "$variant": variant, style: style, className: className, "$theme": currentTheme, children: children }));
};
export default Card;
