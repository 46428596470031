import { useTranslation } from 'react-i18next'
import { useTheme } from 'styled-components'
import ReactSelect from 'react-select'
import { useEffect } from 'react'

import { Div } from 'ui'

import { primary } from './variants'
import { baseStyles } from './styles'


const Select = ({
  selected,
  onChange,
  options,
  name,
  variant = 'primary',
  size,
  placeholder,
  // getOptionValue = (i) => i.value
  formatOptionLabel,
  hideChevron,
  icon: Icon,
  iconSize = 20,
  isSearchable,
  defaultValue,
  getOptionLabel,
  getOptionValue,
}) => {
  const theme = useTheme()
  const { t, i18n } = useTranslation()

  const hasIcon = !!Icon

  const dropdownIndicator = hideChevron
    ? { DropdownIndicator: null }
    : {}


  const getStyles = () => {
    if (variant === 'primary') return primary({ theme, size, hasIcon })

    return baseStyles(theme, size, hasIcon)
  }


  return (
    <div style={{
      position: 'relative',
      height: 'fit-content',
      minWidth: 'max-content',
    }}>
      <ReactSelect
        key={i18n.language}
        // autoFocus
        // menuIsOpen
        // backspaceRemovesValue={false}
        components={{
          IndicatorSeparator: null,
          ...dropdownIndicator,
        }}
        // controlShouldRenderValue={false}
        hideSelectedOptions={false}
        isClearable={false}
        onChange={onChange}
        defaultValue={defaultValue}
        options={options}
        getOptionValue={getOptionValue}
        getOptionLabel={getOptionLabel}
        placeholder={placeholder || t('selectOption')}
        name={name}
        // styles={selectStyles(theme) as StylesConfig<Option, false>}
        // tabSelectsValue={false}
        value={selected}
        isSearchable={isSearchable}
        styles={getStyles()}
        formatOptionLabel={formatOptionLabel}
      />

      {hasIcon && (
        <div style={{
          position: 'absolute',
          top: '50%',
          left: 10,
          transform: 'translateY(-50%)',
          display: 'grid',
          pointerEvents: 'none',
        }}>
          <Icon
            width={iconSize}
            height={iconSize}
          />
        </div>
      )}
    </div>
  )
}

export default Select
