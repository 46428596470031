import { useGetPurchased } from 'api/user'


const useIsPurchased = () => {
  const { data: purchases } = useGetPurchased()

  const isPurchased = (uuid) => {
    const content = purchases?.find((cont) => cont.uuid === uuid)
    return !!content
  }

  return { isPurchased, isPurchasesLoaded: !!purchases }
}

export default useIsPurchased
