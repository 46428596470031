import { jsx as _jsx } from "react/jsx-runtime";
import { Skeleton, Text } from 'ui';
import { useResponsive } from 'hooks';
import Actions from './Actions';
import Author from './Author';
import Body from './Body';
import Carousel from './Carousel';
import Cover from './Cover';
import Divider from './Divider';
import Info from './Info';
import InfoExtend from './InfoExtend';
import OverflowManager from './OverflowManager';
import Root from './Root';
var Title = function (_a) {
    var children = _a.children, isLoading = _a.isLoading;
    var isMobileBig = useResponsive().isMobileBig;
    if (isLoading) {
        return (_jsx(Skeleton, { style: {
                height: 28,
                width: '70%',
                borderRadius: 4,
            } }));
    }
    return (_jsx(Text, { as: 'h1', color: 'headline', size: isMobileBig ? 'xl' : '2xl', children: children }));
};
var TitleSuper = function (_a) {
    var children = _a.children, isLoading = _a.isLoading;
    var isMobileBig = useResponsive().isMobileBig;
    if (isLoading) {
        return (_jsx("div", { style: { marginBottom: 8 }, children: _jsx(Skeleton, { style: {
                    height: 20,
                    width: '40%',
                    borderRadius: 4,
                } }) }));
    }
    return (_jsx("div", { style: { marginBottom: 4 }, children: _jsx(Text, { as: 'h2', color: 'headline', size: isMobileBig ? 'base' : 'l', weight: isMobileBig ? 'medium' : 'semibold', children: children }) }));
};
var Wrapper = function (_a) {
    var children = _a.children;
    var _b = useResponsive(), isMobileBig = _b.isMobileBig, isTab = _b.isTab, isTabBig = _b.isTabBig;
    return (_jsx("div", { style: {
            display: 'grid',
            gap: 24,
            margin: isTab ? '0 16px 40px' : '0 40px 40px',
            gridTemplateAreas: isMobileBig
                ? '"cover cover cover" "info info info" "actions actions actions"'
                : isTabBig
                    ? '"cover cover cover" "info info actions"'
                    : '"cover info actions"',
            gridTemplateColumns: isTabBig
                ? 'repeat(3, 1fr)'
                : 'minmax(300px, 520px) auto minmax(280px, 310px)',
        }, children: children }));
};
var Anteroom = {
    Actions: Actions,
    Author: Author,
    Body: Body,
    Carousel: Carousel,
    Cover: Cover,
    Divider: Divider,
    Info: Info,
    InfoExtend: InfoExtend,
    OverflowManager: OverflowManager,
    Root: Root,
    Title: Title,
    TitleSuper: TitleSuper,
    Wrapper: Wrapper,
};
export default Anteroom;
