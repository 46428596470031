const SvgComponent = (props) => (
  <svg
    width={18}
    height={18}
    viewBox='0 0 18 18'
    fill='none'
    xmlns='http://www.w3.org/2000/svg'
    {...props}
  >
    <path
      d='M8.136 15.05a6.914 6.914 0 1 0 0-13.827 6.914 6.914 0 0 0 0 13.827ZM16.777 16.777l-3.759-3.76'
      stroke='currentColor'
      strokeWidth={1.778}
      strokeLinecap='round'
      strokeLinejoin='round'
    />
  </svg>
)

export default SvgComponent
