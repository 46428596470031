import { jsx as _jsx } from "react/jsx-runtime";
import { Skeleton, Text } from 'ui';
import { richStringToNodes } from 'utils/strings';
var Body = function (_a) {
    var children = _a.children, isLoading = _a.isLoading;
    if (isLoading) {
        return (_jsx("div", { style: { marginTop: 12 }, children: _jsx(Skeleton, { style: {
                    height: 100,
                    borderRadius: 4,
                } }) }));
    }
    return (_jsx("div", { style: { marginTop: 12 }, children: _jsx(Text, { size: 'sm', weight: 'medium', children: richStringToNodes(children) }) }));
};
export default Body;
