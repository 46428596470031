import { useContext, useRef } from 'react'

import { snackbarContext } from 'providers/snackbar'

const useSnackbar = () => {
  const timerRef = useRef()

  const { data, setData } = useContext(snackbarContext)

  const open = ({ message, icon }) => {
    const newData = {
      message,
      icon,
      isOpen: true,
    }

    setData({})

    setTimeout(() => {
      clearTimeout(timerRef.current)
      setData(newData)

      timerRef.current = setTimeout(() => {
        setData({})
      }, 5000)
    }, 0)
  }

  const close = () => setData({})

  const Snackbar = {
    isOpen: data.isOpen,
    open,
    close,
  }

  return { Snackbar }
}

export default useSnackbar
